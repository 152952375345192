import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, mergeClassnames } from '@heathmont/moon-core-tw';
import { Link as RouterLink } from 'react-router-dom';
import { IFlightBookingPayment } from './types';
import FlightBookingTermsAndConditionsDialog from '../../components/FlightBookingTermsAndConditionsDialog';
import FlightBookingPageWrapper from '../../components/FlightBookingPageWrapper';
import FlightBookingBackAndNext from '../../components/FlightBookingBackAndNext';
import { useFlightOffersPriceConfirmation } from '../../hooks/useFlightOffersPriceConfirmation';
import FlightBookingStepsWrapper from '../../components/FlightBookingStepsWrapper';
import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';
import {
  BookingParams,
  isPaymentTypeSupportLowCostBookings,
  isBookingHasLowCostOffers,
  extractErrorFromResponse,
  confirmOffersPrice,
  bookFlight,
} from './utils';
import FlightBookingInfoView from '../../components/FlightBookingInfoView';
import FlightBookingInitialLoader from '../../components/FlightBookingInitialLoader';
import useIsMobile from '../../hooks/useIsMobile';
import FlightBookingSummary from '../../components/FlightBookingSummary';
import useDestinations from '../../hooks/useDestinations';
import PaymentFormWrapper from '../../components/PaymentFormWrapper';
import { handleRedirectToCheckoutPage } from '../../utils/payments';
import { BOOK_FLIGHT_LOADING_TEXTS } from '../../constants';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { checkboxLabelCN } from '../../styles/texts';
import { btnLink } from '../../styles/buttons';

const FlightBookingPayment: React.FC<IFlightBookingPayment> = ({
  isTermsAndConditionsOpen,
  transferRequired,
  selectedOffers = [],
  additionalBags,
  searchParams,
  amaClientRef,
  passengers,
  hasAccess,
  payment,
  booking,
  setIsTermsAndConditionsOpen,
  handleSelectPaymentType,
  handleSelectPaymentCoin,
  setPaymentPayerDetails,
  toggleTransferRequired,
  setPaymentPayerType,
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { origin, destination, isLoading: destinationLoading } = useDestinations({ searchParams, hasAccess });
  const {
    hasAdditionalServicesUnpriceableWarning,
    isPriceConfirmationError,
    isSignificantPriceChange,
    includedBags,
    isLoading,
    price,
  } = useFlightOffersPriceConfirmation({
    additionalBags,
    amaClientRef,
    includeBags: true,
    hasAccess,
    booking,
    offers: selectedOffers,
  });
  const [bookingLoading, setBookingLoading] = useState<boolean>(false);
  const [bookingError, setBookingError] = useState<string | null>(null);
  const [isPaymentFormValid, setIsPaymentFormValid] = useState<boolean>(false);
  const [isPaymentFormLoading, setIsPaymentFormLoading] = useState<boolean>(false);
  const [agreeToStorePersonalData, setAgreeToStorePersonalData] = useState<boolean>(false);
  const isLowCostBooking = useMemo(() => isBookingHasLowCostOffers(selectedOffers), [selectedOffers]);
  const [isCryptoUnavailable, setIsCryptoUnavailable] = useState<boolean>(false);

  const handleAgreeTermsAndConditions = () => {
    setIsTermsAndConditionsOpen(false);
    setAgreeToStorePersonalData(true);
  };

  const handleOpenTermsAndConditions = (e) => {
    e.preventDefault();
    setIsTermsAndConditionsOpen(true);
  };

  const renderLinkToTermsAndConditions = () => {
    const title = 'terms and conditions';

    return (
      <>
        <a className={mergeClassnames(btnLink, 'laptop:hidden')} onClick={handleOpenTermsAndConditions}>
          {title}
        </a>
        <RouterLink className={mergeClassnames(btnLink, 'max-tablet:hidden')} to={'/flight-booking-terms-and-conditions'} target={'_blank'}>
          {title}
        </RouterLink>
      </>
    );
  }

  const handleResetError = () => {
    setBookingLoading(false);
    setBookingError(null);
  };

  const handleRedirect = () => {
    handleRedirectToCheckoutPage({
      amaClientRef,
      paymentType: payment.paymentType,
      navigate,
    });
  };  

  const handleBookFlight = async () => {
    try {
      setBookingLoading(true);
      setBookingError(null);

      const offersWithConfirmedPrice = await confirmOffersPrice({
        additionalBags,
        amaClientRef,
        offers: selectedOffers,
      });

      if (!offersWithConfirmedPrice?.length ) {
        setBookingLoading(false);
        setBookingError('Offers are no longer available.');
        return;
      }

      const bookingParams: BookingParams = {
        transferRequired,
        includedBags,
        amaClientRef,
        travelers: passengers,
        fareRules: selectedOffers.map(({ fareRules }) => fareRules || null),
        payment,
        offers: offersWithConfirmedPrice,
      };

      const { data } = await bookFlight(bookingParams, isLowCostBooking);

      setBookingLoading(false);

      if (data?.success) {
        handleRedirect();
      } else {
        setBookingError(extractErrorFromResponse(data?.error));
      }
    } catch (err) {
      setBookingError(extractErrorFromResponse(err));
      setBookingLoading(false);
    }
  };

  return (
    <FlightBookingPageWrapper isMobile={isMobile} page={'flight-booking-payment'}>
      <FlightBookingStepsWrapper
        hasAdditionalServicesUnpriceableWarning={hasAdditionalServicesUnpriceableWarning}
        isPriceConfirmationError={isPriceConfirmationError}
        isSignificantPriceChange={isSignificantPriceChange}
        bookingLoading={bookingLoading}
        isBookingError={!!bookingError}
        currentStep={FLIGHT_BOOKING_STEP.PAYMENT}
        isLoading={isLoading || destinationLoading}
        renderContinueAndBackButton={() => !bookingError && !bookingLoading && (
          <FlightBookingBackAndNext
            submitLabel='Confirm'
            withSearch={false}
            isLoading={isLoading || destinationLoading || bookingLoading || isPaymentFormLoading}
            disabled={(
              isPriceConfirmationError
              || !isPaymentFormValid
              || !agreeToStorePersonalData
              || (isLowCostBooking && !isPaymentTypeSupportLowCostBookings(payment?.paymentType))
              || (payment?.paymentType === PAYMENT_TYPE.CRYPTO && isCryptoUnavailable)
            )}
            price={price} 
            step={FLIGHT_BOOKING_STEP.PAYMENT}
            proceedContinue={handleBookFlight}
          />
        )}
      >
        {bookingError ? (
          <FlightBookingInfoView
            submitButtonLabel={'Back'}
            subTitle={bookingError}
            title={'The booking was unsuccessful'}
            onClick={handleResetError}
          />
        ) : (
          <>
            <FlightBookingSummary
              departureDate={searchParams.departureDate}
              destination={destination?.city}
              returnDate={searchParams.returnDate}
              isLoading={isLoading || destinationLoading}
              origin={origin?.city}
            />
            {bookingLoading ? (
              <FlightBookingInitialLoader small texts={BOOK_FLIGHT_LOADING_TEXTS} />
            ) : (
              <>
                <PaymentFormWrapper
                  isCryptoUnavailable={isCryptoUnavailable}
                  isLowCostBooking={isLowCostBooking}
                  passengers={passengers}
                  isLoading={isLoading || destinationLoading}
                  hasAccess={hasAccess}
                  payment={payment}
                  handleSelectPaymentType={handleSelectPaymentType}
                  handleSelectPaymentCoin={handleSelectPaymentCoin}
                  setIsPaymentFormLoading={setIsPaymentFormLoading}
                  setIsCryptoUnavailable={setIsCryptoUnavailable}
                  setPaymentPayerDetails={setPaymentPayerDetails}
                  setIsPaymentFormValid={setIsPaymentFormValid}
                  setPaymentPayerType={setPaymentPayerType}
                />
                <div className='w-full flex flex-col mt-[18px] [&_input]:flex-shrink-0'>
                  <Checkbox
                    disabled={isLoading || destinationLoading}
                    checked={transferRequired}
                    label={(
                      <span className={checkboxLabelCN}>
                        Transfer from an airport is required. We will be in touch with you soon for more details
                      </span>
                    )}
                    onChange={toggleTransferRequired}
                  />
                  <Checkbox
                    disabled={isLoading || destinationLoading}
                    checked={agreeToStorePersonalData}
                    label={(
                      <span className={checkboxLabelCN}>
                        I give my consent to the use of my personal data and agree with {renderLinkToTermsAndConditions()}
                      </span>
                    )}
                    onChange={() => setAgreeToStorePersonalData(prevValue => !prevValue)}
                  />
                </div>
              </>
            )}
          </>
        )}
      </FlightBookingStepsWrapper>

      {isTermsAndConditionsOpen && (
        <FlightBookingTermsAndConditionsDialog
          onAgree={handleAgreeTermsAndConditions}
          onClose={() => setIsTermsAndConditionsOpen(false)}
        />
      )}
    </FlightBookingPageWrapper>
  );
};

export default FlightBookingPayment;
