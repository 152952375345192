import { connect } from 'react-redux';
import FlightBookingCryptoPayment from './FlightBookingCryptoPayment.ui';
import { resetToInitial } from '../../store/flightBookings/actions';
import { setIsPriceChangedModalOpen } from '../../store/flightBookingsDialogs/actions';

const mapStateToProps = ({
  flightBookingsDialogsReducer,
  eventLinkReducer,
}) => {
  return {
    isPriceChangedModalOpen: flightBookingsDialogsReducer.isPriceChangedModalOpen,
    hasAccess: !!(eventLinkReducer.confirmed && eventLinkReducer.eventLink),
  };
};

const mapDispatchToProps = {
  setIsPriceChangedModalOpen,
  resetToInitial,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingCryptoPayment);
