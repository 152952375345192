import { connect } from 'react-redux';
import FlightBookingTermsAndConditions from './FlightBookingTermsAndConditions.ui';
import { setIsContactFormOpen } from '../../store/flightBookingsDialogs/actions';

const mapStateToProps = ({
  flightBookingsDialogsReducer,
}) => {
  return {
    isContactFormOpen: flightBookingsDialogsReducer.isContactFormOpen,
  };
};

const mapDispatchToProps = {
  setIsContactFormOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingTermsAndConditions);
