import { DestinationOption } from '../../enums/Destination';

export const formatLabelWithCode = (option) => {
  return option && option?.label ? `${option?.label} (${option?.code})` : '';
};

export const filterOptions = (searchText: string, options: DestinationOption[]): DestinationOption[] => {
  return options.filter(option => {
    const labelWithCode = formatLabelWithCode(option);
    const labelWithInclude = labelWithCode.toLowerCase().includes(searchText.toLowerCase());
    const labelInclude = option.label.toLowerCase().includes(searchText.toLowerCase());
    const valueInclude = option.value.toLowerCase().includes(searchText.toLowerCase());
    const cityInclude = option?.city.toLowerCase().includes(searchText.toLowerCase());
    const countryInclude = option?.country.toLowerCase().includes(searchText.toLowerCase());
    const popular = option?.popular;

    return labelWithInclude
      || labelInclude
      || valueInclude
      || countryInclude
      || cityInclude
      || popular;
  });
};
