import { mergeClassnames } from '@heathmont/moon-core-tw';

export const textCN = mergeClassnames([
  'font-normal',
  'text-moon-16',
  'text-popo',
  'flex',
  'items-center',
  'gap-[7.5px]',
]);

export const fieldWrapperCN = mergeClassnames([
  'w-full',
  'flex',
  'flex-col',
  'gap-[8px]',
]);
