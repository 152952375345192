import { connect } from 'react-redux';
import FlightBookingPaymentChange from './FlightBookingPaymentChange.ui';
import {
  handleSelectPaymentType,
  handleSelectPaymentCoin,
  setPaymentPayerDetails,
  setPaymentPayerType,
} from '../../store/flightBookings/actions';

const mapStateToProps = ({ eventLinkReducer, flightBookingsReducer }) => {
  return {
    hasAccess: !!(eventLinkReducer.confirmed && eventLinkReducer.eventLink),
    payment: flightBookingsReducer.payment,
  };
};

const mapDispatchToProps = {
  handleSelectPaymentType,
  handleSelectPaymentCoin,
  setPaymentPayerDetails,
  setPaymentPayerType,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingPaymentChange);
