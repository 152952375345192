import { useEffect, useState } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { Button, InsetInput, Hint } from '@heathmont/moon-core-tw';
import isEmpty from 'lodash/isEmpty';
import RevolutCheckout, { Mode, FieldStatus } from '@revolut/checkout';
import { ICreditCardForm } from './types';
import {
  InputCardNumberInputStyledDefault,
  InputCardNumberInputStyledInvalid,
  fieldWrapperCN,
} from './styles';
import { creditCardSchema } from './schema';
import { extractCardNumber, extractCardSecurityAndExpiry, extractCardholderName } from './utils';
import './styles.scss';

const CreditCardForm: React.FC<ICreditCardForm> = ({
  shippingAddress,
  isProcessing,
  payerEmail,
  payerName,
  payments,
  children,
  amount,
  token,
  onSuccess,
  onError,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: creditCardSchema,
    validateOnBlur: true,
    initialValues: {
      cardHolder: extractCardholderName(payments),
    },
    onSubmit: () => {},
  });
  const { values, errors, touched, setFieldValue, setFieldTouched, validateForm } = formik;
  const [cardFieldState, setCardFieldState] = useState<any>(null);
  const [cardErrors, setCardErrors] = useState<string[]>([]);
  const [inputStatuses, setInputStatuses] = useState<FieldStatus | null>(null);

  const createCardField = async (token) => {
    const { createCardField } = await RevolutCheckout(token, process.env.REACT_APP_REVOLUT_MODE as Mode);
    const cardField = createCardField({
      hidePostcodeField: true,
      target: document.getElementById('card-field'),
      styles: {
        default: InputCardNumberInputStyledDefault,
        invalid: InputCardNumberInputStyledInvalid,
      },
      onValidation: (validationErrors) => setCardErrors(validationErrors.map(err => err.message)),
      onStatusChange: (newStatuses) => setInputStatuses(newStatuses),
      onSuccess,
      onCancel: () => onError('Payment cancelled'),
      onError: (error) => onError(error.message),
    });
    setCardFieldState(cardField);
  };

  const handleSubmit = () => {
    return cardFieldState.submit({
      cardholderName: values.cardHolder,
      billingAddress: {
        countryCode: shippingAddress.country_code,
        streetLine1: shippingAddress.street_line_1,
        postcode: shippingAddress.postcode,
        region: shippingAddress.region,
        city: shippingAddress.city,
      },
      email: payerEmail,
      name: payerName,
    });
  };

  const isSubmitDisabled = (): boolean => {
    return !isEmpty(errors)
      || inputStatuses?.empty
      || inputStatuses?.invalid
      || !inputStatuses?.completed
      || isProcessing;
  };

  useEffect(() => {
    validateForm();
  }, []);

  useEffect(() => {
    if (token && !isProcessing) {
      createCardField(token);
    }
  }, [token, isProcessing]);

  return (
    <FormikProvider value={formik}>
      <div className='w-full flex flex-col gap-[16px]'>
        <div className={fieldWrapperCN}>
          <InsetInput
            placeholder=' '
            className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
            disabled={isProcessing}
            error={!!(errors?.cardHolder && touched?.cardHolder)}
            value={values.cardHolder}
            name='cardHolder' 
            type='text'
            id='cardHolder'
            onChange={({ target: { value } }) => setFieldValue('cardHolder', value)}
            onBlur={() => setFieldTouched('cardHolder')}
          >
            <InsetInput.Label>Card holder</InsetInput.Label>
          </InsetInput>

          {(!!errors?.cardHolder && touched?.cardHolder) && (
            <Hint error>{errors?.cardHolder}</Hint>
          )}
        </div>

        <div className={fieldWrapperCN}>
          {isProcessing ? (
            <InsetInput
              className='rounded-moon-i-md [&_input]:rounded-moon-i-md [&_input]:pr-[55px]'
              placeholder=' '
              disabled={isProcessing}
              value={extractCardNumber(payments)}
              name='cardNumber' 
              type='text'
              id='cardNumber'
            >
              <InsetInput.Label>Card number</InsetInput.Label>
              <span className='text-moon-16 z-10 absolute top-2/4 -translate-y-1/2 right-[17px]'>
                {extractCardSecurityAndExpiry(payments)}
              </span>
            </InsetInput>
          ) : (
            <div id='card-field' /> 
          )}
          {!!cardErrors?.length && (
            <Hint error>{cardErrors[0]}</Hint>
          )}
        </div>
      </div>

      {children}

      <Button
        className='mb-[20px] text-moon-20 font-normal'
        disabled={isSubmitDisabled()}
        fullWidth
        size='xl'
        id='button-submit'
        onClick={handleSubmit}
      >
        Payment: €{amount}
      </Button>
    </FormikProvider>      
  );
};

export default CreditCardForm;
