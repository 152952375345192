import moment from 'moment';
import airports from 'airport-timezone';
import momentTimezone from 'moment-timezone';
import { toast, TypeOptions } from 'react-toastify';
import { getErrorMessages } from './errors';

export const convertTimeToFlightLocalTime = (date, iata) => {
  const withoutTimeZone = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
  const airport = airports.find((airport) => airport.code.toUpperCase() === iata.toUpperCase());
  let timezone;

  if (airport && airport.timezone) {
    timezone = airport.timezone;
  }

  return momentTimezone.tz(withoutTimeZone, timezone).toString();
};

export const getFlightDuration = (endDate, startDate) => {
  const date = moment.duration(moment(endDate).diff(moment(startDate)));
  const days = date.days();
  const hrs = date.hours();
  const mins = date.minutes();
  let duration = '';

  if (days) duration = `${days}d`;
  if (hrs) duration += ` ${hrs}h`;
  if (mins) duration += ` ${mins}m`;

  return duration;
};

export const getErrorStatusMessage = (data) => {
  const { status, message } = data;

  switch (status) {
    case 200:
      return toast.success(message);
    case 422:
      const errorMessages = getErrorMessages(message);
      return toast.error(errorMessages.join(' '));
    case 403:
      return toast.error('Access forbidden');
    case 500:
      return toast.error('Something went wrong. Try again later');
    default:
      return toast.error('Something went wrong');
  }
};

export const showToast = (message, type: TypeOptions = 'error') => {
  return toast(message, { type });
};

export const trimLongText = (text: string): string => {
  return text.replace(/\s+/g, ' ').trim();
};
