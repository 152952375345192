export enum PAYER_TYPE {
  INDIVIDUAL = 'individual',
  BUSINESS = 'business',
};

export enum PAYER_TYPE_LABEL {
  INDIVIDUAL = 'Individual',
  BUSINESS = 'Company',
}

export const payerTypeOptions = [
  {
    value: PAYER_TYPE.INDIVIDUAL,
    label: PAYER_TYPE_LABEL.INDIVIDUAL,
  },
  {
    value: PAYER_TYPE.BUSINESS,
    label: PAYER_TYPE_LABEL.BUSINESS,
  },
];
