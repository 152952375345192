import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IFlightBookingSuccessfulPayment } from './types';
import FlightBookingPageWrapper from '../../components/FlightBookingPageWrapper';
import {
  downloadAdditionalPaymentInvoice,
  fetchAdditionalPaymentDetails,
  defineTitleAndSubTitle,
} from './utils';
import useIsMobile from '../../hooks/useIsMobile';
import { Payment } from '../../enums/Payment';
import FlightBookingInfoView from '../../components/FlightBookingInfoView';
import { useQuery } from '../../hooks/useQuery';
import { paymentCheckoutContentCN, paymentCheckoutWrapperCN } from '../../styles/wrappers';

const FlightBookingSuccessfulPayment: React.FC<IFlightBookingSuccessfulPayment> = ({
  hasAccess,
  resetToInitial,
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { getQuery } = useQuery();
  const { token } = useMemo(() => getQuery(), []) as { token: string };;
  const [payment, setPayment] = useState<{ data: Payment }>({ data: null });
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(true);
  const [isPaymentError, setIsPaymentError] = useState<boolean>(false);
  const [isInvoiceDownloading, setIsInvoiceDownloading] = useState<boolean>(false);
  const { title, subTitle } = useMemo(() => defineTitleAndSubTitle({
    paymentType: payment?.data?.paymentType,
    status: payment?.data?.status,
  }), [payment?.data?.paymentType, payment?.data?.status]);

  useEffect(() => {
    if (hasAccess) {
      fetchAdditionalPaymentDetails({
        token,
        setIsPaymentLoading,
        setIsPaymentError,
        setPayment,
      });
    }

    return () => {
      resetToInitial();
    };
  }, [hasAccess]);

  const backToFlightsSearch = () => {
    navigate('/');
  };

  const handleDownloadInvoice = () => {
    downloadAdditionalPaymentInvoice({
      token,
      setIsInvoiceDownloading,
    })
  };

  return (
    <FlightBookingPageWrapper isMobile={isMobile} page={'flight-booking-successful-payment'}>
      <div className={paymentCheckoutWrapperCN}>
        <div className={paymentCheckoutContentCN}>
          {
            isPaymentError ? (
              <FlightBookingInfoView
                submitButtonLabel={'Flight search'}
                isSuccess={false}
                subTitle={`Please, contact travel manager for more details: ${process.env.REACT_APP_CONTACT_EMAIL}`}
                loading={isPaymentLoading}
                title={'Payment was not found'}
                onClick={backToFlightsSearch}
              />
            ) : (
              <FlightBookingInfoView
                secondaryButtonLoading={isInvoiceDownloading}
                secondaryButtonLabel={'Download invoice'}
                submitButtonLabel={'Flight search'}
                isSuccess
                subTitle={!isPaymentLoading && subTitle}
                loading={isPaymentLoading}
                title={!isPaymentLoading && title}
                handleSecondaryButtonClick={handleDownloadInvoice}
                onClick={backToFlightsSearch}
              />
            )
          } 
        </div>
      </div>
    </FlightBookingPageWrapper>
  );
};

export default FlightBookingSuccessfulPayment;
