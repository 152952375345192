import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { IFlightBookingAdditionalPayment } from './types';
import FlightBookingPageWrapper from '../../components/FlightBookingPageWrapper';
import FlightBookingBackAndNext from '../../components/FlightBookingBackAndNext';
import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';
import { handleGetAdditionalPaymentDetails, handleRedirect, initAdditionalPayment } from './utils';
import FlightBookingInfoView from '../../components/FlightBookingInfoView';
import useIsMobile from '../../hooks/useIsMobile';
import FlightBookingSummary from '../../components/FlightBookingSummary';
import PaymentFormWrapper from '../../components/PaymentFormWrapper';
import { showToast } from '../../utils/common';
import { PaymentRequest } from '../../enums/PaymentRequest';
import { extractErrorFromResponse } from '../FlightBookingPayment/utils';
import { initPayment } from '../../store/flightBookings/utils';
import { Passenger } from '../../enums/Passenger';
import { Payment } from '../../enums/Payment';
import { Flight } from '../../enums/Flight';
import { Trip } from '../../enums/Trip';
import { useQuery } from '../../hooks/useQuery';
import {
  getDepartureDate,
  getDepartureCity,
  getArrivalCity,
  getReturnDate,
} from '../FlightBookingRequestChanges/utils';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { mergeClassnames } from '@heathmont/moon-core-tw';
import { textEllipsisCN } from '../../styles/texts';
import { paymentCheckoutContentCN, paymentCheckoutWrapperCN } from '../../styles/wrappers';

const FlightBookingAdditionalPayment: React.FC<IFlightBookingAdditionalPayment> = ({
  hasAccess,
  payment,
  handleSelectPaymentType,
  handleSelectPaymentCoin,
  setPaymentPayerDetails,
  setPaymentPayerType,
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [additionalPaymentData, setAdditionalPaymentData] = useState<{
    paymentRequest: PaymentRequest | null;
    travelers: Passenger[];
    payment: Payment;
    flight: Flight | null;
    trip: Trip | null;
  }>({
    paymentRequest: null,
    travelers: [],
    payment: null,
    flight: null,
    trip: null,
  });
  const [isAdditionalPaymentDataLoading, setIsAdditionalPaymentDataLoading] = useState<boolean>(true);
  const [additionalPaymentDataError, setAdditionalPaymentDataError] = useState<boolean>(false);
  const [isInitAdditionalPaymentLoading, setIsInitAdditionalPaymentLoading] = useState<boolean>(false);
  const [isPaymentFormValid, setIsPaymentFormValid] = useState<boolean>(false);
  const [isPaymentFormLoading, setIsPaymentFormLoading] = useState<boolean>(false);
  const [isCryptoUnavailable, setIsCryptoUnavailable] = useState<boolean>(false);
  const { getQuery } = useQuery();
  const { token } = useMemo(() => getQuery(), []) as { token: string };

  const handleRedirectToSearch = async () => {
    navigate('/');
  }; 

  const handleInitAdditionalPayment = async () => {
    try {
      setIsInitAdditionalPaymentLoading(true);
      const response = await initAdditionalPayment({ token, payment });
      setIsInitAdditionalPaymentLoading(false);
      handleRedirect({
        paymentType: payment.paymentType,
        token: response?.data?.token,
        navigate,
      });
    } catch (err) {
      showToast(extractErrorFromResponse(err), 'error');
      setIsInitAdditionalPaymentLoading(false);
    }
  };

  useEffect(() => {
    if (hasAccess) {
      handleGetAdditionalPaymentDetails({
        token,
        setIsAdditionalPaymentDataLoading,
        setAdditionalPaymentData,
        handleError: () => setAdditionalPaymentDataError(true),
        navigate,
      });
    }
  }, [hasAccess, token]);

  useEffect(() => {
    if (additionalPaymentData?.travelers && additionalPaymentData?.payment) {
      setPaymentPayerDetails(initPayment({
        passengers: additionalPaymentData.travelers,
        payment: additionalPaymentData?.payment,
      }));
    }
  }, [additionalPaymentData?.travelers, additionalPaymentData?.payment]);

  return (
    <FlightBookingPageWrapper isMobile={isMobile} page={'flight-booking-additional-payment'}>
      <div className={paymentCheckoutWrapperCN}>
        <div className={paymentCheckoutContentCN}>
          {additionalPaymentDataError ? (
            <FlightBookingInfoView
              submitButtonLabel={'Flight search'}
              subTitle={`Please, contact travel manager for more details: ${process.env.REACT_APP_CONTACT_EMAIL}`}
              title={'Something went wrong'}
              onClick={handleRedirectToSearch}
            />
          ) : (
            <>
              <FlightBookingSummary
                departureDate={getDepartureDate(additionalPaymentData?.flight)}
                destination={getArrivalCity(additionalPaymentData?.flight)}
                returnDate={getReturnDate(additionalPaymentData?.flight)}
                isLoading={isAdditionalPaymentDataLoading}
                origin={getDepartureCity(additionalPaymentData?.flight)}
              />
              <div className='rounded-[12px] bg-goku px-[40px] py-[16px] flex items-start justify-center flex-col relative w-full shadow-moon-xl mb-[10px] max-tablet:px-[16px] max-tablet:py-[12px]'>
                <div className={mergeClassnames('text-moon-24 max-tablet:text-moon-18 text-bulma', textEllipsisCN)}>
                  {isAdditionalPaymentDataLoading ? (
                    <Skeleton style={{ height: '100%', width: '200px' }} inline />
                  ) : (
                    'Additional payment'
                  )}
                </div>

                <div className={mergeClassnames('text-moon-16 max-tablet:text-moon-12 text-trunks', textEllipsisCN)}>
                  {isAdditionalPaymentDataLoading ? (
                    <Skeleton style={{ height: '100%', width: '80px' }} inline />
                  ) : (
                    additionalPaymentData?.paymentRequest?.details
                  )}
                </div>
              </div>
              <PaymentFormWrapper
                isCryptoUnavailable={isCryptoUnavailable}
                isLowCostBooking={false}
                passengers={additionalPaymentData?.travelers}
                isLoading={isAdditionalPaymentDataLoading || isInitAdditionalPaymentLoading}
                hasAccess={hasAccess}
                payment={payment}
                handleSelectPaymentType={handleSelectPaymentType}
                handleSelectPaymentCoin={handleSelectPaymentCoin}
                setIsPaymentFormLoading={setIsPaymentFormLoading}
                setIsCryptoUnavailable={setIsCryptoUnavailable}
                setPaymentPayerDetails={setPaymentPayerDetails}
                setIsPaymentFormValid={setIsPaymentFormValid}
                setPaymentPayerType={setPaymentPayerType}
              />
              <FlightBookingBackAndNext
                submitLabel='Confirm'
                withSearch={false}
                isLoading={isAdditionalPaymentDataLoading || isInitAdditionalPaymentLoading || isPaymentFormLoading}
                disabled={!isPaymentFormValid || (payment?.paymentType === PAYMENT_TYPE.CRYPTO && isCryptoUnavailable)}
                price={additionalPaymentData?.paymentRequest?.sum} 
                step={FLIGHT_BOOKING_STEP.SUCCESS}
                proceedContinue={handleInitAdditionalPayment}
              />
            </>
          )}
        </div>
      </div>
    </FlightBookingPageWrapper>
  );
};

export default FlightBookingAdditionalPayment;
