import omit from 'lodash/omit';
import request from '../../utils/request';
import { Payment } from '../../enums/Payment';

export const updatePayment = async ({
  amaClientRef,
  payment,
}: {
  amaClientRef: string,
  payment: Payment,
}) => {
  const response = await request.post(`white-label/payments/attach/${amaClientRef}`, {
    payment: omit(payment, [
      'id',
      'currency',
      'description',
      'externalStatus',
      'key',
      'provider',
      'sum',
      'total',
      'bookingReference',
      'bookingMargin',
      'raws',
    ]),
  });
  return response;
};
