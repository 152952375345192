import moment from 'moment';
import { convertTimeToFlightLocalTime, getFlightDuration } from '../../utils/common';
import { getCityByIataCode, getCountryByIataCode } from '../../utils/flight-offer';

export const getNoteAboutArrivalNextDay = (date) => {
  return `Arrives on ${moment.utc(date).format('dddd')}, ${moment.utc(date).format('DD MMMM YYYY')}`
};

export const getNoteAboutStop = (iataCode, duration) => {
  const city = getCityByIataCode(iataCode);
  const country = getCountryByIataCode(iataCode);

  return `${city || iataCode}${country ? ` (${country})` : ''}, ${duration}`
};

export const getFlightDurationText = (departure, arrival) => {
  const startDate = convertTimeToFlightLocalTime(departure.at, departure.iataCode);
  const endDate = convertTimeToFlightLocalTime(arrival.at, arrival.iataCode);
  return getFlightDuration(endDate, startDate);
};

export const formatFlightDate = (departure, arrival) => {
  if (!departure || !arrival) {
    return null;
  }

  const departureTime = moment.utc(departure.at).format('HH:mm');
  const departureDate = moment.utc(departure.at).format('MMM DD');
  const arrivalTime = moment.utc(arrival.at).format('HH:mm');
  const arrivalDate = moment.utc(arrival.at).format('MMM DD');
  const arrivalFullDate = departureDate === arrivalDate
    ? arrivalTime
    : `${arrivalTime} (${arrivalDate})`;
  const arrivalDay = moment.utc(arrival.at).format('YYYY-MM-DD')
  const departureDay = moment.utc(departure.at).format('YYYY-MM-DD')
  const departureArrivalDifference = moment(arrivalDay).diff(moment(departureDay), 'days');

  return {
    departureArrivalDifference,
    arrivalAtFull: arrivalFullDate,
    departureAt: departureTime,
    arrivalAt: arrivalTime,
  };
};
