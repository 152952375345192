import { combineReducers } from 'redux';
import flightBookingsDialogsReducer from './flightBookingsDialogs/reducer';
import flightBookingsReducer from './flightBookings/reducer';
import eventLinkReducer from './eventLink/reducer';

export default combineReducers({
  eventLinkReducer,
  flightBookingsReducer,
  flightBookingsDialogsReducer,
});
