import { FEEDBACK_RATE } from '../../enums/FeedbackRate';
import request from '../../utils/request';
import { extractErrorFromResponse } from '../../pages/FlightBookingPayment/utils';
import { showToast } from '../../utils/common';

export const handleSubmitFeedback = async ({
  submittedBy,
  webAppRate,
  comment,
  tripId,
  setIsFeedbackFormLoading,
  onClose,
}: {
  submittedBy: number;
  webAppRate: FEEDBACK_RATE;
  comment?: string;
  tripId: number;
  setIsFeedbackFormLoading: (isFeedbackFormLoading: boolean) => void,
  onClose: () => void;
}) => {
  try {
    setIsFeedbackFormLoading(true);

    await request.post(`white-label/feedback`, {
      submittedBy,
      webAppRate: `${webAppRate}`,
      comment: comment?.trim(),
      tripId,
    });

    setIsFeedbackFormLoading(false);
    onClose();
    showToast('Your feedback has been sent', 'success');
  } catch (err) {
    setIsFeedbackFormLoading(false);
    showToast(extractErrorFromResponse(err), 'error');
  }
};
