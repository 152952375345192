import { useState, useEffect, useRef, useMemo } from 'react';
import { useFormik, FormikProvider, setNestedObjectValues } from 'formik';
import { Input, Hint, Button, mergeClassnames } from '@heathmont/moon-core-tw';
import { IEventCodeConfirmationModal } from './types';
import { schema } from './schema';
import Modal from '../Modal';
import { ReactComponent as DarkLogoSvg } from '../../static/dark-logo.svg';
import { ReactComponent as ChevronRightSmallSvg } from '../../static/icons/chevron-right-small.svg';
import { definePasswordModalTitle } from './utils';
import { btnIconCN } from '../../styles/buttons';

const EventCodeConfirmationModal: React.FC<IEventCodeConfirmationModal> = ({
  submitError,
  page,
  resetSubmitError,
  onSubmit,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues: {
      code: '',
    },
    onSubmit: () => {},
  });
  const { errors, values, touched, setFieldValue, validateForm, setFieldTouched, setTouched, handleBlur } = formik;
  const [isLoading, setIsLoading] = useState(false);
  const formErrors = useMemo(() => {
    return submitError ? { ...(errors || {}), code: submitError } : errors; 
  }, [errors, submitError]);
  const mounted = useRef(false);

  const handleOnSubmit = async () => {
    try {
      resetSubmitError();
      await schema.validate(values, { strict: true });
      setIsLoading(true);
      await onSubmit(values?.code);
    } catch (err) {
      const validationErrors = await validateForm();
      if (Object.keys(validationErrors).length > 0){ 
        setTouched(setNestedObjectValues(validationErrors, true));
      }
    } finally {
      if (mounted.current) {
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setFieldTouched('code', true, false);
      handleOnSubmit();
    }
  };

  const handleChange = (e) => {
    resetSubmitError();
    setFieldValue('code', e.target.value);
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <Modal
      passwordDialog
      withFooter={false}
      withHeader={false}
    >
      <FormikProvider value={formik}>
        <div className='w-full flex flex-col gap-[60px] px-[16px] py-[32px] max-tablet:gap-[20px]'>
          <DarkLogoSvg />
          <div className='w-full flex flex-col gap-[12px]'>
            <h1 className='font-medium text-moon-48 text-bulma tracking-[-0.04em] m-0 max-tablet:text-moon-32'>
              {definePasswordModalTitle(page)}
              <br className='!h-[0]' />
              use password
            </h1>
            <h5 className='font-normal text-moon-16 text-trunks m-0'>
              Use your password to login
            </h5>
          </div>
          <form className='flex justify-between items-start w-full gap-[5px]'>
            <div className='w-[292px] max-laptop:w-[100%]'>
              <div className='relative'>
                <Input
                  placeholder='Password'
                  className='pr-[48px] rounded-moon-i-md [&_select]:rounded-moon-i-md'
                  disabled={isLoading}
                  error={!!(formErrors?.code && touched?.code)}
                  value={values.code}
                  name='code' 
                  type='text'
                  size='lg'
                  id='code'
                  onKeyDown={handleKeyDown}
                  onSubmit={handleOnSubmit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <Button
                  className={mergeClassnames(btnIconCN, 'w-[24px] z-10 absolute top-[12px] right-[12px]')}
                  disabled={isLoading}
                  iconOnly={<ChevronRightSmallSvg />}
                  size='xs'
                  onClick={handleOnSubmit}
                />
              </div>

              {(!!formErrors?.code && touched?.code) && (
                <Hint error>{formErrors?.code}</Hint>
              )}
            </div>  
          </form>
        </div>
      </FormikProvider>
    </Modal>
  );
};

export default EventCodeConfirmationModal;
