import { mergeClassnames } from '@heathmont/moon-core-tw';

export const pageContentWrapperCN = mergeClassnames([
  'flex',
  'flex-col',
  'items-center',
  'justify-center',
  'mb-[40px]',
  'max-tablet:w-full',
]);

export const pageContentCN = mergeClassnames([
  'flex',
  'flex-col',
  'w-[586px]',
  'mb-[80px]',
  'max-tablet:w-full',
  'max-tablet:h-full',
]);

export const paymentCheckoutWrapperCN = mergeClassnames([
  'flex',
  'flex-col',
  'items-center',
  'justify-center',
  'mb-[40px]',
  'max-tablet:w-full',
]);

export const paymentCheckoutContentCN = mergeClassnames([
  'flex',
  'flex-col',
  'w-[586px]',
  'mb-[80px]',
  'max-tablet:h-full',
  'max-tablet:w-full',
]);
