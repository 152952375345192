import orderBy from 'lodash/orderBy';
import { IOption } from '../FlightBookingFilterOptions/types';
import { findOfferByItineraries } from '../../store/flightBookings/utils';
import { formatFlightDate } from '../FlightItinerary/utils';

export const formatItinerariesToOption = (itinerary): IOption => {
  if (!itinerary) {
    return null;
  }

  const { departure } = itinerary.segments[0];
  const { arrival } = itinerary.segments[itinerary.segments.length - 1];
  const { departureAt, arrivalAtFull } = formatFlightDate(departure, arrival);

  return {
    value: itinerary.id,
    label: `${departureAt} - ${arrivalAtFull}`,
  };
};

export const formatItineraries = (itineraries): IOption[] => {
  const orderedByTime = orderBy(itineraries, (itinerary) => new Date(itinerary.segments[0].departure.at), ['asc']);
  return orderedByTime.map(itinerary => formatItinerariesToOption(itinerary));
};

export const getFlightDifference = (group, itineraryData, isReturn) => {
  const itineraryFlight = findOfferByItineraries(group, [
    isReturn ? group.selectedOriginItinerary : itineraryData,
    isReturn ? itineraryData : group.selectedReturnItinerary,
  ]);
  return Math.round(+itineraryFlight?.price - +group.selectedOffer.price);
};

