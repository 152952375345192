import { mergeClassnames } from '@heathmont/moon-core-tw';

export const wrapperCN = mergeClassnames([
  'w-full',
  'flex',
  'items-center',
  'flex-wrap',
  'gap-1',
]);

export const itemCN = mergeClassnames([
  'items-center',
  'flex',
  'text-trunks',
  'text-moon-16',
  'max-tablet:text-moon-14',
]);

export const iconCN = mergeClassnames([
  'text-popo',
  'mr-[2.5px]',
  'max-tablet:hidden',
]);
