export const parseFareRule = (text) => text
  .replace(/APPLICATION/g, 'APPLICATION\n')
  // Add line break before and after hyphens
  .replace(/---+/g, '\n$&\n')
  // Add line break of the sentence if sentence more than 5 characters
  .replace(/\. (?!\n)/g, (match, sentence) => {
    if (sentence.length > 5) {
      return `${sentence  }\n`;
    }
    return match;
  })
  // Remove line breaks dublications
  .replace(/\s*\n\s*\n\s*/g, '\n');