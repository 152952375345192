import request from '../../utils/request';
import {
  SET_EVENT_LINK_ERROR,
  RESET_EVENT_LINK,
  SET_EVENT_LINK,
} from './actionTypes';
import * as sessionStorageHelper from '../../utils/session-storage';

export const resetEventLinkAction = () => ({
  type: RESET_EVENT_LINK,
});

export const setEventLinkAction = (eventLink) => ({
  type: SET_EVENT_LINK,
  payload: { eventLink },
});

export const setEventLinkErrorAction = (error) => ({
  type: SET_EVENT_LINK_ERROR,
  payload: { error },
});

export const setEventLinkExpired = () => {
  sessionStorageHelper.remove('event_token');
  return resetEventLinkAction();
};

export const getAccessToFlightBookingSearch = (code: string) => async (dispatch) => {
  try {
    const response = await request.post('white-label/auth', { code });
    const { eventLink, token } = response?.data;
    sessionStorageHelper.set('event_token', token);
    dispatch(setEventLinkAction(eventLink));
  } catch (err) {
    dispatch(setEventLinkErrorAction(err?.response?.data?.error));
  }
};

export const getEventLink = () => async (dispatch) => {
  try {
    const token = sessionStorageHelper.get('event_token');

    if (!token) {
      return;
    }
  
    const response = await request.get('white-label/events');
    dispatch(setEventLinkAction(response?.data?.eventLink));
  } catch (err) {
    console.error(err?.response?.data?.error);
  }
};
