import { useMemo } from 'react';
import { Group, Hint, InsetInput, mergeClassnames } from '@heathmont/moon-core-tw';
import { SecurityLock, GenericAlarm } from '@heathmont/moon-icons-tw';
import { IFlightBookingPassengerForm } from './types';
import {
  fieldGroupProgressCN,
  fieldGroupTitle,
  fieldWrapperCN,
  noteLabelCN,
  noteIconCN,
  groupCN,
} from './styles';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { PAYER_TYPE, payerTypeOptions } from '../../enums/PayerTypes';
import { PAYER_DOCUMENT_TYPES, payerDocumentTypeOptions } from '../../enums/PayerDocumentTypes';
import { isPaymentTypeSupportLowCostBookings } from '../../pages/FlightBookingPayment/utils';
import SelectOptions from '../SelectOptions';
import Dropdown from '../Dropdown';
import CoinLogo from '../CoinLogo';
import Tabs from '../Tabs';
import { getFieldBaseProps, getInformationAboutFee } from './utils';
import PaymentTypeSelect from '../PaymentTypeSelect';
import { textEllipsisCN } from '../../styles/texts';

const FlightBookingPaymentForm: React.FC<IFlightBookingPassengerForm> = ({
  travelDocumentsTypes,
  isCryptoUnavailable, 
  isLowCostBooking,
  coinsLoading,
  countries,
  touched,
  payment,
  errors,
  coins,
  handleSelectPaymentCoin,
  handleSelectPaymentType,
  setPaymentPayerDetails,
  setPaymentPayerType,
  setFieldTouched,
  setFieldValue,
}) => {
  const isPaymentDisabledForLowCostBookings = useMemo(() => (
    isLowCostBooking && !isPaymentTypeSupportLowCostBookings(payment.paymentType)
  ), [isLowCostBooking, payment.paymentType]);

  const isPaymentDisabled = useMemo(() => {
    return isCryptoUnavailable && payment.paymentType === PAYMENT_TYPE.CRYPTO;
  }, [isCryptoUnavailable, payment.paymentType]);

  const renderCryptoCurrencyItem = (option: any) => {
    return (
      <div className={mergeClassnames('flex items-center gap-[10px]', textEllipsisCN)}>
        <CoinLogo coin={option.value} />
        <span
          className={mergeClassnames('after:text-moon-12 after:text-trunks after:-mb-[3px] after:tracking-[1.5px] after:content-[attr(coin-exchange-rate-attr)]', textEllipsisCN)}
          coin-exchange-rate-attr={` ${option.exchange}`}
        >
          {option.label}
        </span>
      </div>
    );
  };

  const renderError = () => {
    if (isPaymentDisabledForLowCostBookings) {
      return (
        <>
          <GenericAlarm className={`${noteIconCN} text-dodoria`} /> Payment method is not available for low cost flights booking
        </>
      );
    }

    if (isPaymentDisabled) {
      return (
        <>
          <GenericAlarm className={`${noteIconCN} text-dodoria`} /> Currently payment method is not available. Please, try again later or select other payment method
        </>
      )
    }

    return null;    
  };

  return (
    <div className='w-full flex flex-col gap-[16px]'>
      <div className={noteLabelCN}>
        <SecurityLock className={noteIconCN} /> Personal information protected and encrypted
      </div>

      <div className='w-full flex flex-col gap-[16px] relative pl-[20px]'>
        <div className='absolute h-[calc(100%-21px)] top-[10.5px] left-px w-px bg-trunks opacity-30' />
      
        <h5 className={fieldGroupTitle}>
          <div className={fieldGroupProgressCN(true)}/>
          Select a payment method
        </h5>

        <div className='flex flex-col gap-[16px]'>
          <PaymentTypeSelect 
            paymentType={payment.paymentType}
            onChange={(value) => handleSelectPaymentType(value, travelDocumentsTypes)}
          />
          <div className={noteLabelCN}>
            {isPaymentDisabledForLowCostBookings || isPaymentDisabled ? (
              renderError()
            ) : (
              <>
                <GenericAlarm className={noteIconCN} /> {getInformationAboutFee(payment.paymentType)}
              </>
            )}
          </div>
        </div>

        {
          !isPaymentDisabledForLowCostBookings && !isPaymentDisabled && (
            <>
              <h5 className={fieldGroupTitle}>
                <div className={fieldGroupProgressCN(true)} />
                {payment.paymentType === PAYMENT_TYPE.INVOICE && 'Invoice details'}
                {payment.paymentType === PAYMENT_TYPE.CRYPTO && 'Choose crypto'}
                {payment.paymentType === PAYMENT_TYPE.CARD && 'Payer details'}
              </h5>
              
              <div className='w-full flex flex-col gap-[16px]'>
                {payment.paymentType === PAYMENT_TYPE.CRYPTO && (
                  <div className={fieldWrapperCN}>
                    <Dropdown
                      placeholder={'Coin'}
                      disabled={coinsLoading}
                      options={coins}
                      error={!!(errors?.coin && touched?.coin)}
                      value={payment.coin}
                      renderOption={renderCryptoCurrencyItem}
                      onChange={value => handleSelectPaymentCoin(value as string, travelDocumentsTypes)}
                    />

                    {!!(errors?.coin && touched?.coin) && (
                      <Hint error>{errors?.coin}</Hint>
                    )}
                  </div>
                )}

                <div className={fieldWrapperCN}>
                  <Tabs
                    value={payment.payerType}
                    options={payerTypeOptions}
                    onChange={(value) => setPaymentPayerType(value, travelDocumentsTypes)}
                  />
                </div>

                {
                  !(payment.payerType === PAYER_TYPE.BUSINESS && payment.paymentType === PAYMENT_TYPE.CRYPTO) && (
                    <div className={fieldWrapperCN}>
                      <Group className={groupCN}>
                        <Group.FirstInsetInput
                          {...getFieldBaseProps('payerName')}
                          placeholder=' '
                          error={!!(errors?.payerName && touched?.payerName)}
                          value={payment.payerName}
                          onChange={({ target: { value } }) => setFieldValue('payerName', value)}
                          onBlur={() => {
                            setFieldTouched('payerName')
                            setPaymentPayerDetails(payment);
                          }}
                        >
                          <InsetInput.Label>Name</InsetInput.Label>
                        </Group.FirstInsetInput>
                        <Group.LastInsetInput
                          {...getFieldBaseProps('payerEmail')}
                          placeholder=' '
                          error={!!(errors?.payerEmail && touched?.payerEmail)}
                          value={payment.payerEmail}
                          type='email'
                          onChange={({ target: { value } }) => setFieldValue('payerEmail', value)}
                          onBlur={() => {
                            setFieldTouched('payerEmail')
                            setPaymentPayerDetails(payment);
                          }}
                        >
                          <InsetInput.Label>Email</InsetInput.Label>
                        </Group.LastInsetInput>
                      </Group>

                      {!!(errors?.payerName && touched?.payerName) && (
                        <Hint error>{errors?.payerName}</Hint>
                      )}
                    
                      {!!(errors?.payerEmail && touched?.payerEmail) && (
                        <Hint error>{errors?.payerEmail}</Hint>
                      )}
                    </div>
                  )
                }

                {
                  payment.paymentType === PAYMENT_TYPE.CRYPTO && (
                    <>
                      <div className={fieldWrapperCN}>
                         <Group className={groupCN}>
                          <Group.FirstInsetSelect
                            {...getFieldBaseProps('payerDocumentType')}
                            label='Document type'
                            error={!!(errors?.payerDocumentType && touched?.payerDocumentType)}
                            value={payment.payerDocumentType || ''}
                            onChange={({ target: { value } }) => {
                              setFieldTouched('payerDocumentType');
                              setFieldValue('payerDocumentType', value);
                              setPaymentPayerDetails({ ...payment, payerDocumentType: value as PAYER_DOCUMENT_TYPES });
                            }}
                            onBlur={() => setFieldTouched('payerDocumentType')}
                          >
                            <SelectOptions options={payerDocumentTypeOptions} label='document type' />
                          </Group.FirstInsetSelect>
                          <Group.LastInsetInput
                            {...getFieldBaseProps('payerDocumentNumber')}
                            placeholder=' '
                            error={!!(errors?.payerDocumentNumber && touched?.payerDocumentNumber)}
                            value={payment.payerDocumentNumber}
                            onChange={({ target: { value } }) => setFieldValue('payerDocumentNumber', value)}
                            onBlur={() => {
                              setFieldTouched('payerDocumentNumber')
                              setPaymentPayerDetails(payment);
                            }}
                          >
                            <InsetInput.Label>Document number</InsetInput.Label>
                          </Group.LastInsetInput>
                        </Group>

                        {!!(errors?.payerDocumentType && touched?.payerDocumentType) && (
                          <Hint error>{errors?.payerDocumentType}</Hint>
                        )}
                        {!!(errors?.payerDocumentNumber && touched?.payerDocumentNumber) && (
                          <Hint error>{errors?.payerDocumentNumber}</Hint>
                        )}
                      </div>

                      <div className={fieldWrapperCN}>
                        <Group className={groupCN}>
                          <Group.FirstInsetInput
                            {...getFieldBaseProps('payerBirthDate')}
                            placeholder=' '
                            error={!!(errors?.payerBirthDate && touched?.payerBirthDate)}
                            value={payment.payerBirthDate}
                            type='date'
                            onChange={({ target: { value } }) => setFieldValue('payerBirthDate', value || '')}
                            onBlur={() => {
                              setFieldTouched('payerBirthDate')
                              setPaymentPayerDetails(payment);
                            }}
                          >
                            <InsetInput.Label>Birth date</InsetInput.Label>
                          </Group.FirstInsetInput>
                          <Group.LastInsetSelect
                            {...getFieldBaseProps('payerBirthCountry')}
                            label='Birth country'
                            error={!!(errors?.payerBirthCountry && touched?.payerBirthCountry)}
                            value={payment.payerBirthCountry ? `${payment.payerBirthCountry}` : ''}
                            onChange={({ target: { value } }) => {
                              setFieldTouched('payerBirthCountry');
                              setFieldValue('payerBirthCountry', value);
                              setPaymentPayerDetails({ ...payment, payerBirthCountry: +value });
                            }}
                            onBlur={() => setFieldTouched('payerBirthCountry')}
                          >
                            <SelectOptions options={countries} label='birth country' />
                          </Group.LastInsetSelect>
                        </Group>

                        {!!(errors?.payerBirthDate && touched?.payerBirthDate) && (
                          <Hint error>{errors?.payerBirthDate}</Hint>
                        )}
                        {!!(errors?.payerBirthCountry && touched?.payerBirthCountry) && (
                          <Hint error>{errors?.payerBirthCountry}</Hint>
                        )}
                      </div>
                    </>
                  )
                }

                {
                  payment.payerType === PAYER_TYPE.BUSINESS && payment.paymentType === PAYMENT_TYPE.CRYPTO && (
                    <>
                      <div className={fieldWrapperCN}>
                        <InsetInput
                          className={groupCN}
                          placeholder=' '
                          error={!!(errors?.payerEmail && touched?.payerEmail)}
                          name='payerEmail' 
                          type='email'
                          value={payment.payerEmail}
                          id='payerEmail'
                          onChange={({ target: { value } }) => setFieldValue('payerEmail', value)}
                          onBlur={() => {
                            setFieldTouched('payerEmail')
                            setPaymentPayerDetails(payment);
                          }}
                        >
                          <InsetInput.Label>Email</InsetInput.Label>
                        </InsetInput>
                    
                        {!!(errors?.payerEmail && touched?.payerEmail) && (
                          <Hint error>{errors?.payerEmail}</Hint>
                        )}
                      </div>
                    
                      <h5 className='text-moon-16 font-normal text-trunks m-0 p-0'>
                        Company
                      </h5>

                      <div className={fieldWrapperCN}>
                        <InsetInput
                          placeholder=' '
                          className={groupCN}
                          error={!!(errors?.payerName && touched?.payerName)}
                          name='payerName' 
                          type='text'
                          value={payment.payerName}
                          id='payerName'
                          onChange={({ target: { value } }) => setFieldValue('payerName', value)}
                          onBlur={() => {
                            setFieldTouched('payerName')
                            setPaymentPayerDetails(payment);
                          }}
                        >
                          <InsetInput.Label>Name</InsetInput.Label>
                        </InsetInput>
                  
                        {!!(errors?.payerName && touched?.payerName) && (
                          <Hint error>{errors?.payerName}</Hint>
                        )}
                      </div>
                    </>
                  )
                }

                {
                  payment.payerType === PAYER_TYPE.BUSINESS && (
                    <div className={fieldWrapperCN}>
                      <Group className={groupCN}>
                        <Group.FirstInsetInput
                          {...getFieldBaseProps('payerRegistrationNumber')}
                          placeholder=' '
                          error={!!(errors?.payerRegistrationNumber && touched?.payerRegistrationNumber)}
                          value={payment.payerRegistrationNumber}
                          onChange={({ target: { value } }) => setFieldValue('payerRegistrationNumber', value)}
                          onBlur={() => {
                            setFieldTouched('payerRegistrationNumber')
                            setPaymentPayerDetails(payment);
                          }}
                        >
                          <InsetInput.Label>Registration number</InsetInput.Label>
                        </Group.FirstInsetInput>
                        <Group.LastInsetInput
                          {...getFieldBaseProps('payerVatNumber')}
                          placeholder=' '
                          error={!!(errors?.payerVatNumber && touched?.payerVatNumber)}
                          value={payment.payerVatNumber}
                          onChange={({ target: { value } }) => setFieldValue('payerVatNumber', value)}
                          onBlur={() => {
                            setFieldTouched('payerVatNumber')
                            setPaymentPayerDetails(payment);
                          }}
                        >
                          <InsetInput.Label>VAT Number (enter No if not VAT liable)</InsetInput.Label>
                        </Group.LastInsetInput>
                      </Group>
                      {!!(errors?.payerRegistrationNumber && touched?.payerRegistrationNumber) && (
                        <Hint error>{errors?.payerRegistrationNumber}</Hint>
                      )}
                      {!!(errors?.payerVatNumber && touched?.payerVatNumber) && (
                        <Hint error>{errors?.payerVatNumber}</Hint>
                      )}
                    </div>
                  )
                }

                <div className={fieldWrapperCN}>
                  <Group className={groupCN}>
                    <Group.FirstInsetSelect
                      {...getFieldBaseProps('payerCountry')}
                      label='Country'
                      error={!!(errors?.payerCountry && touched?.payerCountry)}
                      value={payment.payerCountry ? `${payment.payerCountry}` : ''}
                      onChange={({ target: { value } }) => {
                        setFieldTouched('payerCountry');
                        setFieldValue('payerCountry', value);
                        setPaymentPayerDetails({ ...payment, payerCountry: +value });
                      }}
                      onBlur={() => setFieldTouched('payerCountry')}
                    >
                      <SelectOptions options={countries} label='country' />
                    </Group.FirstInsetSelect>
                    <Group.LastInsetInput
                      {...getFieldBaseProps('payerCity')}
                      placeholder=' '
                      error={!!(errors?.payerCity && touched?.payerCity)}
                      value={payment.payerCity}
                      onChange={({ target: { value } }) => setFieldValue('payerCity', value)}
                      onBlur={() => {
                        setFieldTouched('payerCity')
                        setPaymentPayerDetails(payment);
                      }}
                    >
                      <InsetInput.Label>City</InsetInput.Label>
                    </Group.LastInsetInput>
                  </Group>
                  {!!(errors?.payerCountry && touched?.payerCountry) && (
                    <Hint error>{errors?.payerCountry}</Hint>
                  )}
                  {!!(errors?.payerCity && touched?.payerCity) && (
                    <Hint error>{errors?.payerCity}</Hint>
                  )}
                </div>

                <div className={fieldWrapperCN}>
                  <InsetInput
                    placeholder=' '
                    className={groupCN}
                    error={!!(errors?.payerStateOrCounty && touched?.payerStateOrCounty)}
                    name='payerStateOrCounty' 
                    type='text'
                    value={payment.payerStateOrCounty}
                    id='payerStateOrCounty'
                    onChange={({ target: { value } }) => setFieldValue('payerStateOrCounty', value)}
                    onBlur={() => {
                      setFieldTouched('payerStateOrCounty')
                      setPaymentPayerDetails(payment);
                    }}
                  >
                    <InsetInput.Label>State or county (optional)</InsetInput.Label>
                  </InsetInput>

                  {!!(errors?.payerStateOrCounty && touched?.payerStateOrCounty) && (
                    <Hint error>{errors?.payerStateOrCounty}</Hint>
                  )}
                </div>

                <div className={fieldWrapperCN}>
                  <Group className={groupCN}>
                    <Group.FirstInsetInput
                      {...getFieldBaseProps('payerPostcode')}
                      placeholder=' '
                      error={!!(errors?.payerPostcode && touched?.payerPostcode)}
                      value={payment.payerPostcode}
                      onChange={({ target: { value } }) => setFieldValue('payerPostcode', value)}
                      onBlur={() => {
                        setFieldTouched('payerPostcode')
                        setPaymentPayerDetails(payment);
                      }}
                    >
                      <InsetInput.Label>Postcode</InsetInput.Label>
                    </Group.FirstInsetInput>
                    <Group.LastInsetInput
                      {...getFieldBaseProps('payerAddress')}
                      placeholder=' '
                      error={!!(errors?.payerAddress && touched?.payerAddress)}
                      value={payment.payerAddress}
                      onChange={({ target: { value } }) => setFieldValue('payerAddress', value)}
                      onBlur={() => {
                        setFieldTouched('payerAddress')
                        setPaymentPayerDetails(payment);
                      }}
                    >
                      <InsetInput.Label>Address line</InsetInput.Label>
                    </Group.LastInsetInput>
                  </Group>
                  {!!(errors?.payerPostcode && touched?.payerPostcode) && (
                    <Hint error>{errors?.payerPostcode}</Hint>
                  )}
                  {!!(errors?.payerAddress && touched?.payerAddress) && (
                    <Hint error>{errors?.payerAddress}</Hint>
                  )}
                </div>
              </div>

              <h5 className={fieldGroupTitle}>
                <div className={fieldGroupProgressCN(false)} />
                Your ticket
              </h5>
            </>
          )
        }
      </div>
    </div>
  );
};

export default FlightBookingPaymentForm;
