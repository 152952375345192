import { useState, useEffect } from 'react'; 
import { ITimer } from './types';
import {
  numberContainerCN,
  numberWrapperCN,
  numberLabelCN,
  colonItemCN,
  colonCN,
} from './styles';

const Timer: React.FC<ITimer> = ({
  deadline,
  onTimerFinish,
}) => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const getTime = () => {
    const time = deadline.getTime() - new Date().getTime();

    if (time <= 0) {
      onTimerFinish();
    }

    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='flex gap-[5px] items-start'>
      <div className={numberWrapperCN}>
        <div className={numberContainerCN}>
          {minutes > 0 ? minutes : 0}
        </div>
        <div className={numberLabelCN}>MIN</div>
      </div>
      <div className={colonCN}>
        <span className={colonItemCN} />
        <span className={colonItemCN} />
      </div>
      <div className={numberWrapperCN}>
        <div className={numberContainerCN}>
          {seconds > 0 ? seconds : 0}
        </div>
        <div className={numberLabelCN}>SEC</div>
      </div>
    </div>
  );
};

export default Timer;
