import { mergeClassnames } from '@heathmont/moon-core-tw';
import { textEllipsisCN } from '../../styles/texts';

export const itineraryCN = ({
  isLoading,
  isReturn,
  isOneWay,
}: {
  isLoading: boolean,
  isReturn: boolean,
  isOneWay: boolean,
}) =>  mergeClassnames([
  'w-full',
  'flex',
  'items-center',
  'bg-goku',
  'px-[32px]',
  'py-[24px]',
  'rounded-[12px]',
  'pr-[175px]',
  'max-tablet:px-[16px]',
  'max-tablet:py-[24px]',
  'max-tablet:pr-[75px]',
  !isLoading && 'cursor-pointer group-hover:shadow-moon-xs',
  isReturn && 'border-t border-dashed border-beerus',
  isOneWay && 'max-mobile:px-[16px] max-mobile:py-[32px]',
]);

export const airlineLogoCN = mergeClassnames([
  'w-[37px]',
  'h-[37px]',
  'flex',
  'justify-center',
  'items-center',
  'flex-shrink-0',
  'mr-[30px]',
  'max-tablet:w-[28px]',
  'max-tablet:h-[28px]',
  'max-tablet:mr-[15px]',
]);

export const placeAndTimeCN = ({ start, end }: { start?: boolean, end?: boolean }) => mergeClassnames([
  'flex',
  'flex-col',
  'w-[55px]',
  start && 'items-start',
  end && 'items-end',  
  'max-tablet:min-w-[0]'
]);

export const flightTimeCN = mergeClassnames([
  'text-[18px]',
  'leading-[24px]',
  'font-medium',
  'relative',
  'max-tablet:text-[16px]',
  'max-tablet:leading-[18px]',
]);

export const daysDifferenceWrapperLabelCN = mergeClassnames([
  'absolute',
  'font-normal',
  'left-[calc(100%+2.5px)]',
  'top-[0]',
]);

export const daysDifferenceLabelCN = mergeClassnames([
  'text-trunks',
  'font-normal',
  'text-moon-12',
  'hover:font-bold'
]); 

export const flightPlaceCN = mergeClassnames([
  'text-[14px]',
  'font-normal',
  'leading-[18px]',
  'tracking-[1.5px]',
  'max-tablet:text-[10px]',
  'max-tablet:leading-[14px]',
]);

export const flightWayCN = mergeClassnames([
  'flex',
  'flex-col',
  'justify-center',
  'items-center',
  'gap-[10px]',
  'max-tablet:gap-[5px]',
]);

export const wayLabelCN = ({ visible, visibleOnlyOnMobile }: { visible: boolean, visibleOnlyOnMobile?: boolean }) => mergeClassnames([
  'text-trunks',
  'text-[12px]',
  'leading-[16px]',
  'max-tablet:text-[10px]',
  'max-tablet:leading-[14px]',
  !visible && '!text-goku select-none',
  visibleOnlyOnMobile && 'laptop:!text-goku laptop:select-none',
]);

export const wayLineCN = mergeClassnames([
  'h-px',
  'w-[264px]',
  'bg-trunks',
  'relative',
  'max-laptop:w-[calc(20vw+60px)]',
  'max-tablet:w-[calc(25vw+30px)]',
  'max-mobile:w-[93px]',
]);

export const flightWayStopDotCN = mergeClassnames([
  'absolute',
  '-top-[2.5px]',
  'right-[0]',
  'translate-x-1/2',
  'w-[14px]',
  'h-[5px]',
  'bg-trunks',
  'rounded-[20px]',
  'max-tablet:w-[8px]',
]);

export const segmentDurationCN = mergeClassnames([
  'absolute',
  '-top-[10px]',
  'left-2/4',
  '-translate-x-1/2',
  '-translate-y-full',
  'text-trunks',
  'text-[12px]',
  'leading-[16px]',
  'max-laptop:hidden',
]);

export const wayStopLabel = mergeClassnames([
  'text-trunks',
  'text-[12px]',
  'leading-[16px]',
  'max-table:text-[10px]',
  'max-table:leading-[14px]',
  'hover:font-bold',
]);

export const extraInfoSubTitleCN = mergeClassnames([
  'text-[14px]',
  'leading-[18px]',
  'text-trunks',
  'font-normal',
  'max-w-[100px]',
  textEllipsisCN,
]);

export const extraInfoTitleCN = mergeClassnames([
  extraInfoSubTitleCN,
  'text-[18px]',
  'leading-[24px]',
  'text-bulma',
  'font-medium',
]);
