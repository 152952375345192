import { mergeClassnames } from '@heathmont/moon-core-tw';

export const segmentWrapperCN = (withBorder: boolean) => mergeClassnames([
  'w-full',
  'bg-[#fff]',
  'rounded-[12px]',
  'relative',
  'px-[32px]',
  'py-[24px]',
  'max-tablet:px-[16px]',
  withBorder && 'border-t border-dashed border-beerus',
]);

export const segmentStopLabelCN = mergeClassnames([
  'absolute',
  'bg-goku',
  'p-[6px]',
  'text-moon-14',
  'font-normal',
  'text-trunks',
  'left-2/4',
  'top-full',
  'z-10',
  '-translate-y-1/2',
  '-translate-x-1/2',
  'max-tablet:text-moon-12',
]);

export const airlineLogoCN = mergeClassnames([
  'w-[16px]',
  'h-[16px]',
  'flex',
  'justify-center',
  'items-center',
  'max-tablet:w-[14px]',
  'max-tablet:h-[14px]',
]);

export const flightNumberCN = mergeClassnames([
  'text-moon-16',
  'max-tablet:text-moon-12',
]);

export const segmentOverviewWrapperCN = mergeClassnames([
  'flex',
  'flex-row',
  'justify-center',
  'items-center',
  'w-full',
  'gap-[50px]',
  'mt-[12px]',
  'content-center',
  'max-tablet:gap-[25px]'
]); 

export const segmentTimeAndPlaceCN = ({ end, start }: { end?: boolean, start?: boolean }) => mergeClassnames([
  'flex',
  'flex-col',
  start && 'items-start',
  end && 'items-end',
  'max-table:min-w-[0]',
]);

export const segmentTimeCN = mergeClassnames([
  'text-moon-24',
  'font-medium',
  'relative',
  'max-tablet:text-moon-18',
]);

export const segmentPlaceCN = mergeClassnames([
  'text-moon-16',
  'font-normal',
  'relative',
  'tracking-[1.5px]',
  'max-tablet:text-moon-14',
]);

export const segmentTimeLineLabelCN = mergeClassnames([
  'absolute',
  'bg-goku',
  'p-[6px]',
  'text-moon-16',
  'font-medium',
  'text-popo',
  'left-2/4',
  'top-2/4',
  '-translate-y-1/2',
  '-translate-x-1/2',
  'max-tablet:text-moon-14',
]);
