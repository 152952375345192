import moment from 'moment';
import airports from 'airport-codes';
import { IncludedCheckedBags } from '../enums/IncludedCheckedBags';

export const getCityByIataCode = (iataCode) => {
  const AIRPORT_EXCEPTIONS = ['TLL'];

  if (AIRPORT_EXCEPTIONS.includes(iataCode)) {
    return 'Tallinn';
  }

  const airport = airports.findWhere({ iata: iataCode });

  return airport ? airport.get('city') : null;
};

export const getCountryByIataCode = (iataCode) => {
  const airport = airports.findWhere({ iata: iataCode });
  return airport ? airport.get('country') : null;
};

export const hasOvernightLayover = (itinerary: { segments: any[] }): boolean => {
  const OVERNIGHT_LAYOVER_TIME = 'T05:00:00.000Z';

  return itinerary?.segments.some((segment, index) => {
    if (index === itinerary.segments.length - 1) {
      return false;
    }

    const arrival = segment.arrival.at;
    const arrivalAt = moment.utc(arrival);
    const departure = itinerary?.segments[index + 1].departure.at;
    const departureAt = moment.utc(departure);
    const nextMorning = moment.utc(`${departureAt.clone().format('YYYY-MM-DD')}${OVERNIGHT_LAYOVER_TIME}`);

    return !arrivalAt.isSame(departureAt, 'day') && departureAt.isSameOrAfter(nextMorning);
  });
};

export const isBaggageIncluded = (bags) => {
  return +bags?.quantity > 0 || +bags?.weight > 0;
};

export const getBaggageLabel = (includedCheckedBags: IncludedCheckedBags = {}): string => {
  return `Baggage: ${isBaggageIncluded(includedCheckedBags) ? 'included' : 'not-included'}`;
};

export const getBaggageDescription = ({
  detailed = true,
  label = 'included',
  bags = {},
}: {
  detailed?: boolean,
  label?: string,
  bags: IncludedCheckedBags,
}): string => {
  const { quantity, weight, weightUnit = 'KG' } = bags;

  if (isBaggageIncluded(bags)) {
    const baggagesQuantity = +quantity || 1;

    let description = `${baggagesQuantity} ${label}`;

    if (detailed) {
      description += ` baggage${baggagesQuantity > 1 ? 's' : ''}`;
    }

    if (+weight) {
      description += ` up to ${weight} ${weightUnit?.toLowerCase()}`;
    }

    if (detailed) {
      description += ' per passenger'
    }

    return description;
  }

  return 'not-included';
};
