import moment from 'moment';
import { Popover, Group, InsetInput } from '@heathmont/moon-core-tw';
import DateRangePicker from '../DateRangePicker';
import { formatDate } from './utils';
import { IFlightBookingDatesRangePicker } from './types';
import { inputCN, labelCN, popoverPanelCN } from './styles';

const FlightBookingDatesRangePicker: React.FC<IFlightBookingDatesRangePicker> = ({
  departureDate,
  returnDate,
  setFieldValue,
}) => {
  const handleDateRangeChange = (close) => ({ startDate, endDate }: { startDate: Date; endDate: Date }, forceClose = true) => { 
    setFieldValue('departureDate', startDate ? moment(startDate).format() : null);
    setFieldValue('returnDate', endDate ? moment(endDate).format() : null);
    forceClose && close();
  };

  return (
    <Popover position='bottom'>
      {({ open }) => {
        return (
          <>
            <Popover.Trigger>
              <Group className='rounded-moon-i-md' orientation='horizontal'>
                <Group.FirstInsetInput
                  className={inputCN(open)}
                  placeholder=' '
                  value={departureDate ? formatDate(departureDate) : open ? ' ' : ''}
                  onChange={() => {}}
                >
                  <InsetInput.Label className={labelCN(!!departureDate || open)}>
                    Departure
                  </InsetInput.Label>
                </Group.FirstInsetInput>
                <Group.LastInsetInput
                  className={inputCN(open)}
                  placeholder=' '
                  value={returnDate ? formatDate(returnDate) : open ? ' ' : ''}
                  onChange={() => {}}
                >
                  <InsetInput.Label className={labelCN(!!returnDate || open)}>
                    Return
                  </InsetInput.Label>
                </Group.LastInsetInput>
              </Group>
            </Popover.Trigger>
            <Popover.Panel className={popoverPanelCN}>
              {({ close }) => (
                <DateRangePicker
                  startDate={departureDate}
                  endDate={returnDate}
                  handleChange={handleDateRangeChange(close)}
                  handleClose={close}
                />
              )}
            </Popover.Panel>
          </>
        );
      }}
    </Popover>
  );
};

export default FlightBookingDatesRangePicker;
