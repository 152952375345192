import { mergeClassnames } from '@heathmont/moon-core-tw';

export const noteWrapperCN = mergeClassnames([
  'flex',
  'items-center',
  'flex-start',
  'gap-[7.5px]',
  'text-trunks',
  'w-full',
]);

export const noteTextCN = mergeClassnames([
  'text-moon-14',
  'font-normal',
  'text-trunks',
  'flex',
  'items-center',
  'gap-[7.5px]',
]);

export const btnInputCN = mergeClassnames([
  'h-[32px]',
  'w-[32px]',
  '-translate-y-1/2',
  'ring-beerus',
  'hover-supported:hover:ring-beerus'
]);

export const iconCN = mergeClassnames([
  'text-moon-24',
  'flex-shrink-0',
]);

export const qrCodeWrapper = (visible: boolean) => mergeClassnames([
  visible ? 'flex' : 'hidden',
  'justify-center',
  'items-center',
  'flex-col',
  'gap-[20px]',
]);
