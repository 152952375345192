import axios from 'axios';
import { POPULAR_DESTINATIONS } from '../../constants';
import { DestinationsGroup } from '../../enums/Destination';
import request from '../../utils/request';
import { formatDestinations } from '../../utils/destinations';

export const handleFetchOptions = async ({
  search,
  source,
  setOptionsGroup,
  setLoading,
} : {
  search: string;
  source: any;
  setOptionsGroup: (options: DestinationsGroup) => void;
  setLoading: (isLoading: boolean) => void;
}) => {
  setLoading(true);

  try {
    const response = search
      ? await request.get('white-label/locations', { cancelToken: source.token, params: { search } })
      : null;
    
    if (response?.data?.locations?.length) {
      setOptionsGroup(formatDestinations({
        destinations: response.data.locations,
        popular: false,
      }));
    } else {
      setOptionsGroup(formatDestinations({
        destinations: POPULAR_DESTINATIONS,
        popular: true,
      }));
    }

    setLoading(false);
    return search;
  } catch (err) {
    if (axios.isCancel(err)) {
      console.error('Request canceled');
    } else {
      setLoading(false);
      setOptionsGroup(formatDestinations({
        destinations: POPULAR_DESTINATIONS,
        popular: true,
      }));
    }
    
    return search;
  }
};