import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-xhr-backend';

const i18nObject = {};

export const init = async () =>
  i18n.use(LanguageDetector).use(backend).use(initReactI18next).init({
    fallbackLng: 'en-GB',
    debug: true,
  });

export default i18nObject;
