import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import { IncludedBagOptionValue } from '../../enums/IncludedBag';
import { Passenger } from '../../enums/Passenger';
import request from '../../utils/request';

export const formatDocumentTypes = (documentTypes = []): { value: number, label: string }[] => {
  return documentTypes.map(documentType => ({
    value: documentType.id,
    label: documentType.name,
  }));
};

export const formatCountries = (countries = []): { value: number, label: string, flag: string }[] => {
  return countries.map(country => ({
    value: country.id,
    label: country.name,
    flag: country.flag,
  }));
};

export const formatAirlines = (airlines = []): { value: number, label: string }[] => {
  return airlines.map(airline => ({
    value: airline.value,
    label: airline.title,
  }));
};

export const getOptionsOnInit = async (options, setOptions) => {
  try {
    setOptions({ ...options, loading: true });
  
    const [
      travelDocumentsTypesResponse,
      countriesResponse,
      airlinesResponse,
    ] = await Promise.all([
      request.get('white-label/travel-documents-types'),
      request.get('white-label/countries'),
      request.get('white-label/airlines'),
    ]);

    setOptions({
      ...options,
      travelDocumentsTypes: formatDocumentTypes(travelDocumentsTypesResponse?.data?.travelDocumentsTypes),
      countries: formatCountries(countriesResponse?.data?.countries),
      airlines: formatAirlines(airlinesResponse?.data?.airlines),
      loading: false,
    });
  } catch (err) {
    setOptions({ ...options, loading: false });
  }
};

export const isAllBagOptionsHeterogeneous = (bags: IncludedBagOptionValue[]): boolean => {
  const byOfferId = groupBy(bags, 'offerId');
  return Object.keys(byOfferId).every((offerId) => {
    return uniqBy(byOfferId[offerId], (bag) => {
      let labels: (string | number)[] = [bag.quantity];
      
      if (bag.weight) {
        labels.push(bag.weight);
      }

      if (bag.weightUnit) {
        labels.push(bag.weightUnit);
      }

      return labels.join('_');
    }).length === 1
  });
};

export const isBagOptionsSinglePerFlight = (bags: IncludedBagOptionValue[]): boolean => {
  return uniqBy(bags, 'label').length !== bags.length;
};

export const validateBaggages = (bags: IncludedBagOptionValue[], passengers: Passenger[]) => {
  const validations = passengers.map(passenger => {
    const passengerBags = bags.filter(bag => bag.passengerId === passenger.id);

    if (isBagOptionsSinglePerFlight(passengerBags)) {
      return 'Only 1 additional baggage option can be added to the outbound flight and 1 for return flight';
    }
    
    if (!isAllBagOptionsHeterogeneous(passengerBags)) {
      return 'Bag options can not be heterogeneous';
    }

    return null;
  });

  if (validations.every(validation => !validation)) {
    return null;
  }

  return validations;
};

export const getPassengerFullName = (passenger) => {
  const name = [];

  if (passenger.firstName) {
    name.push(passenger.firstName);
  }

  if (passenger.lastName) {
    name.push(passenger.lastName);
  }

  return name.length ? name.join(' ') : null;
};
