import { CreditCarPayment } from '../../enums/CreditCardOrder';

export const extractCardholderName = (payments: CreditCarPayment[]) => {
  return payments?.length
    ? payments[payments.length - 1]?.payment_method?.cardholder_name
    : ''
};

export const extractCardNumber = (payments: CreditCarPayment[]) => {
  const lastFourNumbers = payments?.length
    ? payments[payments.length - 1]?.payment_method?.card_last_four
    : '• • • •'
  return `• • • •\u00a0\u00a0\u00a0• • • •\u00a0\u00a0\u00a0• • • •\u00a0\u00a0\u00a0${lastFourNumbers}`;
};

export const extractCardSecurityAndExpiry = (payments: CreditCarPayment[]) => {
  const expiry = payments?.length
    ? payments[payments.length - 1]?.payment_method?.card_expiry
    : '• •/• •'
  return `${expiry}\u00a0\u00a0\u00a0• • •`;
};

