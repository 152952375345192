import { mergeClassnames } from '@heathmont/moon-core-tw';

export const btnSecondaryCN = mergeClassnames([
  'text-bulma',
  'bg-beerus',
]);

export const btnDialogCN = mergeClassnames([
  'max-w-[50%]',
  'min-w-[125px]',
]);

export const btnArrow = mergeClassnames([
  'py-3',
  'bg-goku',
  'text-bulma',
  'font-bold',
  'rounded-full',
  'shadow-xl',
]);

export const btnContinueCN = mergeClassnames([
  'h-[78px]',
  'max-tablet:h-[68px]',
  'max-tablet:flex-[1]',
]);

export const btnDownloadCN = mergeClassnames([
  'w-[260px]',
  'min-w-[150px]',
  'text-moon-20',
  'font-medium',
  'max-tablet:w-[calc(100%-60px)]',
]);

export const btnOfferPriceCN = mergeClassnames([
  'bg-goku',
  'text-bulma',
  'text-moon-32',
  'font-medium',
  'max-laptop:hidden',
  'hover:bg-piccolo',
  'hover:text-goku',
]);

export const btnStatic = mergeClassnames([
  'disabled:bg-[#bfcef4]',
  'disabled:opacity-100',
]);

export const btnIconCN = mergeClassnames([
  'bg-popo',
  'text-goku',
  'flex',
  'justify-center',
  'items-center',
  'rounded-full',
]);

export const btnChipCN = mergeClassnames([
  'bg-goten',
  'text-bulma',
  'rounded-full',
]);

export const btnLink = mergeClassnames([
  'cursor-pointer',
  'font-normal',
  'text-bulma',
  'underline',
  'hover:text-bulma',
  'hover:underline',
  'hover:[text-shadow:0_0_0.01px_#000000]',
]);

export const btnReset = mergeClassnames([
  'cursor-pointer',
  'hover:[text-shadow:0_0_0.01px_#000000]',
]);
