import { Fragment } from 'react';
import { Button, mergeClassnames } from '@heathmont/moon-core-tw';
import { ArrowsTopRight } from '@heathmont/moon-icons-tw';
import FlightBookingPageWrapper from '../../components/FlightBookingPageWrapper';
import Footer from '../../components/Footer';
import useIsMobile from '../../hooks/useIsMobile';
import { IFlightBookingAboutUs } from './types';

import { ReactComponent as TrustedBusinessTravelPartnerSvg } from '../../static/icons/trusted-business-travel-partner.svg';
import { ReactComponent as StreamlinedCommunicationSvg } from '../../static/icons/streamlined-communication.svg';
import { ReactComponent as ComprehensiveServicesSvg } from '../../static/icons/comprehensive-services.svg';

import {
  solutionIconWrapperCN,
  solutionDescriptionCN,
  contactButtonCN,
  solutionBlockCN,
  solutionTitleCN,
  solutionIconCN,
  descriptionCN,
  solutionsCN,
  sectionsCN,
  sectionCN,
  bannerCN,
  borderCN,
  titleCN,
} from './styles';
import { pageContentWrapperCN } from '../../styles/wrappers';

const solutions = [
  {
    title: 'Trusted business travel partner',
    description: 'Flexible travel solutions for your business backed up by Yolo Group.',
    icon: <TrustedBusinessTravelPartnerSvg className={solutionIconCN} />
  },
  {
    title: 'Streamlined Communication',
    description: 'Enhance coordination between travelers, organizers, team managers, HR, and accounting departments.',
    icon: <StreamlinedCommunicationSvg className={solutionIconCN} />
  },
  {
    title: 'Comprehensive Services',
    description: 'From travel booking to expense reporting, TravelApp offers an all-inclusive suite of features for a smooth, hassle-free experience.',
    icon: <ComprehensiveServicesSvg className={solutionIconCN} />
  },
];

const FlightBookingAboutUs: React.FC<IFlightBookingAboutUs> = ({
  isContactFormOpen,
  setIsContactFormOpen,
}) => {
  const isMobile = useIsMobile();

  const renderAboutAsBanner = () => {
    return (
      <>
        <div className={bannerCN}>
          <h1 className={titleCN}>About us</h1>

          <div className={descriptionCN}>
            Established in 2019 as an answer to the growing travel demands within Yolo Group, Yolo Travel has evolved beyond the conventional corporate travel agency.
          </div>

          <div className={sectionsCN}>
            <div className={mergeClassnames(sectionCN, borderCN)}>
              Our journey began with the development of the <b>Slack Travel App</b> – an integral part of our internal management development program. This innovative app was designed to transform the way business travel is managed, making it as effortless as sending a <b>Slack message</b>.
            </div>
            <div className={sectionCN}>
              Fast forward to 2024, and we’re thrilled to unveil the Yolo Travel web app—an innovative, <b>Crypto-First</b> travel booking platform. Now, you can effortlessly book and oversee your business travels from any device, featuring the most seamless payment options, including <b>cryptocurrency</b> payments.
            </div>
          </div>

          <Button
            className={contactButtonCN}
            iconRight={<ArrowsTopRight />}
            size='xl'
            onClick={() => setIsContactFormOpen(true)}
          >
            Contact us to get on board
          </Button>
        </div>
      </>
    ); 
  };

  return (
    <FlightBookingPageWrapper
      passwordRequired={false}
      lightLogo={true}
      isMobile={isMobile}
      page={'flight-booking-about-us'}
      renderBanner={renderAboutAsBanner}
    >
      <div className={pageContentWrapperCN}>
        <div className={solutionsCN}>
          {solutions.map(solution => (
            <Fragment key={solution.title}>
              <div className={solutionBlockCN}>
                <div className={solutionIconWrapperCN}>{solution.icon}</div>
                <h2 className={solutionTitleCN}>{solution.title}</h2>
                <div className={solutionDescriptionCN}>{solution.description}</div>
              </div>
            </Fragment>
          ))}
        </div>
        <Footer
          isContactFormOpen={isContactFormOpen}
          setIsContactFormOpen={setIsContactFormOpen}
        />
      </div>
    </FlightBookingPageWrapper>
  );
};

export default FlightBookingAboutUs;
