import Skeleton from 'react-loading-skeleton';
import { itemCN } from './styles';
import { ITransactionDetails } from './types';

const TransactionDetails: React.FC<ITransactionDetails> = ({
  currency,
  items,
  isLoading,
}) => {
  return (        
    <div className='w-full'>
      <span className='text-moon-16 font-medium text-popo mb-[10px]'>
        <>
          {isLoading ? (
            <Skeleton style={{ height: '100%', width: '150px' }} inline />
          ): (
            <>Transaction details</>
          )}
        </>
      </span>
      {items.map(item => (
        <div className={itemCN(item.total)} key={item.title}>
          {isLoading ? (
            <Skeleton style={{ height: '100%', width: '150px' }} inline />
          ): (
            <span>{item.title}</span>
          )}
          {isLoading ? (
            <Skeleton style={{ height: '100%', width: '150px' }} inline />
          ): (
            <span>{item.amount} {currency}</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default TransactionDetails;
