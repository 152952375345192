import { useState } from 'react';
import { IFlightBookingTimeSelector } from './types';
import {
  itineraryPriceDifferenceCN,
  itineraryOptionWrapperCN,
  itineraryOptionTitleCN,
} from './styles';
import { formatItinerariesToOption, getFlightDifference, formatItineraries } from './utils';
import Modal from '../Modal';
import FlightBookingFilterOptions from '../FlightBookingFilterOptions';
import { IOption } from '../FlightBookingFilterOptions/types';
import { getFlightAirlineCompanyLogo } from '../FlightBookingGroup/utils';
import { formatFlightDate } from '../FlightItinerary/utils';

const FlightBookingTimeSelector: React.FC<IFlightBookingTimeSelector> = ({
  destination,
  origin,
  group,
  onChange,
  onCancel,
}) => {
  const [selectedOriginItinerary, setSelectedOriginItinerary] = useState<IOption>(
    formatItinerariesToOption(group.selectedOriginItinerary)
  );
  const [selectedReturnItinerary, setSelectedReturnItinerary] = useState<IOption>(
    formatItinerariesToOption(group.selectedReturnItinerary)
  );

  const formatLabel = (option: IOption, isReturn = false): string | JSX.Element => {
    const itineraries = isReturn ? group.returnItineraries : group.originItineraries;
    const itineraryData = itineraries.find(({ id }) => id === option.value);

    if (!itineraryData) {
      return option.label;
    }

    const { departure } = itineraryData.segments[0];
    const { arrival } = itineraryData.segments[itineraryData.segments.length - 1];
    const { departureAt, arrivalAtFull } = formatFlightDate(departure, arrival);
    const { url, alt } = getFlightAirlineCompanyLogo(itineraryData);
    const priceDifference = getFlightDifference(group, itineraryData, isReturn)
    const negative = priceDifference < 0;

    return (
      <div className='text-moon-14 flex items-center gap-[5px] max-tablet:text-moon-12 max-tablet:gap-[2.5px]'>
        <div className='w-[25px] h-[25px] mr-[5px] max-tablet:w-[17.5px] max-tablet:h-[17.5px] max-tablet:mr-[2.5px]'>
          <img className='max-w-full max-h-full' src={url} alt={alt} />
        </div>
        <span>
          {departure.iataCode} → {arrival.iataCode},
        </span>
        <span className='font-semibold'>
          {departureAt} - {arrivalAtFull}
        </span>
        {(+priceDifference >= 1 || +priceDifference <= -1) && (
          <div className={itineraryPriceDifferenceCN(negative)}>
            <span>{negative ? '-' : '+'}</span>
            <span>€</span>
            <span>{Math.abs(priceDifference)}</span>
          </div>
        )}
      </div>
    );
  };
 
  return (
    <Modal
      submitButtonLabel="Save"
      title="Select time"
      onSubmit={() => onChange(selectedOriginItinerary, selectedReturnItinerary)}
      onClose={onCancel}
    >
      {group?.selectedOriginItinerary && group?.originItineraries?.length > 1 && (
        <div className={itineraryOptionWrapperCN}>
          <div className={itineraryOptionTitleCN}>
            {`${origin?.city} → ${destination?.city} (Outbound)`}
          </div>
          <FlightBookingFilterOptions
            selectedOption={selectedOriginItinerary}
            options={formatItineraries(group.originItineraries)}
            isMulti={false}
            renderLabel={(option) => formatLabel(option, false)}
            onChange={(option) => setSelectedOriginItinerary(Array.isArray(option) ? option[0] : option)}
          />
        </div>
      )}
      
      {group?.selectedReturnItinerary && group?.returnItineraries?.length > 1 && (
        <div className={itineraryOptionWrapperCN}>
          <div className={itineraryOptionTitleCN}>
            {`${destination?.city} → ${origin?.city} (Return)`}
          </div>
          <FlightBookingFilterOptions
            selectedOption={selectedReturnItinerary}
            options={formatItineraries(group.returnItineraries)}
            isMulti={false}
            renderLabel={(option) => formatLabel(option, true)}
            onChange={(option) => setSelectedReturnItinerary(Array.isArray(option) ? option[0] : option)}
          />
        </div>
      )}
    </Modal>
  );
};

export default FlightBookingTimeSelector;
