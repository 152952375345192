import { mergeClassnames } from '@heathmont/moon-core-tw';

export const itemCN = (withShadow: boolean) => mergeClassnames([
  'rounded-moon-s-sm',
  !!withShadow && 'shadow-moon-lg',
]);

export const extraPaddingCN = mergeClassnames([
  'px-[40px]',
  'py-[20px]',
  'max-tablet:px-[16px]',
  'max-tablet:py-[16px]',
]);

export const contentCN = mergeClassnames([
  extraPaddingCN,
  'bg-goku',
  'border-dashed',
]);

export const titleCN = mergeClassnames([
  'text-moon-20',
  'max-tablet:text-moon-16',
  'font-normal'
]);

export const iconCN = mergeClassnames([
  'text-trunks',
  'text-moon-24',
  'transition',
  'duration-200',
  'moon-open:text-bulma',
]);
