import { KeyboardEvent, useEffect, useRef } from 'react';
import axios from 'axios';
import { Group, InsetInput } from '@heathmont/moon-core-tw';
import { IDestinationSearchSelectInput } from './types';
import useDebounce from '../../hooks/useDebounce';
import { handleFetchOptions } from './utils';
import { inputCN, labelCN } from './styles';

const DestinationSearchSelectInput: React.FC<IDestinationSearchSelectInput> = ({
  inputValue,
  lastSelect,
  isMobile,
  label,
  open,
  setIsOptionsLoading,
  setOptionsGroup,
  setInputValue,
}) => {
  const source = useRef(axios.CancelToken.source());
  const inputRef = useRef(null);
  const InputComponent = lastSelect ? Group.LastInsetInput : Group.FirstInsetInput;

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.select();
    } else if (inputRef.current) {
      inputRef.current.blur();
    }
  }, [open]);

  const debouncedRequest = useDebounce(() => {
    source?.current?.cancel && source.current.cancel();
  
    source.current = axios.CancelToken.source();

    return handleFetchOptions({
      search: inputValue,
      source: source.current,
      setOptionsGroup,
      setLoading: setIsOptionsLoading,
    });
  });

  const handleChangeInput = ({ target: { value } }) => {
    setInputValue(value);
    debouncedRequest();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const SPACE_CODE = 'Space';
    if (event.code === SPACE_CODE) {
      event.stopPropagation();
    };
  }

  return (
    <InputComponent
      placeholder={' '}
      className={inputCN({ open, lastSelect, isMobile })}
      ref={inputRef}
      value={inputValue}
      onKeyDown={handleKeyDown}
      onChange={handleChangeInput}
    >
      <InsetInput.Label className={labelCN({lastSelect, withValue: !!inputValue, isMobile, open })}>
        {label}
      </InsetInput.Label>
    </InputComponent>
  );
};

export default DestinationSearchSelectInput;
