import Skeleton from 'react-loading-skeleton';
import { IFlightOfferSegment } from './types';
import {
  segmentOverviewWrapperCN,
  segmentTimeLineLabelCN,
  segmentTimeAndPlaceCN,
  segmentStopLabelCN,
  segmentWrapperCN,
  segmentPlaceCN,
  flightNumberCN,
  segmentTimeCN,
  airlineLogoCN,
} from './styles';
import { getFlightNumber, getAirlineName } from './utils';
import { getCityByIataCode } from '../../utils/flight-offer';
import { getFlightAirlineCompanyLogo } from '../FlightBookingGroup/utils';
import { formatFlightDate, getFlightDurationText } from '../FlightItinerary/utils';
import { textEllipsisCN } from '../../styles/texts';

const FlightOfferSegment: React.FC<IFlightOfferSegment> = ({
  isLoading,
  isFirst,
  segment,
  stop,
}) => {
  const { departureAt, arrivalAt } = formatFlightDate(segment.departure, segment.arrival);
  const { url, alt } = getFlightAirlineCompanyLogo({ segments: [segment] });

  return (
    <div className={segmentWrapperCN(!isFirst)}>
      <div className='w-full flex justify-center items-center gap-[5px]'>
        <div className={airlineLogoCN}>
          {isLoading ? (
            <Skeleton circle style={{ height: '14px', width: '14px' }} inline />
          ) : (
            <img className='max-w-full max-h-full' src={url} alt={alt} />
          )}
        </div>
        <div className={flightNumberCN}>
          {isLoading ? (
            <Skeleton style={{ height: '100%', width: '80px' }} inline />
          ) : (
            getAirlineName(segment)
          )}
        </div>
        <div className={`${flightNumberCN} font-medium`}>
          {isLoading ? (
            <Skeleton style={{ height: '100%', width: '60px' }} inline />
          ) : (
            getFlightNumber(segment)
          )}
        </div>
      </div>
      <div className={segmentOverviewWrapperCN}>
        <div className={segmentTimeAndPlaceCN({ end: true })}>
          <span className={segmentTimeCN}>
            {isLoading ? (
              <Skeleton style={{ height: '100%', width: '60px' }} inline />
            ) : (
              departureAt
            )}
          </span>
          <span className={segmentPlaceCN}>
            {isLoading ? (
              <Skeleton style={{ height: '100%', width: '40px' }} inline />
            ) : (
              segment.departure.iataCode
            )}
          </span>
        </div>
        <div className='flex-[1] relative bg-trunks h-[2px]'>
          <div className={segmentTimeLineLabelCN}>
            {isLoading ? (
              <Skeleton style={{ height: '100%', width: '80px' }} inline />
            ) : (
              getFlightDurationText(segment.departure, segment.arrival)
            )}
          </div>
        </div>
        <div className={segmentTimeAndPlaceCN({ start: true })}>
          <span className={segmentTimeCN}>
            {isLoading ? (
              <Skeleton style={{ height: '100%', width: '60px' }} inline />
            ) : (
              arrivalAt
            )}
          </span>
          <span className={segmentPlaceCN}>
            {isLoading ? (
              <Skeleton style={{ height: '100%', width: '40px' }} inline />
            ) : (
              segment.arrival.iataCode
            )}
          </span>
        </div>
      </div>
      {!!stop && (
        <div className={segmentStopLabelCN}>
          <span className={textEllipsisCN}>
            {isLoading ? (
              <Skeleton style={{ height: '100%', width: '60px' }} inline />
            ) : (
              stop.duration
            )}
          </span>
          <span className='text-popo mx-[7.5px] my-[0]'>•</span>
          <span className={textEllipsisCN}>
            {isLoading ? (
              <Skeleton style={{ height: '100%', width: '180px' }} inline />
            ) : (
              <>
                  Change planes in {getCityByIataCode(stop.code)} ({stop.code})
              </>
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default FlightOfferSegment;
