import { Button, Modal as MoonModal, mergeClassnames } from '@heathmont/moon-core-tw';
import { IModal } from './types';
import {
  buttonsWrapperCN,
  dialogHeaderCN,
  resetButtonCN,
  dialogBodyCN,
} from './styles';
import { btnDialogCN, btnSecondaryCN } from '../../styles/buttons';

const Modal: React.FC<IModal> = ({
  submitButtonLabel = 'Save',
  cancelButtonLabel = 'Cancel',
  resetButtonLabel = 'Reset',
  passwordDialog = false,
  withHeader = true,
  withFooter = true,
  isLoading = false,
  children,
  title,
  onCancel,
  onSubmit,
  onClose = () => {},
  onReset,
}) => {
  return (
    <>
      <MoonModal className='z-[999999]' open onClose={onClose}>
        <MoonModal.Backdrop className='backdrop-filter backdrop-blur-[2px]' />
        <MoonModal.Panel className='max-w-xl'>
          {withHeader && (
            <div className={dialogHeaderCN}>
              <h4 className='font-semibold text-moon-20 text-popo p-0 m-0'>
                {title}
              </h4>
              {onReset && (
                <span className={resetButtonCN} onClick={onReset}>
                  {resetButtonLabel}
                </span>
              )}
            </div>
          )}
          
          <div className={dialogBodyCN(passwordDialog)}>
            {children}
          </div>

          {withFooter && (
            <div className={buttonsWrapperCN}>
              {(onCancel || onClose) && (
                <Button
                  className={mergeClassnames(btnSecondaryCN, btnDialogCN)}
                  fullWidth
                  size="xl"
                  onClick={(onCancel || onClose)}
                >
                  {cancelButtonLabel}
                </Button>
              )}
              {onSubmit && (
                <Button
                  className={btnDialogCN}
                  fullWidth
                  disabled={isLoading}
                  size="xl"
                  onClick={onSubmit}
                >
                  {submitButtonLabel}
                </Button>
              )}
            </div>
          )}
        </MoonModal.Panel>
      </MoonModal>
    </>
  );
};

export default Modal;
