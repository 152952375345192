import Yup from '../../utils/validation';

const CARD_HOLDER_MIN = 5;
const CARD_HOLDER_MAX_VALUE = 100;

const validateMessages = {
  cardHolder: {
    validName: 'Card holder can contain only Latin characters and hyphens',
    required: 'Card holder cannot be empty',
    min: `Card holder cannot be less than ${CARD_HOLDER_MIN} characters`,
    max: `Card holder cannot be longer than ${CARD_HOLDER_MAX_VALUE} characters`,
  },
};

export const creditCardSchema = Yup.object().shape({
  cardHolder: Yup
    .string()
    .required(validateMessages.cardHolder.required)
    .min(CARD_HOLDER_MIN, validateMessages.cardHolder.min)
    .max(CARD_HOLDER_MAX_VALUE, validateMessages.cardHolder.max)
    .withoutSpaces()
    .validName(validateMessages.cardHolder.validName)
    .isInLatinCharacters(),
});
