export enum COIN {
  BTC = 'BTC',
  ETH = 'ETH',
  TRX = 'TRX',
  DAI = 'DAI',
  DASH = 'DASH',
  BUSD = 'BUSD',
  BNB = 'BNB',
  USDC = 'USDC',
  BCH = 'BCH',
  LTC = 'LTC',
  'USDT.ERC20' = 'USDT.ERC20',
  'USDT.TRC20' = 'USDT.TRC20',
  'USDT.BEP20' = 'USDT.BEP20',
};

export enum COIN_LABEL {
  BTC = 'Bitcoin',
  ETH = 'Ethereum',
  TRX = 'Tron',
  DAI = 'Dai',
  DASH = 'Dash',
  BUSD = 'Binance USD',
  BNB = 'BNB Coin',
  USDC = 'USD Coin',
  BCH = 'Bitcoin Cash',
  LTC = 'Litecoin',
  'USDT.ERC20' = 'Tether USD (ERC20)',
  'USDT.TRC20' = 'Tether USD (TRC20)',
  'USDT.BEP20' = 'Tether USD (BEP20)',
};

export const coinsOptions = [
  {
    value: COIN.BTC,
    label: COIN_LABEL.BTC,
  },
  {
    value: COIN.ETH,
    label: COIN_LABEL.ETH,
  },
  {
    value: COIN.TRX,
    label: COIN_LABEL.TRX,
  },
  {
    value: COIN.DAI,
    label: COIN_LABEL.DAI,
  },
  {
    value: COIN.DASH,
    label: COIN_LABEL.DASH,
  },
  {
    value: COIN.BUSD,
    label: COIN_LABEL.BUSD,
  },
  {
    value: COIN.BNB,
    label: COIN_LABEL.BNB,
  },
  {
    value: COIN.USDC,
    label: COIN_LABEL.USDC,
  },
  {
    value: COIN.BCH,
    label: COIN_LABEL.BCH,
  },
  {
    value: COIN.LTC,
    label: COIN_LABEL.LTC,
  },
  {
    value: COIN['USDT.ERC20'],
    label: COIN_LABEL['USDT.ERC20'],
  },
  {
    value: COIN['USDT.TRC20'],
    label: COIN_LABEL['USDT.TRC20'],
  },
  {
    value: COIN['USDT.BEP20'],
    label: COIN_LABEL['USDT.BEP20'],
  },
];
