const FlightBookingHelloText = () => {
  return (
    <h1 className='w-full font-bold text-moon-48 !mb-[30px] gap-[0.25em] max-laptop:text-moon-32 tablet:flex max-tablet:hidden'>
      <span>Find affordable</span>
      <span className='bg-goku text-popo rounded-[12px] px-[16px] py-0'>airfare</span>
      <span className='opacity-25'>lodging</span>
      <span>deals.</span>
    </h1>
  );
};

export default FlightBookingHelloText;
