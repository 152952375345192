import { TravelNoSuitcase, TravelSuitcase, TimeClock, ControlsChevronRightSmall } from '@heathmont/moon-icons-tw';
import Skeleton from 'react-loading-skeleton';
import { Button, mergeClassnames } from '@heathmont/moon-core-tw';
import { IFlightBookingGroup } from './types';
import {  
  timeClockIconCN,
  priceWrapperCN,
  iconWrapperCN,
  priceCN,
  labelCN,
  iconCN,
} from './styles';
import {
  hasGroupOtherArrivalDepartureTimes,
  getFlightAirlineCompanyLogo,
  extractFlightStops,
} from './utils';
import Tooltip from '../Tooltip';
import FlightItinerary from '../FlightItinerary';
import { fontSpaceGroteskCN } from '../../styles/fonts';
import { btnOfferPriceCN } from '../../styles/buttons';

const FlightBookingGroup: React.FC<IFlightBookingGroup> = ({
  isLoading,
  isOneWay,
  group = {},
  handleOpenArrivalDepartureTimeChangeModal = () => {},
  handleSelectOffer = () => {},
}) => {
  const { selectedOriginItinerary, selectedReturnItinerary, selectedOffer, cheapest, fastest, optimal } = group; 

  return (
    <div className='w-full relative group'>
      {isLoading && (
        <>
          <FlightItinerary
            isLoading
            isReturn={false}
            isOneWay={isOneWay}
            onClick={handleSelectOffer}
          />
          {!isOneWay && (
            <FlightItinerary
              isLoading
              isReturn={true}
              isOneWay={isOneWay}
              onClick={handleSelectOffer}
            />
          )}
        </>
      )}

      {!isLoading && [selectedOriginItinerary, selectedReturnItinerary].map((itinerary, index) => {
        if (!itinerary) {
          return null;
        }

        const logo = getFlightAirlineCompanyLogo(itinerary);
        const { departure } = itinerary.segments[0];
        const { arrival } = itinerary.segments[itinerary.segments.length - 1];
        const stops = extractFlightStops(itinerary);

        return (
          <FlightItinerary
            departure={departure}
            isLoading={false}
            isReturn={index !== 0}
            segments={itinerary?.segments}
            isOneWay={isOneWay}
            arrival={arrival}
            stops={stops}
            logo={logo}
            key={itinerary.id}
            onClick={handleSelectOffer}
          />
        );
      })}

      <div className='absolute flex right-[16px] items-center top-[12px] max-tablet:right-[12px]'>
        {
          isLoading ? (
            <div className={iconWrapperCN}>
              <Skeleton style={{ height: '100%', width: '100%' }} inline />
            </div>
          ) : (
            <Tooltip
              position='left'
              label={group.baggage  ? 'Baggage: included' : 'Baggage: not-included'}
            >
              <div className={iconWrapperCN}>
                {group.baggage ? (
                  <TravelSuitcase className={iconCN} />
                ) : (
                  <TravelNoSuitcase className={iconCN} />
                )}
              </div>
            </Tooltip>
          )
        }
        
        {!isLoading && hasGroupOtherArrivalDepartureTimes(group) && (
          <Tooltip label={'There are other departure/arrival times'} position='left'>
            <div className={iconWrapperCN} onClick={() => handleOpenArrivalDepartureTimeChangeModal(group)}>
              <TimeClock className={timeClockIconCN} />
            </div>
          </Tooltip>
        )}
      </div>

      <div className={priceWrapperCN(isOneWay)}>
        {isLoading ? (
          <Skeleton style={{ height: '36px', width: '70px' }} inline />
        ) : (
          <>
            <h2 className={priceCN}>
              €{Math.round(selectedOffer?.price)}
            </h2>
            <Button
              className={mergeClassnames(fontSpaceGroteskCN, btnOfferPriceCN)}
              iconRight={<ControlsChevronRightSmall />}
              size='lg'
              onClick={handleSelectOffer}
            >
              €{Math.round(selectedOffer?.price)}
            </Button>
          </>
        )}
      </div>

      {!isLoading && (cheapest || fastest || optimal) && (
        <div className='absolute -top-[5px] flex gap-[5px] left-[35px] max-tablet:left-[15px]'>
          {optimal && <div className={labelCN({ optimal: true })}>Best</div>}
          {cheapest &&<div className={labelCN({ cheapest: true })}>Cheapest</div> }
          {fastest && <div className={labelCN({ fastest: true })}>Fastest</div>}
        </div>
      )}
    </div>
  );
};

export default FlightBookingGroup;
