import { connect } from 'react-redux';
import FlightBookingRequestChanges from './FlightBookingRequestChanges.ui';

const mapStateToProps = ({ eventLinkReducer }) => {
  return {
    hasAccess: !!(eventLinkReducer.confirmed && eventLinkReducer.eventLink),
  };
};

export default connect(mapStateToProps)(FlightBookingRequestChanges);
