import { useLocation, useNavigate } from 'react-router-dom';
import { IFlightBookingConfirmPriceError } from './types';
import FlightBookingInfoView from '../FlightBookingInfoView';

const FlightBookingConfirmPriceError: React.FC<IFlightBookingConfirmPriceError> = ({
  hasAdditionalServicesUnpriceableWarning,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const handleBackToPassengers = () => {
    navigate(`/flight-booking-passengers${search}`);
  };

  const handleBackToOffers = () => {
    navigate(`/flight-booking-list${search}`);
  };

  return (
    <FlightBookingInfoView
      submitButtonLabel={hasAdditionalServicesUnpriceableWarning ? (
        '← Back to passenger(s)'
      ) : (
        '← Back to flights'
      )}
      subTitle={hasAdditionalServicesUnpriceableWarning ? (
        'Please, select other extra baggage options for passenger(s)'
      ) : (
        'Please choose other flights'
      )}
      title={hasAdditionalServicesUnpriceableWarning ? (
        'There\'s an error during the pricing of the additional services'
      ) : (
        'The flights are no longer available'
      )}
      onClick={hasAdditionalServicesUnpriceableWarning ? handleBackToPassengers : handleBackToOffers}
    />
  );
};

export default FlightBookingConfirmPriceError;
