import { mergeClassnames } from '@heathmont/moon-core-tw';

export const fieldWrapperCN = mergeClassnames([
  'w-full',
  'flex',
  'flex-col',
]);

export const fieldLabelWrapperCN = mergeClassnames([
  'flex',
  'items-center',
  'justify-between',
  'mb-[10px]',
]);

export const fieldLabelCN = mergeClassnames([
  'text-moon-16',
  'text-trunks',
  'font-normal',
  'p-0',
  'm-0',
]);

export const clearButtonCN = mergeClassnames([
  'text-moon-12',
  'font-medium',
  'text-trunks',
  'cursor-pointer',
  'hover:text-[#5a5e64]',
]);

export const groupCN = mergeClassnames([
  'rounded-moon-i-md',
  '[&_input]:rounded-moon-i-md',
  '[&_select]:rounded-moon-i-md',
]);
