import { mergeClassnames } from '@heathmont/moon-core-tw';

export const paymentStatusLabelCN = (isPending: boolean) => mergeClassnames([
  'text-goku',
  'text-moon-12',
  'font-bold',
  'px-[8px]',
  'py-[2px]',
  'tracking-[1.5px]',
  'rounded-[4px]',
  isPending ? 'bg-krillin' : 'bg-roshi',
]);
