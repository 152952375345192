import externalAirlinesClass from 'airline-codes';

export const getFlightNumber = (segment) => {
  return segment?.carrierCode ? `${segment.carrierCode}-${segment.number}` : segment.number;
};

export const getAirlineName = (segment) => {
  const exceptions = {
    RK: 'Ryanair UK',
  };

  if (!segment.carrierCode) {
    return segment.carrierName || segment.carrierCode;
  }

  if (exceptions[segment.carrierCode]) {
    return exceptions[segment.carrierCode];
  }

  const airline = externalAirlinesClass.findWhere({ iata: segment.carrierCode });

  if (airline) {
    return airline.get('name');
  }

  return segment.carrierName || segment.carrierCode;
};
