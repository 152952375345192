export enum DEPARTURE_TIME {
  ALL = 'clear',
  EARLY_MORNING = '00:00:00-05:59:59',
  MORNING = '06:00:00-11:59:59',
  AFTERNOON = '12:00:00-17:59:59',
  EVENING = '18:00:00-23:59:59',
};

export enum DEPARTURE_TIME_SHORT_LABEL {
  ALL = 'All',
  EARLY_MORNING = '00:00 - 6:00',
  MORNING = '6:00 - 12:00',
  AFTERNOON = '12:00 - 18:00',
  EVENING = '18:00 - 00:00',
};

export enum DEPARTURE_TIME_LABEL {
  ALL = 'All',
  EARLY_MORNING = 'Early morning (00:00 - 6:00)',
  MORNING = 'Morning (6:00 - 12:00)',
  AFTERNOON = 'Afternoon (12:00 - 18:00)',
  EVENING = 'Evening (18:00 - 00:00)',
};

export const departureTimeOptions = [
  {
    shortLabel: DEPARTURE_TIME_SHORT_LABEL.ALL,
    value: DEPARTURE_TIME.ALL,
    label: DEPARTURE_TIME_LABEL.ALL,
  },
  {
    shortLabel: DEPARTURE_TIME_SHORT_LABEL.EARLY_MORNING,
    value: DEPARTURE_TIME.EARLY_MORNING,
    label: DEPARTURE_TIME_LABEL.EARLY_MORNING,
  },
  {
    shortLabel: DEPARTURE_TIME_SHORT_LABEL.MORNING,
    value: DEPARTURE_TIME.MORNING,
    label: DEPARTURE_TIME_LABEL.MORNING,
  },
  {
    shortLabel: DEPARTURE_TIME_SHORT_LABEL.AFTERNOON,
    value: DEPARTURE_TIME.AFTERNOON,
    label: DEPARTURE_TIME_LABEL.AFTERNOON,
  },
  {
    shortLabel: DEPARTURE_TIME_SHORT_LABEL.EVENING,
    value: DEPARTURE_TIME.EVENING,
    label: DEPARTURE_TIME_LABEL.EVENING,
  },
];
