import { saveAs } from 'file-saver';
import { getErrorStatusMessage } from '../../utils/common';
import request from '../../utils/request';
import { PAYMENT_STATUS } from '../../enums/PaymentStatus';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { isInvoicePayment, isSuccessPayment } from '../FlightBookingConfirmation/utils';

export const downloadAdditionalPaymentInvoice = async ({
  token,
  setIsInvoiceDownloading,
}: {
  token: string;
  setIsInvoiceDownloading: (isLoading: boolean) => void,
}) => {
  try {
    setIsInvoiceDownloading(true);

    const response = await request.post('white-label/payments/additional/invoice', {
      token,
    });

    if (response?.data?.invoice) {
      const ticketPdf = await fetch(response?.data?.invoice?.url);
      const blob = await ticketPdf.blob();
      saveAs(blob, `${response?.data?.invoice?.name}.pdf`)
    }

    setIsInvoiceDownloading(false);
  } catch (e) {
    setIsInvoiceDownloading(false);
    getErrorStatusMessage({
      status: e?.response?.status,
      message: e?.response?.data?.error || 'Something went wrong. Try again later',
    });
  }
};

export const fetchAdditionalPaymentDetails = async ({
  token,
  setIsPaymentLoading,
  setIsPaymentError,
  setPayment,
}: {
  token: string;
  setIsPaymentLoading: (isLoading: boolean) => void;
  setIsPaymentError: (error: boolean) => void;
  setPayment: (payment: any) => void;
}) => {
  setIsPaymentLoading(true);
  try {
    const { data } = await request.get('white-label/payments/additional/details', {
      params: {
        token,
      }
    });
    setPayment({ data: data?.payment });
    setIsPaymentLoading(false);
  } catch (err) {
    setIsPaymentLoading(false);
    setIsPaymentError(true);
  }
};

export const defineTitleAndSubTitle = ({
  paymentType,
  status,
}: {
  paymentType: PAYMENT_TYPE,
  status: PAYMENT_STATUS,
}): {
  title: string;
  subTitle: string;
} => {
  if (isSuccessPayment(status)) {
    return {
      title: 'Payment has already been made',
      subTitle: 'Travel manager will contact you as soon as possible',
    };
  }

  if (isInvoicePayment(paymentType)) {
    return {
      title: 'Please download and pay the invoice during 4 hours',
      subTitle: 'Travel manager will contact you after your payment as soon as possible',
    };
  }

  return {
    title: 'Payment has been processing',
    subTitle: 'Travel manager will contact you as soon as possible',
  };
};
