import { useState, useEffect } from 'react';
import { handleFetchOptions } from '../pages/FlightBookingList/utils';

const useDestinations = ({ searchParams, hasAccess }) => {
  const [origin, setOrigin] = useState<any | null>(null);
  const [originOptions, setOriginOptions] = useState([]);
  const [originOptionsLoading, setOriginOptionsLading] = useState<boolean>(false);
  const [destination, setDestination] = useState<any | null>(null);
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [destinationOptionsLoading, setDestinationOptionsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (searchParams.origin && hasAccess) {
      handleFetchOptions(searchParams.origin as string, setOrigin, setOriginOptions, setOriginOptionsLading);
    }
  }, [searchParams.origin, hasAccess]);

  useEffect(() => {
    if (searchParams.destination && hasAccess) {
      handleFetchOptions(searchParams.destination as string, setDestination, setDestinationOptions, setDestinationOptionsLoading);
    }
  }, [searchParams.destination, hasAccess]);

  return {
    isLoading: originOptionsLoading || destinationOptionsLoading,
    destinationOptions,
    originOptions,
    destination,
    origin,
  };
};

export default useDestinations;
