import Rating from '@mui/material/Rating';
import { ReactComponent as EmptyStarSvg } from '../.././static/icons/empty-star.svg';
import { ReactComponent as FilledStarSvg } from '../.././static/icons/filled-star.svg';
import { IStarRatingFormField } from './types';

const StarRatingFormField: React.FC<IStarRatingFormField> = ({
  readOnly = false,
  value,
  label,
  onChange,
}) => {
  const iconStyles = {
    marginRight: '16px',
  };

  return (
    <div className='border-[1px] border-solid border-beerus p-4 rounded-xl cursor-pointer flex flex-col gap-[12px]'>
      <label className='text-moon-16 text-trunks'>{label}</label>
      <Rating
        emptyIcon={<EmptyStarSvg style={iconStyles} />}
        precision={1}
        readOnly={!!readOnly}
        value={value}
        icon={<FilledStarSvg style={iconStyles} />}
        size={'large'}
        onChange={(event, value) => onChange(value)}
      />
    </div>
  );
};

export default StarRatingFormField;

