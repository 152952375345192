import { BAGS } from '../../enums/BagsOption';
import { CABIN } from '../../enums/CabinTypes';
import { DEPARTURE_TIME } from '../../enums/DepartureTimeOption';
import { DURATION } from '../../enums/DurationOption';
import { NUMBER_OF_STOPS } from '../../enums/NumberOfStopsOption';
import { SORT } from '../../enums/SortOption';
import {
  SET_IS_ARRIVAL_DEPARTURE_TIME_CHANGE_MODAL_OPEN,
  SET_EVENT_DATA_TO_SEARCH_PARAMS,
  SET_IS_ALL_FILTERS_MODAL_OPEN,
  SET_PAYMENT_PAYER_DETAILS,
  SET_SELECTED_ITINERARIES,
  TOGGLE_TRANSFER_REQUIRED,
  UPDATE_ADDITIONAL_BAGS,
  SET_PAYMENT_PAYER_TYPE,
  SELECT_PAYMENT_COIN,
  SELECT_PAYMENT_TYPE,
  SET_GROUPS_LOADING,
  SET_GROUPS_SUCCESS,
  SET_SEARCH_PARAMS,
  UPDATE_PASSENGERS,
  SET_GROUPS_ERROR,
  RESET_TO_INITIAL,
  RESET_AIRLINES,
  SELECT_OFFER,
  RESET_FILTER,
  SET_SORT_BY,
  SET_FILTERS,
  RESET_DATA,
  SET_PAGE,
} from './actionTypes';
import {
  RESET_EVENT_LINK,
} from '../eventLink/actionTypes'
import {
  extractDepartureDateFromEvent,
  extractReturnDateFromEvent,
  setSelectedItineraries,
  defineCheckedAirlines,
  defineSelectedOffer,
  definePriceRange,
  initPassengers,
  initPayment,
} from './utils';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { PAYER_TYPE } from '../../enums/PayerTypes';

export const initialFilters = {
  maxNumberOfConnections: NUMBER_OF_STOPS.ALL,
  departureTimeOrigin: DEPARTURE_TIME.ALL,
  arrivalTimeOrigin: DEPARTURE_TIME.ALL,
  departureTimeReturn: DEPARTURE_TIME.ALL, 
  arrivalTimeReturn: DEPARTURE_TIME.ALL,
  withBaggage: BAGS.ALL,
  maxDuration: DURATION.ALL,
  maxPrice: null,
};

export const initialSearch = {
  cabin: CABIN.ECONOMY,
  passengers: 1,
  destination: null,
  origin: null,
  departureDate: null,
  returnDate: null,
};

export const initialPaymentData = {
  paymentType: PAYMENT_TYPE.CRYPTO,
  payerType: PAYER_TYPE.INDIVIDUAL,
}

const initState = {
  isArrivalDepartureTimeChangeModalOpen: false,
  isAllFiltersModalOpen: false,
  transferRequired: false,
  additionalBags: [],
  initialLoading: false,
  transferNeeded: false,
  selectedOffers: [],
  amaClientRef: null, 
  passengers: initPassengers(initialSearch.passengers),
  payment: initPayment({
    payment: initialPaymentData,
    passengers: [],
  }),
  loading: true,
  airlines: [],
  filters: initialFilters,
  prices: definePriceRange(),
  search: initialSearch,
  offset: 10,
  sortBy: SORT.OPTIMAL_FIRST,
  offers: [],
  groups: [],
  limit: 10,
  error: null,
  total: 0,
  page: 1,
};

const flightBookings = (state = initState, action) => {
  switch (action.type) {
    case SELECT_PAYMENT_TYPE: {
      return {
        ...state,
        payment: initPayment({
          travelDocumentsTypes: action.payload.travelDocumentsTypes,
          passengers: state.passengers,
          payment: {
            ...state.payment,
            paymentType: action.payload.paymentType,
          },
        }),
      };
    }

    case SET_PAYMENT_PAYER_TYPE: {
      return {
        ...state,
        payment: initPayment({
          travelDocumentsTypes: action.payload.travelDocumentsTypes,
          shouldResetPayerData: true,
          passengers: state.passengers,
          payment: {
            ...state.payment,
            payerType: action.payload.payerType,
          },
        }),
      };
    }

    case SELECT_PAYMENT_COIN: {
      return {
        ...state,
        payment: initPayment({
          travelDocumentsTypes: action.payload.travelDocumentsTypes,
          passengers: state.passengers,
          payment: {
            ...state.payment,
            coin: action.payload.coin,
          },
        }),
      };
    }

    case SET_PAYMENT_PAYER_DETAILS: {
      return {
        ...state,
        payment: action.payload,
      };
    }

    case UPDATE_ADDITIONAL_BAGS: {
      return {
        ...state,
        additionalBags: action.payload.additionalBags,
      };
    }
  
    case UPDATE_PASSENGERS: {
      return {
        ...state,
        passengers: action.payload.passengers,
      };
    }
  
    case TOGGLE_TRANSFER_REQUIRED: {
      return {
        ...state,
        transferRequired: !state.transferRequired,
      };
    }
  
    case SET_IS_ARRIVAL_DEPARTURE_TIME_CHANGE_MODAL_OPEN: {
      return {
        ...state,
        isArrivalDepartureTimeChangeModalOpen: action.payload,
        isAllFiltersModalOpen: false,
      };
    }
  
    case SET_IS_ALL_FILTERS_MODAL_OPEN: {
      return {
        ...state,
        isArrivalDepartureTimeChangeModalOpen: false,
        isAllFiltersModalOpen: action.payload,
      };
    }

    case SET_SORT_BY: {
      return {
        ...state,
        sortBy: action.payload,
        page: initState.page,
      }
    }
  
    case RESET_EVENT_LINK: {
      return {
        ...state,
        isArrivalDepartureTimeChangeModalOpen: false,
        isAllFiltersModalOpen: false,
      };
    }
  
    case SELECT_OFFER: {
      const selectedIds = state.groups[action.payload].selectedOffer.id.split('/');
      return {
        ...state,
        selectedOffers: state.offers.filter(offer => {
          return selectedIds.includes(offer.id); 
        }),
      };
    }
  
    case RESET_DATA: {
      return {
        ...initState,
        additionalBags: state.additionalBags,
        selectedOffers: state.selectedOffers,
        amaClientRef: state.amaClientRef,
        passengers: state.passengers,
        airlines: state.airlines,
        filters: state.filters,
        prices: state.prices,
        sortBy: state.sortBy,
        search: state.search,
      };
    }

    case SET_SEARCH_PARAMS: {
      return {
        ...state,
        additionalBags: [],
        amaClientRef: initState.amaClientRef,
        passengers: initPassengers(action.payload.searchParams.passengers),
        search: {
          ...state.search,
          ...action.payload.searchParams,
        },
        airlines: action.payload.shouldResetAirlines
          ? initState.airlines
          : state.airlines,
        sortBy: action.payload.shouldResetSortBy 
          ? initState.sortBy
          : state.sortBy,
        filters: action.payload.shouldResetFilters
          ? initialFilters
          : ({
            ...state.filters,
            departureTimeReturn: action?.payload?.searchParams?.returnDate 
              ? state.filters.departureTimeReturn
              : initialFilters.departureTimeReturn,
            arrivalTimeReturn: action?.payload?.searchParams?.returnDate 
              ? state.filters.arrivalTimeReturn
              : initialFilters.arrivalTimeReturn,
          }),
        page: initState.page,
      };
    }
  
    case SET_FILTERS: {
      return {
        ...state,
        airlines: action.payload.airlines,
        filters: {
          ...state.filters,
          ...action.payload.filters,
        },
        page: initState.page,
      };
    }

    case RESET_AIRLINES: {
      return {
        ...state,
        airlines: state.airlines.map(airline => ({ ...airline, checked: true })),
        filters: { ...state.filters },
        page: initState.page,
      };
    }

    case RESET_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filter]: initialFilters[action.payload.filter],
        },
        page: initState.page,
      };
    }

    case SET_PAGE: {
      return {
        ...state,
        page: action.payload.page,
      };
    }

    case SET_GROUPS_LOADING: {
      const isFirstPage = state.page === initState.page;
      return {
        ...state,
        offers: isFirstPage ? [] : state.offers,
        groups: isFirstPage ? [] : state.groups,
        initialLoading: state.amaClientRef
          ? state.initialLoading
          : true,
        loading: state.amaClientRef
          ? true
          : state.loading,
        error: null,
      };
    }

    case SET_GROUPS_SUCCESS: {
      return {
        ...state,
        initialLoading: false,
        amaClientRef: action.payload.amaClientRef, 
        airlines: defineCheckedAirlines(state.airlines, action.payload.airlines),
        loading: false,
        prices: definePriceRange(action.payload.prices, state.filters.maxPrice),
        offers: action.payload.offers,
        groups: [
          ...state.groups,
          ...action.payload.groups.map(group => {
            return {
              ...group,
              ...defineSelectedOffer(group),
            }
          }),
        ],
        total: action.payload.total,
      };
    }

    case SET_GROUPS_ERROR: {
      return {
        ...state,
        initialLoading: false,
        loading: false,
        error: action.payload.error,
      };
    }

    case SET_SELECTED_ITINERARIES: {
      return {
        ...state,
        groups: setSelectedItineraries(state.groups, action.payload),
      }
    }

    case SET_EVENT_DATA_TO_SEARCH_PARAMS: {

      return {
        ...state,
        search: {
          ...state.search,
          destination: action?.payload?.region?.code
            ? action?.payload?.region?.code
            : initialSearch.destination,
          departureDate: action?.payload?.startAt
            ? extractDepartureDateFromEvent(action?.payload)
            : initialSearch.departureDate,
          returnDate: action?.payload?.endAt
            ? extractReturnDateFromEvent(action?.payload)
            : initialSearch.returnDate,
        }
      }
    }

    case RESET_TO_INITIAL: {
      return initState;
    }

    case RESET_EVENT_LINK: {
      return {
        ...state,
        isArrivalDepartureTimeChangeModalOpen: false,
        isAllFiltersModalOpen: false,
      };
    }

    default:
      return state;
  }
};

export default flightBookings;
