export enum PAYER_DOCUMENT_TYPES {
  ID_CARD = 'id_card',
  PASSPORT = 'passport',
};

export enum PAYER_DOCUMENT_TYPE_LABELS {
  ID_CARD = 'ID card',
  PASSPORT = 'Passport',
}

export const payerDocumentTypeOptions = [
  {
    value: PAYER_DOCUMENT_TYPES.ID_CARD,
    label: PAYER_DOCUMENT_TYPE_LABELS.ID_CARD,
  },
  {
    value: PAYER_DOCUMENT_TYPES.PASSPORT,
    label: PAYER_DOCUMENT_TYPE_LABELS.PASSPORT,
  },
];
