import { mergeClassnames } from '@heathmont/moon-core-tw';

export const wrapperCN = mergeClassnames([
  'mt-[60px]',
  'pl-[150px]',
  'pr-[150px]',
  'py-[60px]',
  'w-[892px]',
  'flex',
  'flex-col',
  'justify-center',
  'items-center',
  'bg-goku',
  'rounded-[12px]',
  'ml-[calc(-0.5*892px+50%)]',
  'max-laptop:w-[720px]',
  'max-laptop:ml-[calc(-0.5*720px+50%)]',
  'max-laptop:pl-[90px]',
  'max-laptop:pr-[90px]',
  'max-laptop:py-[60px]',
  'max-tablet:ml-0',
  'max-tablet:w-full',
  'max-tablet:max-w-full',
  'max-tablet:mt-[20px]',
  'max-tablet:p-[20px]',
  'max-tablet:bg-transparent',
]);

export const titleCN = mergeClassnames([
  'text-moon-24',
  'font-medium',
  'text-center',
  'text-bulma',
  'max-tablet:text-moon-20'
]);

export const subTitleCN = mergeClassnames([
  'text-moon-18',
  'font-normal',
  'text-center',
  'text-trunks',
  'mt-[10px]',
  'max-tablet:text-moon-16'
]);

export const iconCN = mergeClassnames([
  'w-[72px]',
  'h-[72px]',
  'mb-[20px]',
  'max-tablet:w-[32px]',
  'max-tablet:h-[32px]',
]);

export const buttonsWrapperCN = mergeClassnames([
  'w-full',
  'flex',
  'gap-[10px]',
  'mt-[40px]',
  'max-tablet:mt-[30px]',
  'max-tablet:flex-col',
]);
