import { ErrorBoundary as ErrorBoundaryComponent } from 'react-error-boundary';
import { NotificationsAlert } from '@heathmont/moon-icons-tw';
import { Alert } from "@heathmont/moon-core-tw";
import { Helmet } from 'react-helmet';

export const ErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundaryComponent
      fallbackRender={({ error, resetErrorBoundary }) => (
        <>
          <Helmet>
            <title>OOPS</title>
          </Helmet>
          <div className='p-24' role="alert">
            <Alert className="bg-chichi-10">
              <Alert.Message className='text-chichi text-moon-24'>
                <NotificationsAlert className="text-moon-32" />
                {error.message}
              </Alert.Message>
              <pre>
                {error.stack}
              </pre>
              <Alert.Close onClick={resetErrorBoundary} />
            </Alert>
          </div>
        </>
      )}
    >
      {children}
    </ErrorBoundaryComponent>
  );
};

