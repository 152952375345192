import { Fragment, useMemo } from 'react';
import { IFlightBookingOffer } from './types';
import {
  detailsReferenceCN,
  detailsTitleCN,
  detailsValueCN,
  detailsItemCN,
  referenceCN,
  detailsCN,
} from './styles';
import { getFlightReference } from './utils';
import { extractFlightStops } from '../FlightBookingGroup/utils';
import { CABIN_LABEL } from '../../enums/CabinTypes';
import FlightBookingOfferAdditionalDetails from '../FlightBookingOfferAdditionalDetails';
import FlightBookingFareRulesDialog from '../FlightBookingFareRulesDialog';
import FlightBookingSummary from '../FlightBookingSummary';
import FlightOfferSegment from '../FlightOfferSegment';
import {
  getBaggageDescription,
  hasOvernightLayover,
  isBaggageIncluded,
  getCityByIataCode,
} from '../../utils/flight-offer';
import {
  extractFlightOfferDetailedFareRules,
  extractFareDetails,
} from '../../utils/fare-rules';
import { getFlightDurationText } from '../FlightItinerary/utils';

const FlightBookingOffer: React.FC<IFlightBookingOffer> = ({
  includedDetailedFareRules,
  isMoreFareRulesModalOpen,
  associatedRecords = [],
  fareRulesOfferId,
  travelers = [],
  isLoading,
  isTicket,
  offer,
  setIsMoreFareRulesModalOpen,
}) => {
  const offerFareDetails = useMemo(() => {
    return extractFareDetails({
      travelerId: travelers[0].id,
      segmentId: offer.itineraries[0].segments[0].id,
      offer,
    });
  }, []);
  const detailedOfferFareRules = useMemo(() => {
    return extractFlightOfferDetailedFareRules(offer, includedDetailedFareRules);
  }, [includedDetailedFareRules]);

  return (
    <div className='w-full flex flex-col gap-[18px]'>
      {offer.itineraries.map((itinerary, itineraryIndex) => {
        const departure = itinerary.segments[0].departure;
        const arrival = itinerary.segments[itinerary.segments.length - 1].arrival;
        const stops = extractFlightStops(itinerary);
      
        return (
          <div className='w-full' key={itineraryIndex}>
            <FlightBookingSummary
              isBookingOverview
              overnightLayover={hasOvernightLayover(itinerary)}
              departureDate={departure.at}
              destination={getCityByIataCode(arrival.iataCode)}
              isLoading={isLoading}
              isTicket={isTicket}
              duration={getFlightDurationText(departure, arrival)}
              origin={getCityByIataCode(departure.iataCode)}
              stops={stops}
            />
            <div className='w-full'>
              {itinerary.segments.map((segment, segmentIndex) => (
                <FlightOfferSegment
                  isLoading={isLoading}
                  isFirst={segmentIndex === 0}
                  segment={segment}
                  stop={itinerary.segments[segmentIndex + 1] ? stops[segmentIndex] : null}
                  key={segmentIndex}
                />
              ))}
            </div>

            {isTicket && (
              <div className={detailsCN}>
                {travelers.map((traveler, index) => {
                  const itineraryFareDetails = extractFareDetails({
                    travelerId: traveler.id,
                    segmentId: itinerary.segments[0].id,
                    offer,
                  });
              
                  return (
                    <Fragment key={traveler.id}>
                      <div className={detailsItemCN({ start: true })}>
                        <div className={detailsTitleCN}>
                          Passenger {index + 1}
                        </div>
                        <div className={detailsValueCN} key={traveler.id}>
                            {`${traveler.firstName} ${traveler.lastName}`}
                        </div>
                      </div>
                      <div className={detailsItemCN({ center: true })}>
                        <div className={detailsTitleCN}>
                          Class
                        </div>
                        <div className={detailsValueCN}>
                          {CABIN_LABEL[offerFareDetails.cabin]}
                        </div>
                      </div>
                      <div className={detailsItemCN({ end: true })}>
                        <div className={detailsTitleCN}>
                          Baggage
                        </div>
                        {(isBaggageIncluded(offerFareDetails?.includedCheckedBags) || !isBaggageIncluded(itineraryFareDetails?.additionalServices?.chargeableCheckedBags)) && (
                          <div className={detailsValueCN}>
                            {getBaggageDescription({
                              detailed: false,
                              bags: offerFareDetails?.includedCheckedBags,
                            })}
                          </div>
                        )}
                        {isBaggageIncluded(itineraryFareDetails?.additionalServices?.chargeableCheckedBags) && (
                          <div className={detailsValueCN}>
                            {getBaggageDescription({
                              detailed: false,
                              label: 'extra',
                              bags: itineraryFareDetails?.additionalServices?.chargeableCheckedBags,
                            })}
                          </div>
                        )}
                      </div>
                    </Fragment>
                  );
                })}
                <div className={detailsReferenceCN}>
                  <div className={referenceCN}>
                    Reference
                  </div>
                  <div className={`${referenceCN} font-bold`}>
                    {getFlightReference(associatedRecords)}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}

      {!isTicket && (
        <FlightBookingOfferAdditionalDetails
          detailedOfferFareRules={detailedOfferFareRules}
          offerFareDetails={offerFareDetails}
          isLoading={isLoading}
          offer={offer}
          setIsMoreFareRulesModalOpen={setIsMoreFareRulesModalOpen}
        />
      )}

      {isMoreFareRulesModalOpen && fareRulesOfferId === offer?.id && (
        <FlightBookingFareRulesDialog
          onClose={() => setIsMoreFareRulesModalOpen(false)}
          detailedOfferFareRules={detailedOfferFareRules}
        />
      )}
    </div>
  );
};

export default FlightBookingOffer;
