import request from '../../utils/request';
import { Passenger } from '../../enums/Passenger';
import { Payment } from '../../enums/Payment';
import { getErrorMessages } from '../../utils/errors';
import { setAdditionalService } from '../../utils/baggage';
import { CarrierType } from '../../enums/CarrierType';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';

export interface BookingParams {
  transferRequired: boolean;
  includedBags: any;
  amaClientRef: string;
  travelers: Passenger[];
  fareRules: any[];
  payment: Payment;
  offers: any[];
};

export const extractErrorFromResponse = (err) => {
  const errors = err?.response?.data?.error;

  if (err?.response?.status === 422 && errors) {
    const errorMsgs = getErrorMessages(errors);
    return errorMsgs.join(' ');
  }

  if (Array.isArray(errors)) {
    return errors.join(' ');
  }

  if (typeof errors === 'string') {
    return errors;
  }

  return 'Something unpredictable happened. Please try again later or contact the travel manager.';
};

export const confirmOffersPrice = async ({
  additionalBags,
  amaClientRef,
  offers,
}) => {
  try {
    const { data } = await request.post('white-label/flights/pricing', {
      amaClientRef,
      includeBags: false,
      offers: setAdditionalService(offers, additionalBags),
    });

    if (data?.success && data?.data?.length) {
      return data?.data;
    }

    return [];
  } catch (err) {
    return [];
  }
};

export const bookFlight = async (bookingParams: BookingParams, isLowCostBooking: boolean) => {
  if (isLowCostBooking) {
    return await request.post('white-label/flights/pre-payment', bookingParams);
  }

  return await request.post('white-label/flights/book', bookingParams);
};

export const isBookingHasLowCostOffers = (offers): boolean => {
  return offers.some(offer => offer.source === CarrierType.LOW_COST);
};

export const isPaymentTypeSupportLowCostBookings = (paymentType: PAYMENT_TYPE): boolean => {
  const PAYMENT_TYPES_FOR_LOW_COST_BOOKINGS = [
    PAYMENT_TYPE.CRYPTO,
    PAYMENT_TYPE.CARD,
  ];
  return PAYMENT_TYPES_FOR_LOW_COST_BOOKINGS.includes(paymentType);
};
