import { mergeClassnames } from '@heathmont/moon-core-tw';
import { btnReset } from '../../styles/buttons';

export const dialogBodyCN = (passwordDialog: boolean) => mergeClassnames([
  'flex',
  'items-center',
  'justify-start',
  'flex-col',
  'overflow-y-auto',
  'p-[32px]',
  'gap-[20px]',
  'max-mobile:px-[16px]',
  'max-mobile:py-[12px]',
  passwordDialog 
    ? 'max-h-[520px] max-laptop:max-h-[calc(100dvh-175px)]'
    : 'max-h-[420px] max-laptop:max-h-[calc(100dvh-200px)]'
]);

export const dialogHeaderCN = mergeClassnames([
  'px-[32px]',
  'py-[24px]',
  'flex',
  'items-center',
  'justify-between',
  'border-b',
  'border-dashed',
  'border-beerus',
  'max-mobile:px-[16px]',
  'max-mobile:py-[12px]',
]);

export const resetButtonCN = mergeClassnames([
  'font-medium',
  'text-moon-12',
  'text-trunks',
  'p-0',
  'm-0',
  btnReset,
]);

export const buttonsWrapperCN = mergeClassnames([
  'bg-goku',
  'flex',
  'justify-center',
  'items-center',
  'w-full',
  'gap-[10px]',
  'pt-[0]',
  'px-[32px]',
  'pb-[32px]',
  'max-tablet:pt-[0]',
  'max-tablet:px-[16px]',
  'max-tablet:pb-[16px]',
  'rounded-bl-[12px]',
  'rounded-br-[12px]',
]);
