import Yup from '../../utils/validation';

const validateMessages = {
  code: {
    required: 'Password cannot be empty',
    min: 'Password cannot be less than 3 characters',
    max: 'Password cannot be longer than 255 characters',
  },
};

export const schema = Yup.object().shape({
  code: Yup
    .string()
    .required(validateMessages.code.required)
    .min(3, validateMessages.code.min)
    .max(255, validateMessages.code.max)
    .withoutSpaces(),
});
