import { browserName } from 'react-device-detect';

export const detectScrollBarWidth = () => {
  let scrollbarWidth = 0;
 
  if (browserName === 'Safari') {
    const scrollDiv = document.createElement('div');
    scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';
    document.body.appendChild(scrollDiv);
    scrollbarWidth = -(scrollDiv.offsetWidth - scrollDiv.clientWidth) / 2;
    document.body.removeChild(scrollDiv);
  };

  document.body.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
};