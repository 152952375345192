import { bagsOptions } from '../../enums/BagsOption';
import { numberOfStopsOptions } from '../../enums/NumberOfStopsOption';
import { durationOptions } from '../../enums/DurationOption';
import { departureTimeOptions } from '../../enums/DepartureTimeOption';

export const prepareFiltersChipsData = ({
  destination,
  airlines,
  filters,
  isRound,
  origin,
}) => {
  const chips: any[] = [];

  if ((filters.maxNumberOfConnections || filters.maxNumberOfConnections === 0) && filters.maxNumberOfConnections !== 'clear') {
    chips.push({
      isClearable: true,
      label: numberOfStopsOptions.find(({ value }) => value === filters.maxNumberOfConnections).label,
      field: 'maxNumberOfConnections',
    });
  }

  if (filters.maxPrice) {
    chips.push({
      isClearable: true,
      label: `Less than €${filters.maxPrice}`,
      field: 'maxPrice',
    });
  }

  if (filters.withBaggage !== 'clear') {
    chips.push({
      isClearable: true,
      label: bagsOptions.find(({ value }) => value === filters.withBaggage).label,
      field: 'withBaggage',
    });
  }

  if (filters.maxDuration && filters.maxDuration !== 'clear') {
    chips.push({
      isClearable: true,
      label: durationOptions.find(({ value }) => value === filters.maxDuration).label,
      field: 'maxDuration',
    });
  }

  if (origin && filters.departureTimeOrigin && filters.departureTimeOrigin !== 'clear') {
    const label = departureTimeOptions.find(({ value }) => value === filters.departureTimeOrigin).shortLabel;
    chips.push({
      isClearable: true,
      labelValue: label,
      label: `Dep from ${origin.code}`,
      field: 'departureTimeOrigin',
    });
  }

  if (destination && filters.arrivalTimeOrigin && filters.arrivalTimeOrigin !== 'clear') {
    const label = departureTimeOptions.find(({ value }) => value === filters.arrivalTimeOrigin).shortLabel;
    chips.push({
      isClearable: true,
      labelValue: label,
      label: `Arr to ${destination.code}`,
      field: 'arrivalTimeOrigin',
    });
  }

  if (destination && isRound && filters.departureTimeReturn && filters.departureTimeReturn !== 'clear') {
    const label = departureTimeOptions.find(({ value }) => value === filters.departureTimeReturn).shortLabel;
    chips.push({
      isClearable: true,
      labelValue: label,
      label: `Dep from ${destination.code}`,
      field: 'departureTimeReturn',
    });
  }

  if (origin && filters.arrivalTimeReturn && filters.arrivalTimeReturn !== 'clear') {
    const label = departureTimeOptions.find(({ value }) => value === filters.arrivalTimeReturn).shortLabel;
    chips.push({
      isClearable: true,
      labelValue: label,
      label: `Arr to ${origin.code}`,
      field: 'arrivalTimeReturn',
    });
  }

  const checkedAirlines = airlines.filter(airline => airline.checked);

  if (checkedAirlines.length && airlines.length !== checkedAirlines.length) {
    chips.push({
      isAirlineFilter: true,
      isClearable: true,
      number: checkedAirlines.length,
      label: 'Airlines',
      field: 'airlines',
    });
  }
  
  return chips;
};