import { ReactComponent as CurrencyUsdSvg } from '../static/icons/currency-usd.svg';

export enum FARE_RULE_CATEGORY {
  REFUND = 'REFUND',
  EXCHANGE = 'EXCHANGE',
  REVALIDATION = 'REVALIDATION',
  REISSUE = 'REISSUE',
  REBOOK = 'REBOOK',
  CANCELLATION = 'CANCELLATION',
};

export enum FARE_RULE_CATEGORY_LABEL {
  REFUND = 'Refundable',
  EXCHANGE = 'Exchangeable',
  REVALIDATION = 'Revalidation',
  REISSUE = 'Reissue',
  REBOOK = 'Rebook',
  CANCELLATION = 'Cancellable',
}

export const fareRuleCategoryOptions = [
  {
    value: FARE_RULE_CATEGORY.REFUND,
    label: FARE_RULE_CATEGORY_LABEL.REFUND,
    required: true,
    icon: CurrencyUsdSvg,
  },
  {
    value: FARE_RULE_CATEGORY.EXCHANGE,
    label: FARE_RULE_CATEGORY_LABEL.EXCHANGE,
    required: true,
  },
];