import { DESTINATION_GROUP_TYPES, DestinationsGroup } from '../enums/Destination';
import { REGION_TYPES } from '../enums/RegionTypes';

export const formatDestinations = ({
  destinations,
  popular,
}: {
  destinations: any[],
  popular: boolean,
}): DestinationsGroup => ({
  label: popular 
    ? 'POPULAR CITIES'
    : 'SEARCH RESULTS',
  type: popular 
    ? DESTINATION_GROUP_TYPES.POPULAR_CITIES
    : DESTINATION_GROUP_TYPES.SEARCH_RESULTS,
  options: destinations.map((destination) => ({
    subLocation: !!destination.subLocation,
    popular: popular,
    country: destination.country,
    image: popular
      ? require(`../static/destinations/${destination.code}.png`)
      : null,
    label: destination.subLocation
      ? (destination.name || destination.city)
      : `${destination.name || destination.city}, ${destination.country}`,
    value: destination.code,
    code: destination.code,
    city: destination.city,
    type: destination.type || REGION_TYPES.CITY,
    name: destination.name || destination.city,
  })),
});