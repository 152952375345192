import moment from 'moment';
import { FLIGHT_BOOKING_STEP } from '../enums/FlightBookingStep';
import { PAYMENT_TYPE } from '../enums/PaymentTypes';
import request from '../utils/request';
import { extractErrorFromResponse } from '../pages/FlightBookingPayment/utils';
import { Payment } from '../enums/Payment';
import { PAYMENT_STATUS } from '../enums/PaymentStatus';
import { CryptoTransactionStatus } from '../enums/CryptoTransaction';
import { CreditCardOrder, CreditCardOrderStatus } from '../enums/CreditCardOrder';

export const handleRedirectToCheckoutPage = async ({
  amaClientRef,
  paymentType,
  navigate,
}: {
  amaClientRef: string;
  paymentType: PAYMENT_TYPE;
  navigate: (url: string, params?: any) => void;
}) => {
  let url;

  if (paymentType === PAYMENT_TYPE.CRYPTO) {
    url = FLIGHT_BOOKING_STEP.CRYPTO_PAYMENT;
  } else if (paymentType === PAYMENT_TYPE.CARD) {
    url = FLIGHT_BOOKING_STEP.CARD_PAYMENT;
  } else {
    url = FLIGHT_BOOKING_STEP.SUCCESS;
  }

  navigate(`/${url}/${amaClientRef}`, { state: { resetToInitial: true } });
};

export const handleSuccessfulPayment = async ({
  isLowCostBooking,
  transactionKey,
  amaClientRef,
  initial,
  trips,
  setLowCostBookingLoading,
  redirectToBookingSuccess,
  setLowCostBookingError,
}: {
  isLowCostBooking: boolean;
  transactionKey: string;
  amaClientRef: string;
  initial: boolean,
  trips: any[],
  setLowCostBookingLoading: (loading: boolean) => void;
  redirectToBookingSuccess: () => void;
  setLowCostBookingError: (error: string | null) => void;
}) => {
  try {
    if (trips?.length) {
      return redirectToBookingSuccess();
    }
  
    if (isLowCostBooking && !trips?.length && !initial) {
      setLowCostBookingLoading(true);
      setLowCostBookingError(null);
    
      const { data } = await request.post(`white-label/flights/book/${amaClientRef}`, { transactionKey });

      setLowCostBookingLoading(false);

      if (data?.success) {
        return redirectToBookingSuccess();
      } 
      
      return setLowCostBookingError(extractErrorFromResponse(data?.error));
    }

    if (!trips?.length && initial) {
      return setLowCostBookingError(`We received your payment. But the status of the booking is unknown. Contact ${process.env.REACT_APP_CONTACT_EMAIL} for more information`);
    }
  } catch (err) {
    setLowCostBookingError(extractErrorFromResponse(err));
    setLowCostBookingLoading(false);
  }
};

export const isCryptoProcessingStatus = (transactionStatus) => {
  return [
    CryptoTransactionStatus.FULL,
    CryptoTransactionStatus.GREATER_AMOUNT,
    CryptoTransactionStatus.PROCESSING,
  ].includes(transactionStatus);
};

export const isUserMakeDeposit = (payments: Payment[]) => {
  return payments.some(payment => (
    payment?.status === PAYMENT_STATUS.SUCCESS || (
      payment.paymentType === PAYMENT_TYPE.CRYPTO
      && isCryptoProcessingStatus(payment.externalStatus)
  )));
};

export const getDurationInSeconds = (date) => {
  const now = moment();
  return moment.duration(now.diff(moment(date))).asSeconds();
}; 

export const isPaymentStuck = (order: CreditCardOrder): boolean => {
  const PAYMENT_TIMEOUT = 30;
  return order?.state === CreditCardOrderStatus.PROCESSING
    && order?.payments?.length > 0
    && !!order?.payments[order.payments.length - 1]?.updated_at
    && getDurationInSeconds(order?.payments[order.payments.length - 1]?.updated_at) > PAYMENT_TIMEOUT;
};

export const isCryptoPaymentReceived = (status: CryptoTransactionStatus): boolean => {
  const cryptoPaymentReceivedStatuses = [
    CryptoTransactionStatus.FULL,
    CryptoTransactionStatus.GREATER_AMOUNT,
    CryptoTransactionStatus.PROCESSING,
    CryptoTransactionStatus.SENT_OUT,
    CryptoTransactionStatus.COMPLETED,
    CryptoTransactionStatus.ARCHIVED,
  ];
  return cryptoPaymentReceivedStatuses.includes(status);
};

export const isWaitingForCryptoPaymentStatus = (status: CryptoTransactionStatus): boolean => {
  const waitingForCryptoPaymentStatuses = [
    CryptoTransactionStatus.NEW,
    CryptoTransactionStatus.UNCONFIRMED_TRANSACTION,
    CryptoTransactionStatus.INCOMPLETE,
  ];
  return waitingForCryptoPaymentStatuses.includes(status); 
};
