import { getFlightDuration } from '../../utils/common';
import { Logo } from '../../enums/Logo';
import { Stop } from '../../enums/Stop';

export const getAirlineLogoUrl = (carrierCode, carrierName) => {
  const filename = `${carrierCode}_${carrierName}.PNG`.replace(/\//g, '.');
  return encodeURI(`${process.env.REACT_APP_IMAGES_URL}/${filename}`);
};

export const getFlightAirlineCompanyLogo = (itinerary): Logo => {
  const [ { carrierCode, carrierName } ] = itinerary.segments;

  return {
    url: getAirlineLogoUrl(carrierCode, carrierName),
    alt: `${carrierCode}_${carrierName}`,
  };
};

export const extractFlightStops = (itinerary): Stop[] => {
  return itinerary.segments.length > 1
    ? itinerary.segments
      .map((s, index) => ({
        duration: index + 1 !== itinerary.segments.length 
          ? getFlightDuration(itinerary.segments[index + 1].departure.at, s.arrival.at)
          : null,
        code: s.arrival.iataCode,
      }))
      .filter((s, index) => index + 1 !== itinerary.segments.length)
    : [];
};

export const hasGroupOtherArrivalDepartureTimes = (group) => {
  return group?.originItineraries?.length > 1
    || group?.returnItineraries?.length > 1;
};