import { useState } from 'react';
import { Checkbox, mergeClassnames } from '@heathmont/moon-core-tw';
import { IFlightBookingOverview } from './types';
import { Link as RouterLink } from 'react-router-dom';
import FlightBookingTermsAndConditionsDialog from '../../components/FlightBookingTermsAndConditionsDialog';
import FlightBookingPageWrapper from '../../components/FlightBookingPageWrapper';
import FlightBookingBackAndNext from '../../components/FlightBookingBackAndNext';
import FlightBookingOffer from '../../components/FlightBookingOffer';
import { useFlightOffersPriceConfirmation } from '../../hooks/useFlightOffersPriceConfirmation';
import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';
import FlightBookingStepsWrapper from '../../components/FlightBookingStepsWrapper';
import useIsMobile from '../../hooks/useIsMobile';
import { checkboxLabelCN } from '../../styles/texts';
import { btnLink } from '../../styles/buttons';

const FlightBookingOverview: React.FC<IFlightBookingOverview> = ({
  isMoreFareRulesModalOpen,
  isTermsAndConditionsOpen,
  fareRulesOfferId,
  selectedOffers = [],
  amaClientRef,
  travelers,
  hasAccess,
  setIsTermsAndConditionsOpen,
  setIsMoreFareRulesModalOpen,
}) => {
  const isMobile = useIsMobile();
  const {
    includedDetailedFareRules,
    isPriceConfirmationError,
    isSignificantPriceChange,
    isLoading,
    price,
  } = useFlightOffersPriceConfirmation({
    includeDetailedFareRules: true,
    amaClientRef,
    hasAccess,
    offers: selectedOffers,
  });
  const [agreeWithTermsAndConditions, setAgreeWithTermsAndConditions] = useState<boolean>(false);

  const handleAgreeTermsAndConditions = () => {
    setIsTermsAndConditionsOpen(false);
    setAgreeWithTermsAndConditions(true);
  };

  const handleOpenTermsAndConditions = (e) => {
    e.preventDefault();
    setIsTermsAndConditionsOpen(true)
  };

  const renderLinkToTermsAndConditions = () => {
    const title = 'terms and conditions';

    return (
      <>
        <a className={mergeClassnames(btnLink, 'laptop:hidden')} onClick={handleOpenTermsAndConditions}>
          {title}
        </a>
        <RouterLink
          className={mergeClassnames(btnLink, 'max-tablet:hidden')}
          target={'_blank'}
          to={'/flight-booking-terms-and-conditions'}
        >
          {title}
        </RouterLink>
      </>
    );
  }

  return (
    <FlightBookingPageWrapper isMobile={isMobile} page={'flight-booking-overview'}>
      <FlightBookingStepsWrapper
        isPriceConfirmationError={isPriceConfirmationError}
        isSignificantPriceChange={isSignificantPriceChange}
        currentStep={FLIGHT_BOOKING_STEP.PRICE_CONFIRMATION}
        isLoading={isLoading}
        renderContinueAndBackButton={() => (
          <FlightBookingBackAndNext
            isLoading={isLoading}
            disabled={isPriceConfirmationError || !agreeWithTermsAndConditions}
            price={price}
            step={FLIGHT_BOOKING_STEP.PRICE_CONFIRMATION}
          />
        )}
      >
        <div className='w-full flex flex-col gap-[18px]'>
          {selectedOffers.map((offer) => (
            <FlightBookingOffer
              includedDetailedFareRules={includedDetailedFareRules}
              isMoreFareRulesModalOpen={isMoreFareRulesModalOpen}
              fareRulesOfferId={fareRulesOfferId}
              travelers={travelers}
              isLoading={isLoading}
              offer={offer}
              key={offer.id}
              setIsMoreFareRulesModalOpen={setIsMoreFareRulesModalOpen}
            />
          ))}

          <div className='w-full flex flex-col'>
            <Checkbox
              disabled={isLoading}
              checked={agreeWithTermsAndConditions}
              label={(
                <span className={checkboxLabelCN}>
                  I understand and agree with {renderLinkToTermsAndConditions()}
                </span>
              )}
              onChange={() => setAgreeWithTermsAndConditions(prevValue => !prevValue)}
            />
          </div>
        </div>
      </FlightBookingStepsWrapper>

      {isTermsAndConditionsOpen && (
        <FlightBookingTermsAndConditionsDialog
          onAgree={handleAgreeTermsAndConditions}
          onClose={() => setIsTermsAndConditionsOpen(false)}
        />
      )}
    </FlightBookingPageWrapper>
  );
};

export default FlightBookingOverview;
