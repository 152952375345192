export const SET_IS_ARRIVAL_DEPARTURE_TIME_CHANGE_MODAL_OPEN = 'flightBookings/SET_IS_ARRIVAL_DEPARTURE_TIME_CHANGE_MODAL_OPEN';
export const SET_EVENT_DATA_TO_SEARCH_PARAMS = 'flightBookings/SET_EVENT_DATA_TO_SEARCH_PARAMS';
export const SET_IS_ALL_FILTERS_MODAL_OPEN = 'flightBookings/SET_IS_ALL_FILTERS_MODAL_OPEN';
export const SET_PAYMENT_PAYER_DETAILS = 'flightBookings/SET_PAYMENT_PAYER_DETAILS';
export const SET_SELECTED_ITINERARIES = 'flightBookings/SET_SELECTED_ITINERARIES';
export const TOGGLE_TRANSFER_REQUIRED = 'flightBookings/TOGGLE_TRANSFER_REQUIRED';
export const SET_PAYMENT_PAYER_TYPE = 'flightBookings/SET_PAYMENT_PAYER_TYPE';
export const UPDATE_ADDITIONAL_BAGS = 'flightBookings/UPDATE_ADDITIONAL_BAGS';
export const SELECT_PAYMENT_COIN = 'flightBookings/SELECT_PAYMENT_COIN';
export const SELECT_PAYMENT_TYPE = 'flightBookings/SELECT_PAYMENT_TYPE';
export const SET_GROUPS_LOADING = 'flightBookings/SET_GROUPS_LOADING';
export const SET_GROUPS_SUCCESS = 'flightBookings/SET_GROUPS_SUCCESS';
export const SET_SEARCH_PARAMS = 'flightBookings/SET_SEARCH_PARAMS';
export const UPDATE_PASSENGERS = 'flightBookings/UPDATE_PASSENGERS';
export const SET_GROUPS_ERROR = 'flightBookings/SET_GROUPS_ERROR';
export const RESET_TO_INITIAL = 'flightBookings/RESET_TO_INITIAL';
export const RESET_AIRLINES = 'flightBookings/RESET_AIRLINES';
export const RESET_FILTER = 'flightBookings/RESET_FILTER';
export const SELECT_OFFER = 'flightBookings/SELECT_OFFER';
export const SET_FILTERS = 'flightBookings/SET_FILTERS';
export const SET_SORT_BY = 'flightBookings/SET_SORT_BY';
export const RESET_DATA = 'flightBookings/RESET_DATA';
export const SET_PAGE = 'flightBookings/SET_PAGE';
