import { REQUEST_CHANGES_OPTION } from '../../enums/RequestChangesOption';
import Yup from '../../utils/validation';

const EMAIL_MIN_VALUE = 2;
const EMAIL_MAX_VALUE = 255;
const DESCRIPTION_MIN = 2;
const DESCRIPTION_MAX = 1000;

const validateMessages = {
  email: {
    required: 'Email cannot be empty',
    min: `Email cannot be less than ${EMAIL_MIN_VALUE} characters`,
    max: `Email cannot be longer than ${EMAIL_MAX_VALUE} characters`,
    valid: 'Email should be in format "user@example.com"',
    disallowedSymbol: 'Email cannot contain "+"',
  },
  reason: {
    required: 'Reason cannot be empty',
  },
  description: {
    required: 'Description cannot be empty',
    min: `Description cannot be less than ${DESCRIPTION_MIN} characters`,
    max: `Description cannot be longer than ${DESCRIPTION_MAX} characters`,
  },
};

export const requestChangesSchema = Yup.object().shape({
  reason: Yup
    .string()
    .oneOf([REQUEST_CHANGES_OPTION.CANCEL_TRIP, REQUEST_CHANGES_OPTION.REQUEST_CHANGES])
    .required(validateMessages.reason.required),
  email: Yup
    .string()
    .email(validateMessages.email.valid)
    .min(EMAIL_MIN_VALUE, validateMessages.email.min)
    .max(EMAIL_MAX_VALUE, validateMessages.email.max)
    .withoutSpaces()
    .isInLatinCharacters()
    .disallowedSymbols(['+'], validateMessages.email.disallowedSymbol)
    .required(validateMessages.email.required),
  description: Yup
    .string()
    .min(DESCRIPTION_MIN, validateMessages.description.min)
    .max(DESCRIPTION_MAX, validateMessages.description.max)
    .isInLatinCharacters()
    .required(validateMessages.description.required),
});

export const initialValues = {
  description: '',
  reason: REQUEST_CHANGES_OPTION.CANCEL_TRIP,
  email: '',
};
