import { useMemo } from 'react';
import { ICreditCardPaymentCheckout } from './types';
import { getTransactionTotal } from './utils';
import TransactionDetails from '../TransactionDetails';
import PaymentCheckout from '../PaymentCheckout';
import { CreditCardOrderStatus, CreditCardOrderStatusLabel } from '../../enums/CreditCardOrder';
import { ReactComponent as RevolutLogoSvg } from '../../static/icons/revolut-logo.svg';
import CreditCardForm from '../CreditCardForm';

const CreditCardPaymentCheckout: React.FC<ICreditCardPaymentCheckout> = ({
  isRefreshLoading,
  paymentDetails,
  paymentError,
  canRetry,
  isLoading,
  order,
  onPaymentMethodChange,
  setPaymentDetails,
  setPaymentError,
  setCanRetry,
  onRefresh,
  onSuccess,
}) => {
  const amount = useMemo(() => getTransactionTotal(order), [order?.amount]);

  const onError = (error: string) => {
    setPaymentDetails(error);
    setCanRetry(true);
    setPaymentError(true);
  };

  return (
    <PaymentCheckout
      isTryAgainLoading={isRefreshLoading}
      paymentDetails={paymentDetails}
      poweredByIcon={(<RevolutLogoSvg />)}
      paymentError={paymentError}
      statusLabel={CreditCardOrderStatusLabel[order?.state]}
      isLoading={isLoading}
      isPending={[
        CreditCardOrderStatus.PENDING,
        CreditCardOrderStatus.AUTHORISED,
        CreditCardOrderStatus.PROCESSING,
      ].includes(order?.state)}
      canRetry={canRetry}
      onPaymentMethodChange={onPaymentMethodChange}
      handleTryAgain={onRefresh}
    >
      {!isLoading && (
        <CreditCardForm
          shippingAddress={order?.shipping_address}
          isProcessing={[CreditCardOrderStatus.AUTHORISED, CreditCardOrderStatus.PROCESSING].includes(order?.state)}
          payerEmail={order?.customer?.email}
          payerName={order?.customer?.full_name}
          payments={order?.payments}
          currency={order?.currency}
          amount={amount}
          token={order?.token}
          onSuccess={onSuccess}
          onError={onError}
        >
          <TransactionDetails
            isLoading={isLoading}
            currency={order?.currency}
            items={[
              { title: 'Transaction amount', amount },
              { title: 'Sum total', amount, total: true },
            ]}
          />
        </CreditCardForm>
      )}
    </PaymentCheckout>
  );
};

export default CreditCardPaymentCheckout;
