import moment from 'moment';
import request from '../../utils/request';
import { getCityByIataCode } from '../../utils/flight-offer';
import { REQUEST_CHANGES_OPTION } from '../../enums/RequestChangesOption';
import { extractErrorFromResponse } from '../FlightBookingPayment/utils';
import { showToast } from '../../utils/common';

export const getDepartureCity = (flight: any) => {
  if (!flight) {
    return null;
  }

  const segments = flight?.itineraries[0]?.segments;
  const { code } = segments[0]?.departure;
  return getCityByIataCode(code);
};

export const getDepartureDate = (flight: any) => {
  if (!flight) {
    return null;
  }

  const segments = flight?.itineraries[0]?.segments;
  const { timestamp } = segments[0]?.departure;
  return moment.utc(timestamp).format();
};

export const getArrivalCity = (flight: any) => {
  if (!flight) {
    return null;
  }

  const segments = flight?.itineraries[0]?.segments;
  const { code } = segments[segments.length - 1]?.arrival;
  return getCityByIataCode(code);
};

export const getReturnDate = (flight: any) => {
  if (!flight || flight?.itineraries?.length < 2) {
    return null
  }
  
  const segments = flight?.itineraries[flight?.itineraries.length - 1]?.segments;
  const { timestamp } = segments[0]?.arrival;
  return moment.utc(timestamp).format();;
};

export const handleFetchFlight = async ({
  amaClientRef,
  tripId,
  setIsLoading,
  setTraveler,
  setFlight,
  setError,
}: {
  amaClientRef: string,
  tripId: string,
  setIsLoading: (isFlightLoading: boolean) => void,
  setTraveler: (traveler: any) => void,
  setFlight: (flight: any) => void,
  setError: (error: string) => void,
}) => {
  try {
    setIsLoading(true);
    const response = await request.get(`white-label/flights/request-changes/${amaClientRef}?tripId=${tripId}`);
    setFlight(response?.data?.flight);
    setTraveler(response?.data?.traveler);
    setIsLoading(false);
    return response;
  } catch (err) {
    setIsLoading(false);
    setError(extractErrorFromResponse(err));
  }
};

export const handleRequestChanges = async ({
  amaClientRef,
  description,
  reason,
  tripId,
  email,
  setIsRequestChangesLoading,
  setIsRequestChangesSuccess,
}: {
  amaClientRef: string,
  description: string,
  tripId: string,
  reason: REQUEST_CHANGES_OPTION,
  email: string;
  setIsRequestChangesLoading: (isRequestChangesLoading: boolean) => void,
  setIsRequestChangesSuccess: (isRequestChangesSuccess: boolean) => void,
}) => {
  try {
    setIsRequestChangesLoading(true);

    await request.post(`white-label/flights/request-changes/${amaClientRef}?tripId=${tripId}`, {
      description: description?.trim(),
      reason,
      email,
    });

    setIsRequestChangesLoading(false);
    setIsRequestChangesSuccess(true);
  } catch (err) {
    setIsRequestChangesLoading(false);
    showToast(extractErrorFromResponse(err), 'error');
  }
};
