import { mergeClassnames } from '@heathmont/moon-core-tw';

export const optionCN = ({ firstOption, lastOption }: { firstOption: boolean, lastOption: boolean }) => mergeClassnames([
  'flex',
  'px-[16px]',
  'py-[12px]',
  'w-full',
  'justify-between',
  'items-center',
  'm-0',
  'cursor-pointer',
  'rounded-none',
  '-mt-[1px]',
  'mr-[0]',
  'mb-[0]',
  '-ml-[1px]',
  'border-[1px]',
  'border-[solid]',
  'border-beerus',
  firstOption && 'rounded-tl-[16px] rounded-tr-[16px]',
  lastOption && 'rounded-bl-[16px] rounded-br-[16px]',
  'hover:bg-[#F6F5F4]'
]);

export const checkboxCN = mergeClassnames([
  'w-[16px]',
  'h-[16px]',
  'flex',
  'items-center',
  'justify-center',
  'rounded-[5px]',
  'mr-[4px]',
]);

export const checkboxCheckedCN = mergeClassnames([
  checkboxCN,
  'text-goku',
  'bg-popo',
]);

export const checkboxUncheckedCN = mergeClassnames([
  checkboxCN,
  'text-popo',
  'bg-beerus',
]);
