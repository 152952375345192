import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import request from '../utils/request';
import { IncludedBagOptionValue } from '../enums/IncludedBag';
import { setAdditionalService } from '../utils/baggage';
import { SIGNIFICANT_PRICE_CHANGE, SIGNIFICANT_PRICE_CHANGE_PERCENT } from '../constants';

export const calculateFlightTotalCost = (offers: any[]): number => {
  const total = offers.reduce((prev, offer) => prev + +(offer?.price?.price), 0);
  return Math.round(total * 100) / 100;
};

export const isPriceChangedSignificantly = (offers: any[], additionalBags: IncludedBagOptionValue[], price: number) => {
  const oldPrice = calculateFlightTotalCost(offers);
  const maxChangeFromOldPrice = (oldPrice / 100) * SIGNIFICANT_PRICE_CHANGE_PERCENT;
  const maxChange = Math.max(SIGNIFICANT_PRICE_CHANGE, maxChangeFromOldPrice);
  const baggagePrice = additionalBags.reduce((total, bag) => total + + bag?.price?.price, 0);
  const newPrice = price - baggagePrice;
  return Math.abs(newPrice - oldPrice) > maxChange;
};

const extractTravelerRequirements = (booking) => {
  if (booking?.bookingRequirements?.travelerRequirements?.length) {
    return booking?.bookingRequirements?.travelerRequirements;
  }

  return [];
};

const isDocumentRequired = (booking): boolean => {
  const travelerRequirements = extractTravelerRequirements(booking); 
  return travelerRequirements.some(travelerRequirement => travelerRequirement?.documentRequired);
};

export const hasAdditionalServicesUnpriceableWarning = (warnings = []): boolean => {
  return warnings?.some(warning => warning.title === 'AdditionalServicesUnpriceableWarning');
};

export const useFlightOffersPriceConfirmation = ({
  includeDetailedFareRules = false,
  additionalBags = [],
  amaClientRef,
  includeBags = true,
  hasAccess,
  booking,
  offers,
}: {
  includeDetailedFareRules?: boolean,
  additionalBags?: IncludedBagOptionValue[];
  amaClientRef?: string;
  includeBags?: boolean;
  hasAccess: boolean;
  booking?: any; 
  offers: any[];
}): {
  hasAdditionalServicesUnpriceableWarning: boolean;
  includedDetailedFareRules: any;
  isPriceConfirmationError: boolean;
  isSignificantPriceChange: boolean;
  documentRequired: boolean;
  confirmedOffers: any[];
  includedBags: any;
  isLoading: boolean;
  price: number;
} => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [price, setPrice] = useState<number>(calculateFlightTotalCost(offers));
  const [confirmedOffers, setConfirmedOffers] = useState<any[]>([]);
  const [includedBags, setIncludedBags] = useState<any>(null);
  const [includedDetailedFareRules, setIncludedDetailedFareRules] = useState<any>(null);
  const [warnings, setWarnings] = useState<string[]>([]);
  const [isPriceConfirmationError, setIsPriceConfirmationError] = useState<boolean>(null);
  const [isSignificantPriceChange, setIsSignificantPriceChange] = useState<boolean>(false);
  const [documentRequired, setDocumentRequired] = useState<boolean>(true);

  const confirmPrice = async () => {
    setIsPriceConfirmationError(null);
  
    try {
      setIsLoading(true);

      const { data } = await request.post('white-label/flights/pricing', {
        includeDetailedFareRules,
        amaClientRef,
        includeBags,
        offers: setAdditionalService(offers, additionalBags),
      });

      if (data?.success && data?.data?.length) {
        const newPrice = calculateFlightTotalCost(data?.data);
      
        setPrice(newPrice);
        setConfirmedOffers(data?.data);
        setIncludedBags(data?.included?.bags);
        setIncludedDetailedFareRules(data?.included?.['detailed-fare-rules']);
        setIsPriceConfirmationError(false);
        setIsSignificantPriceChange(isPriceChangedSignificantly(offers, additionalBags, newPrice));
        setDocumentRequired(isDocumentRequired(data));

        if (data?.warnings?.length) {
          setWarnings(data.warnings);
          setIsPriceConfirmationError(hasAdditionalServicesUnpriceableWarning(data.warnings));
        }
      } else {
        setIsPriceConfirmationError(true);
      }
    } catch (err) {
      setIsPriceConfirmationError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!amaClientRef && !booking?.success) {
      navigate(`/flight-booking-list${search}`); 
    } else if (hasAccess && !booking?.success) {
      confirmPrice();
    }
  }, [amaClientRef, hasAccess, offers]);

  return {
    hasAdditionalServicesUnpriceableWarning: hasAdditionalServicesUnpriceableWarning(warnings),
    includedDetailedFareRules,
    isPriceConfirmationError,
    isSignificantPriceChange,
    documentRequired,
    confirmedOffers,
    includedBags,
    isLoading,
    price,
  };
};
