import qs from 'qs';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import { IncludedBag, IncludedBagOption, IncludedBagOptionValue } from '../enums/IncludedBag';
import { BAGGAGE_LABEL_VALUE, BAGGAGE_LABEL } from '../enums/BaggageLabel';

export const getApplyToFlightTitle = ({
  destination,
  origin,
  label,
}: {
  destination?: string,
  origin?: string,
  label: BAGGAGE_LABEL_VALUE,
}): string => {
  if (label === BAGGAGE_LABEL_VALUE.OUTBOUND) {
    return origin && destination
      ? `<b>${origin} - ${destination}</b>`
      : `<b>${BAGGAGE_LABEL.OUTBOUND}</b> flight`;
  }

  if (label === BAGGAGE_LABEL_VALUE.RETURN) {
    return origin && destination
      ? `<b>${destination} - ${origin}</b>`
      : `<b>${BAGGAGE_LABEL.RETURN}</b> flight`;
  }

  return origin && destination
      ? `<b>${origin} - ${destination}</b> and <b>${destination} - ${origin}</b>`
      : `<b>${BAGGAGE_LABEL.OUTBOUND}</b> and <b>${BAGGAGE_LABEL.RETURN}</b> flights`;
};

export const getBaggageLabel = ({
  destination,
  origin,
  label,
  bag,
}: {
  destination?: string,
  origin?: string,
  label: BAGGAGE_LABEL_VALUE, 
  bag: IncludedBag,
}): string => {
  const { price, quantity, weight, weightUnit } = bag;

  let title = +quantity > 1 ? `${quantity} extra bags` : `${quantity} extra bag`;

  if (weight) {
    title += ` (${weight}${weightUnit})`
  }

  title += ` for ${getApplyToFlightTitle({ destination, origin, label })}`;
  title += `  –  ${price.price} ${price.currencyCode}`;

  return title;
};

export const orderBagsOptions = (bags: IncludedBagOption[]): IncludedBagOption[] => {
  return orderBy(bags, ['label', 'quantity'], ['asc', 'asc']);
};

export const defineOriginSegments = (offers: any[]): string[] => {
  return offers[0].itineraries[0].segments.map(segment => segment.id).sort();
};

export const defineReturnSegments = (offers: any[]): string[] => {
  return offers.length > 1 
    ? offers[1].itineraries[0].segments.map(segment => segment.id).sort()
    : offers[0].itineraries.length > 1 
      ? offers[0].itineraries[offers[0].itineraries.length - 1].segments.map(segment => segment.id).sort()
      : []
};

export const extractBagsOptions = ({
  destination,
  origin,
  offers,
  bags,
}: {
  destination?: string,
  origin?: string,
  offers: any[],
  bags: { [key: string]: IncludedBag },
}): IncludedBagOption[] => {
  if (isEmpty(bags)) {
    return [];
  }

  const originSegments = defineOriginSegments(offers);
  const returnSegments = defineReturnSegments(offers);
  const allSegments = [ ...originSegments, ...returnSegments ].sort();
  const offerIds = offers.map(offer => offer.id);
  const options = [];

  Object.keys(bags).forEach(value => {
    const bag = bags[value];
    const segments = bag.segmentIds.sort();

    if (isEqual(segments, originSegments)) {
      options.push({
        ...bag,
        applyForSegments: segments,
        offerId: offerIds[0],
        label: BAGGAGE_LABEL_VALUE.OUTBOUND,
        title: getBaggageLabel({
          destination,
          origin,
          label: BAGGAGE_LABEL_VALUE.OUTBOUND,
          bag,
        }),
        value: qs.stringify({ bag }),
      });
    } else if (isEqual(segments, returnSegments)) {
      options.push({
        ...bag,
        applyForSegments: segments,
        offerId: offerIds.length > 1 ? offerIds[1] : offerIds[0],
        title: getBaggageLabel({
          destination,
          origin,
          label: BAGGAGE_LABEL_VALUE.RETURN,
          bag,
        }),
        value: qs.stringify({ bag }),
      });
    } else if (isEqual(segments, allSegments)) {
      const offerId = offerIds[0];
    
      if (bag.bookableByItinerary) {
        options.push({
          ...bag,
          applyForSegments: originSegments,
          offerId,
          label: BAGGAGE_LABEL_VALUE.OUTBOUND,
          title: getBaggageLabel({
            destination,
            origin,
            label: BAGGAGE_LABEL_VALUE.OUTBOUND,
            bag,
          }),
          value: qs.stringify({ bag, outbound: true }),
        }, {
          ...bag,
          applyForSegments: returnSegments,
          offerId,
          label: BAGGAGE_LABEL_VALUE.RETURN,
          title: getBaggageLabel({
            destination,
            origin,
            label: BAGGAGE_LABEL_VALUE.RETURN,
            bag,
          }),
          value: qs.stringify({ bag, return: true }),
        });
      } else {
        options.push({
          ...bag,
          applyForSegments: segments,
          offerId,
          label: BAGGAGE_LABEL_VALUE.OUTBOUND_AND_RETURN,
          title: getBaggageLabel({
            destination,
            origin,
            label: BAGGAGE_LABEL_VALUE.OUTBOUND_AND_RETURN,
            bag,
          }),
          value: qs.stringify({ bag }),
        });
      }
    }
  });

  return orderBagsOptions(options);
};

export const calculatePriceWithBags = (price: number, additionalBagPerPassenger: IncludedBagOptionValue[]): number => {
  const bagsTotalPrice = additionalBagPerPassenger.reduce((total, bag) => total + +bag?.price?.price, 0);
  const total = +price + bagsTotalPrice;
  return Math.round(total * 100) / 100;
};

export const setAdditionalService = (offers: any[], bags: IncludedBagOptionValue[]): any[] => {
  if (!bags?.length) {
    return offers;
  }

  const flightOffers = cloneDeep(offers);

  flightOffers.forEach(offer => {
    const offerBags = bags.filter(bag => +bag.offerId == +offer.id);

    if (offerBags.length) {
      const additionalServicePrice = offerBags.reduce((total, bag) => total + +bag?.price?.price, 0);
      offer.price.grandTotal = +offer.price.grandTotal + additionalServicePrice;

      const checkedBags = offerBags.map(offerBag => ({ amount: offerBag?.price?.price, type: 'CHECKED_BAGS' }));
      offer.price.additionalServices = offer.price.additionalServices?.length 
        ? [ ...offer.price.additionalServices, ...checkedBags ]
        : checkedBags;
    }

    if (offer?.travelerPricings?.length) {
      offer.travelerPricings.forEach((travelerPricing) => {
        const travelerBags = offerBags.filter(offerBag => +offerBag.passengerId === +travelerPricing.travelerId);

        if (travelerBags?.length) {
          travelerPricing.fareDetailsBySegment = travelerPricing.fareDetailsBySegment.map(details => {
          const segmentBag = travelerBags.find(travelerBag => travelerBag.applyForSegments.includes(details.segmentId));

            if (segmentBag) {
              return {
                ...details,
                additionalServices: {
                  ...details.additionalServices,
                  chargeableCheckedBags: {
                    weightUnit: segmentBag.weightUnit,
                    quantity: segmentBag.quantity,
                    weight: segmentBag.weight,
                  },
                },
              }
            }

            return details;
          });
        }
      });
    }
  });

  return flightOffers
};
