import { CryptoTransaction, CryptoTransactionStatus } from '../../enums/CryptoTransaction';

export const extractQrCodeUrl = (transaction: CryptoTransaction) => {
  return process.env.REACT_APP_SWAPIN_MODE === 'sandbox'
    ? 'https://app.swapin.com/api/api/qr/0bv8jsd2o1qh4rnrwyzgs6ewom6wqhpr/basic'
    : transaction?.qrc_image_url;
};

export const getTransactionAmount = (transaction: CryptoTransaction) => {
  return +(transaction?.total_coin - transaction?.fees_coin - transaction?.bank_fees_coin).toFixed(8);
};

export const isPendingTransaction = (transaction: CryptoTransaction): boolean => {
  return [
    CryptoTransactionStatus.UNCONFIRMED_TRANSACTION,
    CryptoTransactionStatus.FULL,
    CryptoTransactionStatus.INCOMPLETE,
    CryptoTransactionStatus.GREATER_AMOUNT,
    CryptoTransactionStatus.PROCESSING,
  ].includes(transaction?.status)
};

export const formatTransactionRaws = (transaction: CryptoTransaction): { title: string, amount: number, total?: boolean }[] => {
  return [
    { title: 'Transaction amount', amount: getTransactionAmount(transaction) },
    { title: 'Swapin fee', amount: transaction?.fees_coin },
    { title: 'Bank fee', amount: transaction?.bank_fees_coin },
    { title: 'Sum total', amount: transaction?.total_coin, total: true },
  ];
};

