import { mergeClassnames } from '@heathmont/moon-core-tw';

export const fieldWrapperCN = mergeClassnames([
  'w-full',
  'flex',
  'flex-col',
]);

export const fieldGroupTitle = mergeClassnames([
  'relative',
  'text-[16px]',
  'leading-[21px]',
  'h-[21px]',
  'font-normal',
  'm-0',
  'p-0',
]);

export const fieldGroupProgressCN = (active: boolean) => mergeClassnames([
  'absolute',
  '-left-[21px]',
  'top-2/4',
  '-translate-y-1/2',
  'w-[5px]',
  'h-[5px]',
  'rounded-[100%]',
  active ? 'bg-piccolo' : 'bg-goku border border-beerus',
]);

export const noteLabelCN = mergeClassnames([
  'text-trunks',
  'font-normal',
  'text-moon-14',
  'flex',
  'items-center',
  'w-full',
]);

export const noteIconCN = mergeClassnames([
  'flex-shrink-0',
  '-ml-[5px]',
  'mr-[5px]',
  'w-[18px]',
  'h-[18px]',
  'mb-[2px]',
  'flex-shrink-0',
]);

export const groupCN = mergeClassnames([
  'rounded-moon-i-md',
  '[&_input]:rounded-moon-i-md',
  '[&_select]:rounded-moon-i-md',
]);
