import { useState, useEffect } from 'react'; 
import { Popover } from '@heathmont/moon-core-tw';
import useIsMobile from '../../hooks/useIsMobile';
import { IDestinationSearchSelect } from './types';
import DestinationSearchSelectInput from '../DestinationSearchSelectInput';
import DestinationSearchSelectMenu from '../DestinationSearchSelectMenu';
import { filterOptions, formatLabelWithCode } from './utils';
import { wrapperCN } from './styles';

const DestinationSearchSelect: React.FC<IDestinationSearchSelect> = ({
  optionsGroup,
  lastSelect = false,
  selected,
  label,
  setOptionsGroup,
  setFieldValue,
}) => {
  const isMobile = useIsMobile();
  const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(formatLabelWithCode(selected)); 

  useEffect(() => {
    setInputValue(formatLabelWithCode(selected));
  }, [selected?.value]);

  const handleChangeValue = (option, close) => {
    setFieldValue(option);
    setInputValue(formatLabelWithCode(option));
    close();
  };

  return (
    <Popover
      className={wrapperCN(isMobile)}
      position={isMobile ? 'bottom' : 'bottom-start'}
    >
      {({ open }) => (
        <>
          <Popover.Trigger>
            <DestinationSearchSelectInput
              inputValue={inputValue}
              lastSelect={lastSelect}
              isMobile={isMobile}
              label={label}
              open={open}
              setIsOptionsLoading={setIsOptionsLoading}
              setOptionsGroup={setOptionsGroup}
              setInputValue={setInputValue}
            />
          </Popover.Trigger>
          <Popover.Panel className='w-[425px] max-tablet:w-full max-h-96 px-[24px] py-[16px] max-mobile:p-[16px]'>
            {({ close }) => (
              <DestinationSearchSelectMenu
                isLoading={isOptionsLoading}
                options={filterOptions(inputValue, optionsGroup.options)}
                label={optionsGroup.label}
                type={optionsGroup.type}
                onSelect={(option) => handleChangeValue(option, close)}
              />
            )}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default DestinationSearchSelect;
