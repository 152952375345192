import { FEEDBACK_RATE } from '../../enums/FeedbackRate';
import Yup from '../../utils/validation';

const COMMENT_MIN = 2;
const COMMENT_MAX = 1000;

const validateMessages = {
  webAppRate: {
    required: 'Web app rate cannot be empty',
  },
  comment: {
    min: `Comment cannot be less than ${COMMENT_MIN} characters`,
    max: `Comment cannot be longer than ${COMMENT_MAX} characters`,
  },
};

export const feedbackFormSchema = Yup.object().shape({
  webAppRate: Yup
    .number()
    .oneOf([
      FEEDBACK_RATE.ONE,
      FEEDBACK_RATE.TWO,
      FEEDBACK_RATE.THREE,
      FEEDBACK_RATE.FOUR,
      FEEDBACK_RATE.FIVE,
    ])
    .required(validateMessages.webAppRate.required)
    .typeError(validateMessages.webAppRate.required),
  comment: Yup
    .string()
    .min(COMMENT_MIN, validateMessages.comment.min)
    .max(COMMENT_MAX, validateMessages.comment.max)
    .isInLatinCharacters()
    .nullable(),
});

export const initialValues = {
  webAppRate: null,
  comment: '',
};

export const getFieldBaseProps = (field) => {
  return {
    inputSize: 'xlarge',
    fieldName: field, 
    fieldId: field,
  };
};
