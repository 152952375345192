import { useMemo } from 'react';
import { Dropdown, MenuItem, Button, mergeClassnames } from '@heathmont/moon-core-tw';
import { ControlsChevronDownSmall, ControlsChevronUpSmall } from '@heathmont/moon-icons-tw';
import { ReactComponent as RadioUncheckedSvg } from '../../static/icons/radio-unchecked.svg';
import { ReactComponent as RadioCheckedSvg } from '../../static/icons/radio-checked.svg';
import { IFlightBookingSorting } from './types';
import { sortOptions } from '../../enums/SortOption';
import { btnChipCN } from '../../styles/buttons';

const FlightBookingSorting: React.FC<IFlightBookingSorting> = ({
  sortBy,
  setSortBy,
}) => {
  const label = useMemo(() => {
    const selected = sortOptions.find(option => option.value === sortBy);
    return `Sort by: ${selected ? selected.label : sortBy}`;
  }, [sortBy]);

  return (
    <Dropdown
      className='tablet:w-[240px] mobile:w-[calc(50%-5px)] w-full'
      position='bottom-end'
      value={sortBy}
      onChange={setSortBy}
    >
      {({ open }) => (
        <>
          <Dropdown.Trigger className='w-full'>
            <Button
              className={mergeClassnames('w-full', btnChipCN)}
              role='button'
              as='span'
              iconRight={open ? <ControlsChevronUpSmall className='text-moon-24' />: <ControlsChevronDownSmall className='text-moon-24' />}
            >
              {label}
            </Button>
          </Dropdown.Trigger>
          <Dropdown.Options className="w-[385px] max-mobile:w-full p-0 [box-shadow:0px_6px_6px_0px_rgba(0_0_0_/_0.16)]">
            {sortOptions.map((option, index) => (
              <Dropdown.Option value={option.value} key={index}>
                {({ selected }) => (
                  <>
                    {!!index && <hr className="border-top border-beerus" />}
                    <MenuItem className="h-[3.5rem] p-[1rem] rounded-none">
                      <MenuItem.Title>{option.label}</MenuItem.Title>
                      {selected ? <RadioCheckedSvg /> : <RadioUncheckedSvg />}
                    </MenuItem>
                  </>
                )}
              </Dropdown.Option>
            ))}
          </Dropdown.Options>
        </>
      )}
    </Dropdown>
  );
};

export default FlightBookingSorting;
