import { mergeClassnames } from '@heathmont/moon-core-tw';

export const swapIconCN = (isMobile: boolean) => mergeClassnames([
  'w-[32px]',
  'h-[32px]',
  'flex',
  'items-center',
  'justify-center',
  'cursor-pointer',
  'rounded-[16px]',
  'text-bulma',
  'bg-goku',
  '-rotate-180',
  '[transition:transform_0.5s]',
  'hover:bg-[#f9f9f9]',
  'active:rotate-0',
  'active:[transition:0s]',
  'z-10',
  'absolute',
  'top-2/4',
  '-translate-y-1/2',
  isMobile && 'right-[20px]',
  !isMobile && 'left-2/4',
  !isMobile && '-translate-x-1/2',
]);
