import { connect } from 'react-redux';
import {
  getAccessToFlightBookingSearch,
  setEventLinkErrorAction,
  setEventLinkExpired,
  getEventLink,
} from '../../store/eventLink/actions';
import Component from './index.ui';

const mapStateToProps = ({ eventLinkReducer }) => {
  const { confirmed, error } = eventLinkReducer;
  return {
    hasAccess: confirmed,
    error,
  };
};

const mapDispatchToProps = {
  getAccessToFlightBookingSearch,
  setEventLinkErrorAction,
  setEventLinkExpired,
  getEventLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
