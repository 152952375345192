import { InsetInput, Checkbox, Group, Hint, InsetNativeSelect } from '@heathmont/moon-core-tw';
import { IFlightBookingPassengerForm } from './types';
import { genderOptions } from '../../enums/GenderType';
import CheckboxGroup from '../CheckboxGroup';
import { countyCodes, formatPhone } from './utils';
import { useEffect } from 'react';
import SelectOptions from '../SelectOptions';
import { clearButtonCN, fieldLabelCN, fieldLabelWrapperCN, fieldWrapperCN, groupCN } from './styles';
import { checkboxLabelCN } from '../../styles/texts';

const FlightBookingPassengerForm: React.FC<IFlightBookingPassengerForm> = ({
  travelDocumentsTypes,
  additionalBagError,
  isMultiPassengers,
  additionalBags,
  countries,
  passenger,
  airlines,
  touched,
  errors,
  bags,
  handleSelectMainContact,
  handleSelectBaggage,
  setFieldTouched,
  setFieldValue,
}) => {
  useEffect(() => {
    if (touched?.phoneCountryCode && touched?.phoneNumber) {
      setFieldTouched('phone');
    }
  }, [touched?.phoneCountryCode, touched?.phoneNumber]);

  useEffect(() => {
    setFieldValue('phone', formatPhone(passenger));
  }, [passenger?.phoneCountryCode, passenger?.phoneNumber]);

  const getFieldBaseProps = (field) => {
    return {
      name: `${field}_${passenger.id}`, 
      id: `${field}_${passenger.id}`,
    };
  };

  const resetFrequentFlayer = () => {
    setFieldValue('loyaltyNumber', { airlineId: null, number: '' });
  };

  return (
    <div className='w-full flex flex-col gap-[16px]'>
      <div className={fieldWrapperCN}>
        <InsetNativeSelect
          {...getFieldBaseProps('gender')}
          className='rounded-moon-i-md [&_select]:rounded-moon-i-md'
          error={!!(errors?.gender && touched?.gender)}
          value={passenger?.gender || ''}
          label={'Gender'}
          onChange={({ target: { value } }) => setFieldValue('gender', value)}
          onBlur={() => setFieldTouched('gender')}
        >
          <SelectOptions options={genderOptions} label='gender' />
        </InsetNativeSelect>

        {!!(errors?.gender && touched?.gender) && (
          <Hint error>{errors?.gender}</Hint>
        )}
      </div>

      <div className={fieldWrapperCN}>
        <Group className={groupCN}>
          <Group.FirstInsetInput
            {...getFieldBaseProps('firstName')}
            placeholder=' '
            error={!!(errors?.firstName && touched?.firstName)}
            value={passenger.firstName}
            onChange={({ target: { value } }) => setFieldValue('firstName', value)}
            onBlur={() => setFieldTouched('firstName')}
          >
            <InsetInput.Label>First name as in your passport</InsetInput.Label>
          </Group.FirstInsetInput>
          <Group.LastInsetInput
            {...getFieldBaseProps('lastName')}
            placeholder=' '
            error={!!(errors?.lastName && touched?.lastName)}
            value={passenger.lastName}
            onChange={({ target: { value } }) => setFieldValue('lastName', value)}
            onBlur={() => setFieldTouched('lastName')}
          >
            <InsetInput.Label>Last name as in your passport</InsetInput.Label>
          </Group.LastInsetInput>
        </Group>

        {!!(errors?.firstName && touched?.firstName) && (
          <Hint error>{errors?.firstName}</Hint>
        )}
        {!!(errors?.lastName && touched?.lastName) && (
          <Hint error>{errors?.lastName}</Hint>
        )}
      </div>     
      
      <div className={fieldWrapperCN}>
        <Group className={groupCN}>
          <Group.FirstInsetInput
            {...getFieldBaseProps('birthday')}
            placeholder='Birth date'
            error={!!(errors?.birthday && touched?.birthday)}
            value={passenger.birthday}
            type='date'
            onChange={({ target: { value } }) => setFieldValue('birthday', value || '')}
            onBlur={() => setFieldTouched('birthday')}
          >
            <InsetInput.Label>Birth date</InsetInput.Label>
          </Group.FirstInsetInput>
          <Group.LastInsetInput
            {...getFieldBaseProps('email')}
            placeholder=' '
            error={!!(errors?.email && touched?.email)}
            value={passenger.email}
            type='email'
            onChange={({ target: { value } }) => setFieldValue('email', value)}
            onBlur={() => setFieldTouched('email')}
          >
            <InsetInput.Label>Email</InsetInput.Label>
          </Group.LastInsetInput>
        </Group>
    
        {!!(errors?.birthday && touched?.birthday) && (
          <Hint error>{errors?.birthday}</Hint>
        )}
        {!!(errors?.email && touched?.email) && (
          <Hint error>{errors?.email}</Hint>
        )}
      </div>
      
      <div className={fieldWrapperCN}>
        <Group className={groupCN}>
          <Group.FirstInsetSelect
            {...getFieldBaseProps('phoneCountryCode')}
            error={!!(errors?.phoneCountryCode && touched?.phoneCountryCode) || !!(errors?.phone && touched?.phone)}
            value={passenger.phoneCountryCode || ''}
            label={'Country'}
            onChange={({ target: { value } }) => setFieldValue('phoneCountryCode', value)}
            onBlur={() => setFieldTouched('phoneCountryCode')}
          >
            <SelectOptions options={countyCodes} label='country' />
          </Group.FirstInsetSelect>
          <Group.LastInsetInput
            {...getFieldBaseProps('phoneNumber')}
            placeholder=' '
            error={!!(errors?.phoneNumber && touched?.phoneNumber) || !!(errors?.phone && touched?.phone)}
            value={passenger.phoneNumber}
            type='number'
            onChange={({ target: { value } }) => setFieldValue('phoneNumber', value)}
            onBlur={() => setFieldTouched('phoneNumber')}
          >
            <InsetInput.Label>Telephone number</InsetInput.Label>
          </Group.LastInsetInput>
        </Group>
    
        {!!(errors?.phoneCountryCode && touched?.phoneCountryCode) && (
          <Hint error>{errors?.phoneCountryCode}</Hint>
        )}
        {!!(errors?.phoneNumber && touched?.phoneNumber) && (
          <Hint error>{errors?.phoneNumber}</Hint>
        )}
        {!!(touched?.phone && touched?.phone && !errors?.phoneCountryCode && !errors?.phoneNumber && errors?.phone) && (
          <Hint error>{errors?.phone}</Hint>
        )}
      </div>

      {isMultiPassengers && (
        <div className={fieldWrapperCN}>
          <Checkbox
            checked={!!passenger?.mainContact}
            label={<span className={checkboxLabelCN}>Primary contact</span>}
            onChange={() => {
              setFieldValue('mainContact', !passenger?.mainContact);
              setFieldTouched('mainContact');
              handleSelectMainContact();
            }}
          />
        </div> 
      )}  

      {passenger?.documentRequired && (
        <>
          <div className={fieldWrapperCN}>
            <Group className={groupCN}>
              <Group.FirstInsetSelect
                {...getFieldBaseProps('document.typeId')}
                error={!!(errors?.document?.typeId && touched?.document?.typeId)}
                value={passenger.document.typeId || ''}
                label={'Document type'}
                onChange={({ target: { value } }) => setFieldValue('document.typeId', value)}
                onBlur={() => setFieldTouched('document.typeId')}
              >
                <SelectOptions options={travelDocumentsTypes} label='document type' />
              </Group.FirstInsetSelect>
              <Group.LastInsetSelect
                {...getFieldBaseProps('document.countryId')}
                error={!!(errors?.document?.countryId && touched?.document?.countryId)}
                value={passenger.document.countryId || ''}
                label={'Country issued document'}
                onChange={({ target: { value } }) => setFieldValue('document.countryId', value)}
                onBlur={() => setFieldTouched('document.countryId')}
              >
                <SelectOptions options={countries} label='country issued document' />
              </Group.LastInsetSelect>
            </Group>

            {!!(errors?.document?.typeId && touched?.document?.typeId) && (
              <Hint error>{errors?.document?.typeId}</Hint>
            )}

            {!!(errors?.document?.countryId && touched?.document?.countryId) && (
              <Hint error>{errors?.document?.countryId}</Hint>
            )}
          </div>

          <div className={fieldWrapperCN}>
            <Group className={groupCN}>
              <Group.FirstInsetInput
                {...getFieldBaseProps('document.number')}
                placeholder=' '
                error={!!(errors?.document?.number && touched?.document?.number)}
                value={passenger.document.number}
                type='text'
                onChange={({ target: { value } }) => setFieldValue('document.number', value)}
                onBlur={() => setFieldTouched('document.number')}
              >
                <InsetInput.Label>Document number</InsetInput.Label>
              </Group.FirstInsetInput>
            
              <Group.LastInsetInput
                {...getFieldBaseProps('document.validTill')}
                placeholder=' '
                error={!!(errors?.document?.validTill && touched?.document?.validTill)}
                value={passenger.document.validTill}
                type='date'
                onChange={({ target: { value } }) => setFieldValue('document.validTill', value || '')}
                onBlur={() => setFieldTouched('document.validTill')}
              >
                <InsetInput.Label>Document expiration date</InsetInput.Label>
              </Group.LastInsetInput>
            </Group>

            {!!(errors?.document?.number && touched?.document?.number) && (
              <Hint error>{errors?.document?.number}</Hint>
            )}
            {!!(errors?.document?.validTill && touched?.document?.validTill) && (
              <Hint error>{errors?.document?.validTill}</Hint>
            )}
          </div>
        </>
      )}

      {bags?.length > 0 && (
        <>
          <div className={fieldWrapperCN}>
            <div className={fieldLabelWrapperCN}>
              <h5 className={fieldLabelCN}>
                Additional baggage
              </h5>
            </div>    

            <CheckboxGroup
              selectedValues={additionalBags}
              data={bags}
              renderCheckboxLabel={(title: string) => (
                <span className={checkboxLabelCN} dangerouslySetInnerHTML={{ __html: title }} />
              )}
              onSelect={handleSelectBaggage}
            />

            {additionalBagError && (
              <Hint error>{additionalBagError}</Hint>
            )}
          </div>
        </>
      )}

      <div className={fieldWrapperCN}>
        <div className={fieldLabelWrapperCN}>
          <h5 className={fieldLabelCN}>
            Frequent flyer (optional)
          </h5>

          <span className={clearButtonCN} onClick={resetFrequentFlayer}>
            Clear all
          </span>
        </div>

        <Group className={groupCN}>
          <Group.FirstInsetSelect
            {...getFieldBaseProps('loyaltyNumber.airlineId')}
            error={!!(errors?.loyaltyNumber?.airlineId && touched?.loyaltyNumber?.airlineId)}
            value={passenger.loyaltyNumber.airlineId || ''}
            label={'Frequent flyer program'}
            onChange={({ target: { value } }) => setFieldValue('loyaltyNumber.airlineId', value)}
            onBlur={() => setFieldTouched('loyaltyNumber.airlineId')}
          >
            <SelectOptions options={airlines} label='frequent flyer program' />
          </Group.FirstInsetSelect>
        
          <Group.LastInsetInput
            {...getFieldBaseProps('loyaltyNumber.number')}
            placeholder=' '
            error={!!(errors?.loyaltyNumber?.number && touched?.loyaltyNumber?.number)}
            value={passenger.loyaltyNumber.number}
            type='text'
            onChange={({ target: { value } }) => setFieldValue('loyaltyNumber.number', value)}
            onBlur={() => setFieldTouched('loyaltyNumber.number')}
          >
            <InsetInput.Label>Flyer number</InsetInput.Label>
          </Group.LastInsetInput>
        </Group>
        
        {!!(errors?.loyaltyNumber?.airlineId && touched?.loyaltyNumber?.airlineId) && (
          <Hint error>{errors?.loyaltyNumber?.airlineId}</Hint>
        )}
        {!!(errors?.loyaltyNumber?.number && touched?.loyaltyNumber?.number) && (
          <Hint error>{errors?.loyaltyNumber?.number}</Hint>
        )}
      </div>
    </div>
  );
};

export default FlightBookingPassengerForm;
