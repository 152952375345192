import { useMemo } from 'react';
import { TravelAirplane, MapsMarker } from '@heathmont/moon-icons-tw';
import { MenuItem, Avatar } from '@heathmont/moon-core-tw';
import { DESTINATION_GROUP_TYPES } from '../../enums/Destination';
import { REGION_TYPES } from '../../enums/RegionTypes';
import { IDestinationSearchSelectMenu } from './types';
import { iconCN, labelCN, loadingTextCN, optionIconCN, optionTitleCN } from './styles';

const DestinationSearchSelectMenu: React.FC<IDestinationSearchSelectMenu> = ({
  isLoading,
  options,
  label,
  type,
  onSelect,
}) => {
  const loading = useMemo(() => {
    return !options?.length || isLoading;
  }, [options.length, isLoading]);

  if (loading) {
    return (
      <div className='w-full text-left'>
        <span className={loadingTextCN}>
          Searching...
        </span>
      </div>
    );
  }

  return (
    <>
      <div className={labelCN(type === DESTINATION_GROUP_TYPES.POPULAR_CITIES)}>
        {label}
      </div>

      {options.map(option => (
        <MenuItem key={`${option.code}-${option.type || ''}`} className='min-h-12 flex items-center' onClick={() => onSelect(option)}>
          {option?.image ? (
            <Avatar imageUrl={option?.image} size='lg' />
          ) : (
            <div className={optionIconCN(option.subLocation && options.length > 1)}>
              {option.type === REGION_TYPES.AIRPORT ? (
                <TravelAirplane className={iconCN} />
              ) : (
                <MapsMarker className={iconCN} />
              )}
            </div>
          )}
          <MenuItem.Title>
            <div destination-code-attr={option.code} className={optionTitleCN}>
              {option.label}
            </div>
          </MenuItem.Title>
        </MenuItem>
      ))}
    </>
  );
};

export default DestinationSearchSelectMenu;
