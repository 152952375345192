import { mergeClassnames } from '@heathmont/moon-core-tw';

export const itemCN = (total: boolean) => mergeClassnames([
  'w-full',
  'flex',
  'items-center',
  'justify-between',
  'text-moon-16',
  'font-normal',
  'text-trunks',
  'px-[0]',
  'py-[16px]',
  total ? 'font-medium text-popo' : 'border-b border-beerus',
]);