import moment from 'moment';
import { MAX_NUMBER_PASSENGERS, MIN_NUMBER_PASSENGERS } from '../../constants';
import { CABIN } from '../../enums/CabinTypes';
import Yup from '../../utils/validation';

export const initialValues = {
  origin: null,
  destination: null,
  departureDate: null,
  returnDate: null,
  cabin: CABIN.ECONOMY,
  passengers: 1,
};

const validateMessages = {
  origin: {
    required: 'Origin is required',
  },
  destination: {
    required: 'Destination is required',
    unique: 'Origin and destination can not be the same',
  },
  departureDate: {
    afterToday: 'Departure date has to be after today',
    required: 'Departure date is required',
  },
  returnDate: {
    afterDeparture: 'Return date has to be after departure date',
  },
  cabin: {
    required: 'Cabin class is required',
  },
  passengers: {
    required: 'Passengers is required',
    min: 'Min number of passengers is 1',
    max: 'Max number of passengers is 6',
  },
};

export const flightBookingSearchSchema = Yup.object().shape({
  origin: Yup
    .object()
    .shape({
      value: Yup.string().required(),
    })
    .typeError(validateMessages.origin.required)
    .required(validateMessages.origin.required),
  destination: Yup
    .object()
    .shape({
      value: Yup.string().required(),
    })
    .test('unique', validateMessages.destination.unique, (destination, ctx) => {
      return !destination?.value || destination?.value !== ctx.parent.origin?.value;
    })
    .typeError(validateMessages.destination.required)
    .required(validateMessages.destination.required),
  departureDate: Yup
    .date()
    .nullable()
    .default(null)
    .test('after-today', validateMessages.departureDate.afterToday, (departureDate, ctx) => {
      return !departureDate
      || moment(departureDate).isAfter(moment());
    })
    .required(validateMessages.departureDate.required),
  returnDate: Yup
    .date()
    .nullable()
    .test('after-departure', validateMessages.returnDate.afterDeparture, (returnDate, ctx) => {
      return !returnDate
      || !ctx.parent.departureDate
      || moment(returnDate).isSameOrAfter(moment(ctx.parent.departureDate));
    })
    .default(null),
  cabin: Yup
    .string()
    .required(validateMessages.cabin.required),
  passengers: Yup
    .number()
    .min(MIN_NUMBER_PASSENGERS, validateMessages.passengers.max)
    .max(MAX_NUMBER_PASSENGERS, validateMessages.passengers.max)
    .required(validateMessages.passengers.required),
});
