import moment from 'moment';
import { PAYER_DOCUMENT_TYPES } from '../../enums/PayerDocumentTypes';
import { PAYER_TYPE } from '../../enums/PayerTypes';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import Yup from '../../utils/validation';

const DEFAULT_STRING_MIN_VALUE = 2;
const DEFAULT_STRING_MAX_VALUE = 255;
const DEFAULT_SHIPPING_ADDRESS_MAX_LENGTH = 100;
const PAYER_DOCUMENT_NUMBER_MIN = 6;
const PAYER_DOCUMENT_NUMBER_MAX = 25;
const PAYER_AGE_MIN = 18;
const PAYER_AGE_MAX = 100;

const validateMessages = {
  coin: {
    required: 'Coin cannot be empty',
    min: `Coin cannot be less than ${DEFAULT_STRING_MIN_VALUE} characters`,
    max: `Coin cannot be longer than ${DEFAULT_STRING_MAX_VALUE} characters`,
  },
  paymentType: {
    required: 'Payment type cannot be empty',
    min: `Payment type cannot be less than ${DEFAULT_STRING_MIN_VALUE} characters`,
    max: `Payment type cannot be longer than ${DEFAULT_STRING_MAX_VALUE} characters`,
  },
  payerType: {
    required: 'Payer type cannot be empty',
    min: `Payer type cannot be less than ${DEFAULT_STRING_MIN_VALUE} characters`,
    max: `Payer type cannot be longer than ${DEFAULT_STRING_MAX_VALUE} characters`,
  },
  payerName: {
    required: 'Payer name cannot be empty',
    min: `Payer name cannot be less than ${DEFAULT_STRING_MIN_VALUE} characters`,
    max: `Payer name cannot be longer than ${DEFAULT_STRING_MAX_VALUE} characters`,
  },
  payerEmail: {
    required: 'Payer email cannot be empty',
    min: `Payer email cannot be less than ${DEFAULT_STRING_MIN_VALUE} characters`,
    max: `Payer email cannot be longer than ${DEFAULT_STRING_MAX_VALUE} characters`,
    valid: 'Payer email should be in format "user@example.com"',
    disallowedSymbol: 'Payer email cannot contain "+"',
  },
  payerRegistrationNumber: {
    required: 'Payer registration number cannot be empty',
    min: `Payer registration number cannot be less than ${DEFAULT_STRING_MIN_VALUE} characters`,
    max: `Payer registration number cannot be longer than ${DEFAULT_STRING_MAX_VALUE} characters`,
  },
  payerVatNumber: {
    required: 'Payer VAT number cannot be empty',
    min: `Payer VAT number cannot be less than ${DEFAULT_STRING_MIN_VALUE} characters`,
    max: `Payer VAT number cannot be longer than ${DEFAULT_STRING_MAX_VALUE} characters`,
  },
  payerCountry: {
    required: 'Payer country cannot be empty',
  },
  payerStateOrCounty: {
    min: `Payer state or county cannot be less than ${DEFAULT_STRING_MIN_VALUE} characters`,
    max: `Payer state or county cannot be longer than ${DEFAULT_SHIPPING_ADDRESS_MAX_LENGTH} characters`,
    disallowedSymbol: 'Payer state or county cannot contain "|"',
  },
  payerCity: {
    required: 'Payer city cannot be empty',
    min: `Payer city cannot be less than ${DEFAULT_STRING_MIN_VALUE} characters`,
    max: `Payer city cannot be longer than ${DEFAULT_SHIPPING_ADDRESS_MAX_LENGTH} characters`,
    disallowedSymbol: 'Payer city cannot contain "|"',
  },
  payerAddress: {
    required: 'Payer address line cannot be empty',
    min: `Payer address line cannot be less than ${DEFAULT_STRING_MIN_VALUE} characters`,
    max: `Payer address line cannot be longer than ${DEFAULT_SHIPPING_ADDRESS_MAX_LENGTH} characters`,
    disallowedSymbol: 'Payer address line cannot contain "|"',
  },
  payerPostcode: {
    required: 'Payer postcode cannot be empty',
    min: `Payer postcode cannot be less than ${DEFAULT_STRING_MIN_VALUE} characters`,
    max: `Payer postcode cannot be longer than ${DEFAULT_SHIPPING_ADDRESS_MAX_LENGTH} characters`,
    disallowedSymbol: 'Payer postcode cannot contain "|"',
  },
  payerDocumentNumber: {
    required: 'Payer document number cannot be empty',
    min: `Payer document number cannot be less than ${PAYER_DOCUMENT_NUMBER_MIN} characters`,
    max: `Payer document number cannot be longer than ${PAYER_DOCUMENT_NUMBER_MAX} characters`,
  },
  payerDocumentType: {
    required: 'Payer document type cannot be empty',
  },
  payerBirthCountry: {
    required: 'Payer birth country cannot be empty',
  },
  payerBirthDate: {
    required: 'Payer birth date cannot be empty',
    max: `Age cannot be more then ${PAYER_AGE_MAX} years old`,
    min: `Age cannot be less then ${PAYER_AGE_MIN} years old`,
  },
};

export const paymentSchema = Yup.object().shape({
  payment: Yup.object().shape({
    paymentType: Yup
      .string()
      .oneOf([PAYMENT_TYPE.CARD, PAYMENT_TYPE.CRYPTO, PAYMENT_TYPE.INVOICE])
      .required(validateMessages.paymentType.required)
      .min(DEFAULT_STRING_MIN_VALUE, validateMessages.paymentType.min)
      .max(DEFAULT_STRING_MAX_VALUE, validateMessages.paymentType.max)
      .withoutSpaces()
      .isInLatinCharacters(),
  coin: Yup
    .string()
    .when('paymentType', ([value]) => {
      if (value === PAYMENT_TYPE.CRYPTO) {
        return Yup
          .string()
          .min(DEFAULT_STRING_MIN_VALUE, validateMessages.coin.min)
          .max(DEFAULT_STRING_MAX_VALUE, validateMessages.coin.max)
          .withoutSpaces()
          .isInLatinCharacters()
          .required(validateMessages.coin.required);
      } else {
        return Yup
          .string()
          .nullable()
      }
    }),
  payerType: Yup
    .string()
    .oneOf([PAYER_TYPE.BUSINESS, PAYER_TYPE.INDIVIDUAL])
    .required(validateMessages.payerType.required)
    .min(DEFAULT_STRING_MIN_VALUE, validateMessages.payerType.min)
    .max(DEFAULT_STRING_MAX_VALUE, validateMessages.payerType.max)
    .withoutSpaces()
    .isInLatinCharacters(),
  payerName: Yup
    .string()
    .min(DEFAULT_STRING_MIN_VALUE, validateMessages.payerName.min)
    .max(DEFAULT_STRING_MAX_VALUE, validateMessages.payerName.max)
    .withoutSpaces()
    .isInLatinCharacters()
    .required(validateMessages.payerName.required),
  payerEmail: Yup
    .string()
    .email(validateMessages.payerEmail.valid)
    .min(DEFAULT_STRING_MIN_VALUE, validateMessages.payerEmail.min)
    .max(DEFAULT_STRING_MAX_VALUE, validateMessages.payerEmail.max)
    .withoutSpaces()
    .isInLatinCharacters()
    .disallowedSymbols(['+'], validateMessages.payerEmail.disallowedSymbol)
    .required(validateMessages.payerEmail.required),
  payerRegistrationNumber: Yup
    .string()
    .when('payerType', ([value]) => {
      if (value === PAYER_TYPE.BUSINESS) {
        return Yup
          .string()
          .min(DEFAULT_STRING_MIN_VALUE, validateMessages.payerRegistrationNumber.min)
          .max(DEFAULT_STRING_MAX_VALUE, validateMessages.payerRegistrationNumber.max)
          .withoutSpaces()
          .isInLatinCharacters()
          .required(validateMessages.payerRegistrationNumber.required)
      } else {
        return Yup
          .string()
          .nullable();
      }
    }),
  payerVatNumber: Yup
    .string()
    .when('payerType', ([value]) => {
      if (value === PAYER_TYPE.BUSINESS) {
        return Yup
          .string()
          .min(DEFAULT_STRING_MIN_VALUE, validateMessages.payerVatNumber.min)
          .max(DEFAULT_STRING_MAX_VALUE, validateMessages.payerVatNumber.max)
          .withoutSpaces()
          .isInLatinCharacters()
          .required(validateMessages.payerVatNumber.required);
      } else {
        return Yup
          .string()
          .nullable();
      }
    }),
  payerCountry: Yup
    .number()
    .required(validateMessages.payerCountry.required),
  payerStateOrCounty: Yup
    .string()
    .min(DEFAULT_STRING_MIN_VALUE, validateMessages.payerStateOrCounty.min)
    .max(DEFAULT_SHIPPING_ADDRESS_MAX_LENGTH, validateMessages.payerStateOrCounty.max)
    .withoutSpaces()
    .isInLatinCharacters()
    .disallowedSymbols(['|'], validateMessages.payerStateOrCounty.disallowedSymbol)
    .nullable(),
  payerCity: Yup
    .string()
    .min(DEFAULT_STRING_MIN_VALUE, validateMessages.payerCity.min)
    .max(DEFAULT_SHIPPING_ADDRESS_MAX_LENGTH, validateMessages.payerCity.max)
    .withoutSpaces()
    .isInLatinCharacters()
    .disallowedSymbols(['|'], validateMessages.payerCity.disallowedSymbol)
    .required(validateMessages.payerCity.required),
  payerAddress: Yup
    .string()
    .min(DEFAULT_STRING_MIN_VALUE, validateMessages.payerAddress.min)
    .max(DEFAULT_SHIPPING_ADDRESS_MAX_LENGTH, validateMessages.payerAddress.max)
    .withoutSpaces()
    .isInLatinCharacters()
    .disallowedSymbols(['|'], validateMessages.payerAddress.disallowedSymbol)
    .required(validateMessages.payerAddress.required),
  payerPostcode: Yup
    .string()
    .min(DEFAULT_STRING_MIN_VALUE, validateMessages.payerPostcode.min)
    .max(DEFAULT_SHIPPING_ADDRESS_MAX_LENGTH, validateMessages.payerPostcode.max)
    .withoutSpaces()
    .isInLatinCharacters()
    .disallowedSymbols(['|'], validateMessages.payerPostcode.disallowedSymbol)
    .required(validateMessages.payerPostcode.required),
  payerDocumentNumber: Yup
    .string()
    .when('paymentType', ([value]) => {
      if (value === PAYMENT_TYPE.CRYPTO) {
        return Yup
          .string()
          .min(PAYER_DOCUMENT_NUMBER_MIN, validateMessages.payerDocumentNumber.min)
          .max(PAYER_DOCUMENT_NUMBER_MAX, validateMessages.payerDocumentNumber.max)
          .withoutSpaces()
          .isInLatinCharacters()
          .required(validateMessages.payerDocumentNumber.required);
      } else {
        return Yup
          .string()
          .nullable();
      }
    }),
  payerDocumentType: Yup
    .string()
    .when('paymentType', ([value]) => {
      if (value === PAYMENT_TYPE.CRYPTO) {
        return Yup
          .string()
          .oneOf([PAYER_DOCUMENT_TYPES.ID_CARD, PAYER_DOCUMENT_TYPES.PASSPORT])
          .required(validateMessages.payerDocumentType.required);
      } else {
        return Yup
          .string()
          .nullable();
      }
    }),
  payerBirthCountry: Yup
    .number()
    .when('paymentType', ([value]) => {
      if (value === PAYMENT_TYPE.CRYPTO) {
        return Yup
          .number()
          .required(validateMessages.payerBirthCountry.required);
      } else {
        return Yup
          .number()
          .nullable();
      }
    }),
  payerBirthDate: Yup
    .date()
    .when('paymentType', ([value]) => {
      if (value === PAYMENT_TYPE.CRYPTO) {
        return Yup
          .date()
          .required(validateMessages.payerBirthDate.required)
          .test('max-age', validateMessages.payerBirthDate.max, (value) => (
            moment().diff(moment(value), 'years') <= PAYER_AGE_MAX
          ))
          .test('min-age', validateMessages.payerBirthDate.min, (value) => (
            moment().diff(moment(value), 'years') >= PAYER_AGE_MIN
          ))
          .nullable();
      } else {
        return Yup
          .date()
          .nullable();
      }
    }),
  }).required(),
});

