import { mergeClassnames } from '@heathmont/moon-core-tw';
import { textEllipsisCN } from '../../styles/texts';

export const labelCN = (popular: boolean) => mergeClassnames([
  'h-[42px]',
  'flex',
  'items-center',
  'text-moon-12',
  'font-normal',
  'tracking-[1.5px]',
  popular ? 'text-trunks' : 'text-bulma',
]);

export const optionIconCN = (subLocation: boolean) => mergeClassnames([
  subLocation ? 'pl-[25px]' : 'pl-0',
  'flex',
  'h-[24px]',
  'items-center',
]);

export const optionTitleCN = mergeClassnames([
  textEllipsisCN,
  'text-moon-16',
  'after:content-[attr(destination-code-attr)]',
  'after:display',
  'after:text-moon-12',
  'after:text-trunks',
  'after:ml-[3px]',
  'after:tracking-[1.5px]'
]);

export const iconCN = mergeClassnames([
  'text-moon-24',
  'text-trunks',
]);

export const loadingTextCN = mergeClassnames([
  'text-left',
  'block',
  'px-[0]',
  'py-[5px]',
  'font-[monospace]',
  'w-[fit-content]',
  'text-[14px]',
  '[clip-path:inset(0_3ch_0_0)]',
  'animate-[searching_1s_steps(4)_infinite]',
]);
