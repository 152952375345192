import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, mergeClassnames } from '@heathmont/moon-core-tw';
import { ControlsChevronLeft } from '@heathmont/moon-icons-tw';
import { IFlightBookingBackAndNext } from './types';
import { buttonsWrapperCN } from './styles';
import useIsMobile from '../../hooks/useIsMobile';
import { flightBookingSteps } from '../../enums/FlightBookingStep';
import { btnArrow, btnContinueCN, btnStatic } from '../../styles/buttons';

const FlightBookingBackAndNext: React.FC<IFlightBookingBackAndNext> = ({
  submitLabel = 'Continue',
  withSearch = true,
  isLoading,
  disabled,
  price,
  step,
  proceedContinue,
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { next, back } = useMemo(() => flightBookingSteps[step], [step]);

  const handleBack = () => {
    navigate(`/${back}${search}`);
  };

  const handleNext = async () => {
    if (proceedContinue) {
      await proceedContinue();
    } else {
      navigate(`/${next}${withSearch ? search : ''}`);
    }
  };

  return (
    <div className={buttonsWrapperCN(isMobile)}>
      {back && isMobile && (
        <Button
          className={mergeClassnames('w-[58px] h-[58px]', btnArrow)}
          iconOnly={<ControlsChevronLeft fontSize="1.2rem" />}
          onClick={handleBack}
        />
      )}
      
      {(next || proceedContinue) && (
        <Button
          className={mergeClassnames(btnContinueCN, btnStatic)}
          fullWidth={!isMobile}
          disabled={isLoading || disabled}
          size="xl"
          onClick={handleNext}
        >
          <span className="flex flex-col gap-1">
            <span className="text-moon-24 max-tablet:text-moon-16 font-medium">
              {submitLabel}
            </span>
            {!isLoading && (
              <span className="text-moon-16 max-tablet:text-moon-12 font-normal">
                Your payment: €{price}
              </span>
            )}
          </span>
        </Button>
      )}
    </div>
  );
};

export default FlightBookingBackAndNext;
