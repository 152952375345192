import { mergeClassnames } from '@heathmont/moon-core-tw';
import { textEllipsisCN } from '../../styles/texts';

export const summaryWrapperCN = ({ withShadow, withBorder }: { withShadow: boolean, withBorder: boolean }) => mergeClassnames([
  'rounded-[12px]',
  'bg-goku',
  'p-[16px]',
  'flex',
  'items-center',
  'justify-center',
  'flex-col',
  'relative',
  'w-full',
  'max-tablet:px-[16px]',
  'max-tablet:py-[12px]',
  withShadow && 'shadow-moon-xs mb-[10px]',
  withBorder && 'border-b border-dashed border-beerus',
]);

export const headerCN = mergeClassnames([
  'flex',
  'items-center',
  'justify-center',
  'w-full',
  'gap-[10px]',
  'max-tablet:justify-start',
  'max-tablet:gap-[5px]',
]);

export const summaryTitleCN = mergeClassnames([
  'text-bulma',
  'text-moon-24',
  'max-tablet:justify-start',
  'max-tablet:text-moon-18',
  textEllipsisCN,
]);

export const summaryDetailsCN = mergeClassnames([
  'flex',
  'items-center',
  'justify-center',
  'w-full',
  'gap-[10px]',
  'max-tablet:justify-start',
  'max-tablet:gap-[5px]',
]);

export const labelCN = mergeClassnames([
  'flex',
  'items-center',
  'text-trunks',
  'text-[16px]',
  'whitespace-nowrap',
  'overflow-hidden',
  'max-tablet:text-[12px]'
]);

export const labelSeparatorCN = mergeClassnames([
  'text-[32px]',
  'leading-[16px]',
  'text-popo',
  'max-tablet: leading-[12px]',
]);
