import { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowsTopRight } from '@heathmont/moon-icons-tw';
import {
  footerAddressWrapperCN,
  footerAddressTextCN,
  footerWrapperCN,
  footerSectionCN,
  iconCN,
  textCN,
  linkCN,
} from './styles';
import { ReactComponent as SocialTelegramSvg } from '../../static/icons/social-telegram.svg';
import { ReactComponent as SocialWatsAppSvg } from '../../static/icons/social-wats-app.svg';
import { ReactComponent as SocialGmailSvg } from '../../static/icons/social-gmail.svg'
import FlightBookingContactForm from '../FlightBookingContactForm';
import { IFooter } from './types';

const links = [
  {
    href: '/about-us',
    title: 'More about us',
  },
  {
    href: '/flight-booking-privacy-and-policy',
    title: 'Privacy policy',
  },
  {
    href: '/flight-booking-terms-and-conditions',
    title: 'Terms & Conditions',
  },
];

const social = [
  {
    title: process.env.REACT_APP_CONTACT_EMAIL,
    icon: <SocialGmailSvg className={iconCN} />,
  },
  {
    href: `https://wa.me/${process.env.REACT_APP_CONTACT_WATS_APP}`,
    title: 'WhatsApp',
    icon: <SocialWatsAppSvg className={iconCN} />,
  },
  {
    href: `https://t.me/${process.env.REACT_APP_CONTACT_TELEGRAM}`,
    title: 'Telegram',
    icon: <SocialTelegramSvg className={iconCN} />,
  },
];

const Footer: React.FC<IFooter> = ({
  isContactFormOpen,
  setIsContactFormOpen,
}) => {
  return (
    <>
      <div className={footerWrapperCN}>
        <div className={footerSectionCN}>
          {links.map(({ href, title }) => (
            <RouterLink className={linkCN} to={href} key={href}>
              {title}
            </RouterLink>
          ))}
        </div>
        <div className={footerSectionCN}>
          <div className={linkCN} onClick={() => setIsContactFormOpen(true)}>
            Contact form
            <ArrowsTopRight style={{ width: '16px', height: '16px' }} />
          </div>
          {social.map(({ href, title, icon }, index) => (
            <Fragment key={index}>
              {href ? (
                <a className={linkCN} href={href} target={'_blank'}>
                  {icon}
                  {title}
                </a>
              ) : (
                <span className={textCN}>
                  {icon}
                  {title}
                </span>
              )}
            </Fragment>
          ))}
        </div>
        <div className={footerAddressWrapperCN}>
          <div className={footerAddressTextCN}>
            HEATHMONT TRAVEL OÜ, 2019.
          </div>
          <div className={footerAddressTextCN}>
            Suur-Patarei 13, Tallinn, Estonia 10415
          </div>
          <div className='block h-full border-l border-beerus max-tablet:hidden' />
          <div className={footerAddressTextCN}>
            Registry code: 14790371
          </div>
        </div>
      </div>
      {isContactFormOpen && (
        <FlightBookingContactForm onClose={() => setIsContactFormOpen(false)} />
      )}
    </>
  );
};

export default Footer;
