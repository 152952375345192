export enum SORT {
  OPTIMAL_FIRST = 'optimal',
  CHEAPEST_FIRST = 'cheapest',
  FASTEST_FIRST = 'fastest',
};

export enum SORT_LABEL {
  OPTIMAL_FIRST = 'Optimal first',
  CHEAPEST_FIRST = 'Cheapest first',
  FASTEST_FIRST = 'Fastest first',
}

export const sortOptions = [
  {
    value: SORT.OPTIMAL_FIRST,
    label: SORT_LABEL.OPTIMAL_FIRST,
  },
  {
    value: SORT.CHEAPEST_FIRST,
    label: SORT_LABEL.CHEAPEST_FIRST,
  },
  {
    value: SORT.FASTEST_FIRST,
    label: SORT_LABEL.FASTEST_FIRST,
  },
];

