import { connect } from 'react-redux';
import FlightBookingPassengers from './FlightBookingPassengers.ui';
import {
  updateAdditionalBags,
  updatePassengers,
} from '../../store/flightBookings/actions';

const mapStateToProps = ({ eventLinkReducer, flightBookingsReducer }) => {
  return {
    additionalBags: flightBookingsReducer.additionalBags,
    selectedOffers: flightBookingsReducer.selectedOffers,
    searchParams: flightBookingsReducer.search,
    amaClientRef: flightBookingsReducer.amaClientRef,
    passengers: flightBookingsReducer.passengers,
    hasAccess: !!(eventLinkReducer.confirmed && eventLinkReducer.eventLink),
  };
};

const mapDispatchToProps = {
  updateAdditionalBags,
  updatePassengers,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingPassengers);
