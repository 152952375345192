import { connect } from 'react-redux';
import FlightBookingConfirmation from './FlightBookingConfirmation.ui';
import { resetToInitial } from '../../store/flightBookings/actions';
import {
  setIsMoreFareRulesModalOpen,
  setIsFeedbackFormOpen,
} from '../../store/flightBookingsDialogs/actions';

const mapStateToProps = ({
  flightBookingsDialogsReducer,
  eventLinkReducer,
}) => {
  return {
    isMoreFareRulesModalOpen: flightBookingsDialogsReducer.isMoreFareRulesModalOpen,
    isFeedbackModalOpen: flightBookingsDialogsReducer.isFeedbackModalOpen,
    fareRulesOfferId: flightBookingsDialogsReducer.fareRulesOfferId,
    hasAccess: !!(eventLinkReducer.confirmed && eventLinkReducer.eventLink),
  };
};

const mapDispatchToProps = {
  setIsMoreFareRulesModalOpen,
  setIsFeedbackFormOpen,
  resetToInitial,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingConfirmation);
