import { IFlightBookingTermsAndConditionsDialog } from './types';
import Modal from '../Modal';
import TermsAndConditionsContent from '../TermsAndConditionsContent';

const FlightBookingTermsAndConditionsDialog: React.FC<IFlightBookingTermsAndConditionsDialog> = ({
  onAgree,
  onClose,
}) => { 
  return (
    <Modal
      submitButtonLabel="Agree"
      cancelButtonLabel="Close"
      title="General Terms & Conditions"
      onSubmit={onAgree}
      onClose={onClose}
    >
      <TermsAndConditionsContent />
    </Modal>
  );
};

export default FlightBookingTermsAndConditionsDialog;
