import { mergeClassnames } from '@heathmont/moon-core-tw';

export const sectionSubTitleCN = mergeClassnames([
  'text-popo',
  '!font-normal',
  'text-moon-16',
  'block',
]);

export const sectionTitleCN = mergeClassnames([
  sectionSubTitleCN,
  'ml-[10px]',
]);

export const paragraphCN = mergeClassnames([
  'text-trunks',
  'font-normal',
  'text-moon-16',
  'block',
]);

export const linkCN = mergeClassnames([
  'text-popo',
  'font-normal',
  'text-moon-16',
  'underline',
  'hover:text-popo',
  'hover:underline',
  'hover:[text-shadow:0_0_0.01px_popo]',
]);
