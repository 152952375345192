
import { PAYMENT_TYPE, paymentTypeOptions } from '../../enums/PaymentTypes';
import { ReactComponent as CreditCardSvg } from '../../static/icons/credit-card.svg';
import { ReactComponent as InvoiceSvg } from '../../static/icons/invoice.svg';
import { ReactComponent as CryptoSvg } from '../../static/icons/crypto.svg';
import { paymentTypeCN, iconCN } from './styles';
import { IPaymentTypeSelect } from './types';

const PaymentTypeSelect: React.FC<IPaymentTypeSelect> = ({
  paymentType,
  onChange,
}) => {
  const renderPaymentTypeIcon = (type: PAYMENT_TYPE): JSX.Element => {
    if (type === PAYMENT_TYPE.CARD) {
      return <CreditCardSvg className={iconCN} />
    }

    if (type === PAYMENT_TYPE.CRYPTO) {
      return <CryptoSvg className={iconCN} />
    }

    if (type === PAYMENT_TYPE.INVOICE) {
      return <InvoiceSvg className={iconCN} />
    }
  };

  return (
    <div className='flex w-full h-[150px] gap-[6px] max-mobile:h-[100px]'>
      {paymentTypeOptions.map(paymentOption => (
        <div
          key={paymentOption.value}
          className={paymentTypeCN(paymentType === paymentOption.value)}
          onClick={() => onChange(paymentOption.value)}
        >
          <div className='w-[60px] h-[60px] flex items-center justify-center max-mobile:w-[30px] max-mobile:h-[30px]'>
            {renderPaymentTypeIcon(paymentOption.value)}
          </div>
          <span className='text-moon-16 text-trunks max-mobile:text-moon-12'>
            {paymentOption.label}
          </span>
        </div>
      ))}
    </div>
  );
};

export default PaymentTypeSelect;
