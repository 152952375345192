import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IFlightBookingCardPayment } from './types';
import FlightBookingPageWrapper from '../../components/FlightBookingPageWrapper';
import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';
import { fetchOrder, refreshOrder } from './utils';
import useIsMobile from '../../hooks/useIsMobile';
import { CreditCardOrder } from '../../enums/CreditCardOrder';
import FlightBookingLoader from '../../components/FlightBookingLoader';
import { handleSuccessfulPayment } from '../../utils/payments';
import { useConfirmOfferPriceAndAvailability } from '../../hooks/useConfirmOfferPriceAndAvailability';
import { calculateFlightTotalCost } from '../../hooks/useFlightOffersPriceConfirmation';
import FlightBookingPriceChangedDialog from '../../components/FlightBookingPriceChangedDialog';
import { handleAcceptNewPriceAndContinue } from '../../utils/flights';
import CreditCardPaymentCheckout from '../../components/CreditCardPaymentCheckout';

const FlightBookingCardPayment: React.FC<IFlightBookingCardPayment> = ({
  isPriceChangedModalOpen,
  hasAccess,
  setIsPriceChangedModalOpen,
  resetToInitial,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [offers, setOffers] = useState([]);
  const [order, setOrder] = useState<CreditCardOrder | null>(null);
  const [paymentDetails, setPaymentDetails] = useState<string | null>(null);
  const [paymentError, setPaymentError] = useState<boolean>(false);
  const [canRetry, setCanRetry] = useState<boolean>(false);
  const [refreshTransactionLoading, setRefreshTransactionLoading] = useState<boolean>(false);
  const [lowCostBookingLoading, setLowCostBookingLoading] = useState<boolean>(false);
  const [lowCostBookingError, setLowCostBookingError] = useState<string | null>(null);
  const { amaClientRef } = useParams();
  const { isOfferAvailable, currentPrice, currentOffersState } = useConfirmOfferPriceAndAvailability({
    amaClientRef,
    needToCheck: !lowCostBookingLoading && !lowCostBookingError && !paymentError,
    hasAccess,
    offers,
  });
  const [isAcceptingNewPriceLoading, setIsAcceptingNewPriceLoading] = useState<boolean>(false);

  const redirectToSearchPage = () => {
    navigate('/');
  };
  
  const redirectToBookingSuccess = () => {
    navigate(`/${FLIGHT_BOOKING_STEP.SUCCESS}/${amaClientRef}`);
  };

  const redirectToChangePaymentMethodPage = () => {
    navigate(`/${FLIGHT_BOOKING_STEP.PAYMENT_CHANGE}/${amaClientRef}`);
  };

  const proceedAfterSuccessfulPayment = ({
    isLowCostBooking,
    transactionKey,
    initial,
    trips,
  }: {
    isLowCostBooking: boolean,
    transactionKey: string,
    initial: boolean,
    trips: any[]
  }) => {
    handleSuccessfulPayment({
      isLowCostBooking,
      transactionKey,
      amaClientRef,
      initial,
      trips,
      setLowCostBookingLoading,
      redirectToBookingSuccess,
      setLowCostBookingError,
    });
  };

  const handleRefreshTransaction = () => {
    return refreshOrder({
      amaClientRef,
      proceedAfterSuccessfulPayment,
      setRefreshTransactionLoading,
      setPaymentDetails,
      setPaymentError,
      setIsLoading,
      setCanRetry,
      setOffers,
      setOrder,
    });
  };

  const handleFetchOrder = (initial: boolean) => {
    return fetchOrder({
      amaClientRef,
      initial,
      proceedAfterSuccessfulPayment,
      setPaymentDetails,
      setPaymentError,
      setIsLoading,
      setCanRetry,
      setOffers,
      setOrder,
    });
  };

  useEffect(() => {
    if (location?.state?.resetToInitial) {
      resetToInitial();
    }
  }, [location?.state?.resetToInitial]);

  useEffect(() => {
    if (hasAccess) {
      handleFetchOrder(true);
    }

    return () => {
      resetToInitial();
    };
  }, [hasAccess]);

  useEffect(() => {
    if (!isOfferAvailable) {
      setLowCostBookingError('The flights are no longer available');
    }
  }, [isOfferAvailable]);

  useEffect(() => {
    if (currentPrice && offers?.length && currentPrice !== calculateFlightTotalCost(offers)) {
      setIsPriceChangedModalOpen(true);
    }
  }, [currentPrice]);

  return (
    <FlightBookingPageWrapper
      isMobile={isMobile}
      page={'flight-booking-card-payment'}
    >
      {(lowCostBookingLoading || lowCostBookingError) && (
        <FlightBookingLoader
          bookingError={lowCostBookingError}
          offers={offers}
        />
      )}

      {!lowCostBookingLoading && !lowCostBookingError && (
        <CreditCardPaymentCheckout
          isRefreshLoading={refreshTransactionLoading}
          paymentDetails={paymentDetails}
          paymentError={paymentError}
          canRetry={canRetry}
          isLoading={isLoading}
          order={order}
          onPaymentMethodChange={redirectToChangePaymentMethodPage}
          setPaymentDetails={setPaymentDetails}
          setPaymentError={setPaymentError}
          setCanRetry={setCanRetry}
          onRefresh={handleRefreshTransaction}
          onSuccess={() => handleFetchOrder(false)}
        />
      )}

      {isPriceChangedModalOpen && (
        <FlightBookingPriceChangedDialog
          isLoading={isAcceptingNewPriceLoading}
          oldPrice={calculateFlightTotalCost(offers)}
          newPrice={currentPrice}
          onCancel={redirectToSearchPage}
          onAgree={() => handleAcceptNewPriceAndContinue({
            amaClientRef,
            offers: currentOffersState,
            setIsLoading: setIsAcceptingNewPriceLoading,
          })}
        />
      )}
    </FlightBookingPageWrapper>
  );
};

export default FlightBookingCardPayment;
