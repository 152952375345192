import { mergeClassnames } from '@heathmont/moon-core-tw';

export const outputCN = ({ start, middle, end }: { start: boolean, middle: boolean, end: boolean }) => mergeClassnames([
  'range-output',
  'hidden',
  'text-moon-12',
  'font-medium',
  'top-[4px]',
  'text-goku',
  'absolute',
  'px-[6px]',
  'py-[4px]',
  'bg-piccolo',
  'rounded-[6px]',
  start && 'translate-x-[0%]',
  middle && '-translate-x-1/2',
  end && '-translate-x-full',
]);

export const rangeCN = mergeClassnames([
  'w-full',
  'appearance-none',
  'cursor-pointer',
  'outline-[none]',
  'rounded-[16px]',

  '[&:hover+.range-output]:block',
  '[&:focus+.range-output]:block',

  '[&::-webkit-slider-runnable-track]:h-[6px]',
  '[&::-webkit-slider-runnable-track]:bg-[linear-gradient(to_right,_#275ADB_var(--rangeSelectorPosition),_#ECECEC_var(--rangeSelectorPosition))]',
  '[&::-webkit-slider-runnable-track]:rounded-[6px]',

  '[&::-moz-range-track]:h-[6px]',
  '[&::-moz-range-track]:bg-[linear-gradient(to_right,_#275ADB_var(--rangeSelectorPosition),_#ECECEC_var(--rangeSelectorPosition))]',
  '[&::-moz-range-track]:rounded-[6px]',

  '[&::-webkit-slider-thumb]:appearance-none',
  '[&::-webkit-slider-thumb]:h-[24px]',
  '[&::-webkit-slider-thumb]:w-[24px]',
  '[&::-webkit-slider-thumb]:-mt-[10px]',
  '[&::-webkit-slider-thumb]:bg-piccolo',
  '[&::-webkit-slider-thumb]:rounded-[50%]',
  '[&::-webkit-slider-thumb]:[box-shadow:inset_0px_0px_0px_4px_#ffffff]',

  '[&::-moz-range-thumb]:h-[24px]',
  '[&::-moz-range-thumb]:w-[24px]',
  '[&::-moz-range-thumb]:-mt-[10px]',
  '[&::-moz-range-thumb]:bg-piccolo',
  '[&::-moz-range-thumb]:rounded-[50%]',
  '[&::-moz-range-thumb]:[box-shadow:inset_0px_0px_0px_4px_#ffffff]',
]);
