import { configureStore, Tuple } from '@reduxjs/toolkit'
import { thunk } from 'redux-thunk';
import rootReducer from '../store';

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger');

  middlewares.push(logger);
}

const store = configureStore({
  devTools: true,
  reducer: rootReducer,
  middleware: () => new Tuple(...middlewares),
});

export { store };
