export enum CryptoTransactionStatus {
  NEW = 'N',
  UNCONFIRMED_TRANSACTION = 'U',
  FULL = 'F',
  INCOMPLETE = 'I',
  GREATER_AMOUNT = 'G',
  PROCESSING = 'P',
  SENT_OUT = 'S',
  COMPLETED = 'C',
  RETURNED_OR_REVISION_NEEDED = 'R',
  CANCELLED = 'X',
  ARCHIVED = 'Z',
};

export enum CryptoTransactionStatusLabel {
  N = 'NEW', 
  U = 'UNCONFIRMED',
  F = 'FULL',
  I = 'INCOMPLETE',
  G = 'GREATER AMOUNT',
  P = 'PROCESSING',
  S = 'SENT OUT',
  C = 'COMPLETED',
  R = 'RETURNED',
  X = 'CANCELLED',
  Z = 'ARCHIVED',
};

export enum CryptoTransactionStatusDescription {
  N = 'Awaiting transaction from wallet',
  U = 'We have detected transaction in blockchain and we are waiting for transaction to be confirmed',
  F = 'We have received correct amount of crypto',
  G = 'We have received greater amount of crypto',
  I = 'We have received smaller amount of crypto',
  P = 'Payment is being processed',
  S = 'Payment is sent out to payment processor',
  C = 'Payment processor has processed the payment',
  R = 'There was an issue processing the payment by the payment processor. We\'ll contact you promptly about the matter',
  X = 'We have not received any coins in 30 minutes',
  Z = 'Transaction is complete and archived',
};

export interface CryptoTransaction {
  transaction_key: string;
  status: CryptoTransactionStatus;
  status_time: Date;
  created_time: Date;
  coin: string;
  sum_eur: number;
  total_eur: number;
  fees_eur: number;
  bank_fees_eur: number;
  rate: number;
  description: string;
  qrc_image_url: string;
  qrc_image_url_basic: string;
  payment_url: string;
  total_coin: number;
  fees_coin: number;
  bank_fees_coin: number;
  received_coin: number;
  missing_coin: number;
  coin_address: string;
};
