import { mergeClassnames } from '@heathmont/moon-core-tw';

export const numberWrapperCN = mergeClassnames([
  'flex',
  'justify-center',
  'items-center',
  'flex-col',
  'gap-[5px]',
]);

export const numberContainerCN = mergeClassnames([
  'flex',
  'justify-center',
  'items-center',
  'text-popo',
  'border',
  'border-beerus',
  'w-[36px]',
  'h-[40px]',
  'rounded-[8px]',
  'text-moon-16',
  'font-normal',
  'text-center',
]);

export const numberLabelCN = mergeClassnames([
  'text-[9px]',
  'leading-[16px]',
  'tracking-[1.5px]',
  'font-normal',
  'text-center',
]);

export const colonCN = mergeClassnames([
  'flex',
  'justify-center',
  'items-center',
  'flex-col',
  'h-[40px]',
  'gap-[12px]',
]);

export const colonItemCN = mergeClassnames([
  'w-[2px]',
  'h-[2px]',
  'bg-bulma',
  'rounded-[12px]',
]);
