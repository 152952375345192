import { IFlightBookingFilterOptions } from './types';
import { ReactComponent as RadioUncheckedSvg } from '../../static/icons/radio-unchecked.svg';
import { ReactComponent as RadioCheckedSvg } from '../../static/icons/radio-checked.svg'
import { ReactComponent as CheckedSvg } from '../../static/icons/checked-icon.svg';
import {
  checkboxUncheckedCN,
  checkboxCheckedCN,
  optionCN,
} from './styles';
import { IOption } from '../FlightBookingPassengerAndClassFilter/types';
import { useState } from 'react';
import { mergeClassnames } from '@heathmont/moon-core-tw';
import { btnReset } from '../../styles/buttons';

const MAX_ITEM_TO_SHOW = 3;

const FlightBookingFilterOptions: React.FC<IFlightBookingFilterOptions> = ({
  selectedOption,
  options,
  isMulti,
  label,
  renderLabel,
  onChange,
}) => {
  const [showAll, setShowAll] = useState(false);
  const handleChange = (option: IOption) => {
    if (isMulti && Array.isArray(selectedOption)) {
      const selectedOptions = selectedOption.some(({ value }) => value === option?.value)
        ? selectedOption.filter(({ value }) => value !== option?.value)
        : [ ...selectedOption, option ];

      return onChange(selectedOptions);
    }
    
    return onChange(option);
  };

  const handleResetAll = () => {
    return onChange([]);
  };

  const handleSelectAll = () => {
    return onChange(options);
  };

  const isOptionChecked = (option: IOption): boolean => {
    if (isMulti && Array.isArray(selectedOption)) {
      return selectedOption.some(({ value }) => value === option?.value);
    } else if (!Array.isArray(selectedOption)) {
      return selectedOption?.value === option?.value;
    } 
    
    return false;
  };

  if (!options?.length) {
    return null;
  }

  const optionsToShow = options.slice(0, showAll || !isMulti ? options.length : MAX_ITEM_TO_SHOW);

  return (
    <div className='w-full'>
      {(label || isMulti) && (
        <span className='w-full flex mb-[10px] items-center justify-between'>
          {label && <span className='font-medium text-moon-16 text-trunks block'>{label}</span>}
          {isMulti && Array.isArray(selectedOption) && (
            <span
              className={mergeClassnames('font-medium text-moon-12 text-trunks p-0 m-0 mr-[12px] flex items-center', btnReset)}
              onClick={selectedOption.length ? handleResetAll : handleSelectAll}
            >
              {selectedOption.length ? 'Reset all' : 'Select all'}
            </span>
          )}
        </span>
      )} 
       <div>
        {optionsToShow.map((option, index) => (
          <div
            className={optionCN({ firstOption: index === 0, lastOption: index + 1 === optionsToShow.length })}
            key={option.value}
            onClick={() => handleChange(option)}
          >
            <div className='font-normal text-moon-16 max-tablet:text-moon-12'>
              {renderLabel ? (
                renderLabel(option)
              ): (
                option.label
              )}
            </div>
            {isOptionChecked(option) ? (
              isMulti ? (
                <span className={checkboxCheckedCN}>
                  <CheckedSvg />
                </span>
              ) : (
                <RadioCheckedSvg />
              )
            ) : (
              isMulti ? (
                <span className={checkboxUncheckedCN} />
              ) : (
                <RadioUncheckedSvg />
              )
            )}
          </div>
        ))}
      </div>
      {options.length > MAX_ITEM_TO_SHOW && !showAll && isMulti && (
        <div className='flex px-[16px] py-[12px] w-full items-center min-h-[32px] justify-center mt-[12px]'>
          <div
            className={mergeClassnames('text-moon-12 text-center text-piccolo', btnReset)}
            onClick={() => setShowAll(true)}
          >
            See all
          </div>
        </div>
      )}
    </div>
  );
};

export default FlightBookingFilterOptions;
