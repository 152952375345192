import { mergeClassnames } from '@heathmont/moon-core-tw';
import { textEllipsisCN } from '../../styles/texts';

export const appliedFiltersWrapperCN = (disabled: boolean) => mergeClassnames([
  'flex',
  'items-center',
  'gap-[2.5px]',
  'w-full',
  'max-tablet:gap-[10px]',
  'max-tablet:flex-wrap',
  disabled && '!pointer-events-none',
]);

export const filterChipsContainerCN = mergeClassnames([
  'flex',
  'items-center',
  'overflow-x-auto',
  'w-[calc(100%-200px-2.5px)]',
  '[scrollbar-width:none]',
  'gap-[2.5px]',
  '[&::-webkit-slider-thumb]:hidden',
  '[&::-webkit-slider-thumb]:w-[0]',
  '[&::-webkit-slider-thumb]:h-[0]',
]);

export const filterChipCN = mergeClassnames([
  textEllipsisCN,
  'flex-shrink-0',
  'bg-goku',
  'rounded-full',
  'px-[16px]',
  'py-[8px]',
  'text-moon-14',
  'h-[40px]',
  'flex',
  'items-center',
  'justify-between',
  'max-tablet:w-[calc(50%-5px)]',
  'max-mobile:w-full',
]);

export const filterChipClearButtonCN = mergeClassnames([
  'flex',
  'justify-center',
  'items-center',
  'w-[24px]',
  'h-[24px]',
  'rounded-full',
  'cursor-pointer',
  'bg-gohan',
  'ml-[10px]',
  '-mr-[5px]',
  'hover:bg-[#d7dce9]',
]);
