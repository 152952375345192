import { connect } from 'react-redux';
import CryptoPaymentCheckout from './CryptoPaymentCheckout.ui';

const mapStateToProps = ({
  eventLinkReducer,
}) => {
  return {
    hasAccess: !!(eventLinkReducer.confirmed && eventLinkReducer.eventLink),
  };
};

export default connect(mapStateToProps, null)(CryptoPaymentCheckout);
