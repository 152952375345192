import { connect } from 'react-redux';
import FlightBookingOverview from './FlightBookingOverview.ui';
import {
  setIsTermsAndConditionsOpen,
  setIsMoreFareRulesModalOpen,
} from '../../store/flightBookingsDialogs/actions';

const mapStateToProps = ({
  flightBookingsDialogsReducer,
  flightBookingsReducer,
  eventLinkReducer,
}) => {
  return {
    isMoreFareRulesModalOpen: flightBookingsDialogsReducer.isMoreFareRulesModalOpen,
    isTermsAndConditionsOpen: flightBookingsDialogsReducer.isTermsAndConditionsOpen,
    fareRulesOfferId: flightBookingsDialogsReducer.fareRulesOfferId,
    selectedOffers: flightBookingsReducer.selectedOffers,
    amaClientRef: flightBookingsReducer.amaClientRef,
    hasAccess: !!(eventLinkReducer.confirmed && eventLinkReducer.eventLink),
    travelers: flightBookingsReducer.passengers,
  };
};

const mapDispatchToProps = {
  setIsMoreFareRulesModalOpen,
  setIsTermsAndConditionsOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingOverview);
