import moment from 'moment';

export const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatValueToSearchParams = (values: any): object => {
  return {    
    cabin: values.cabin,
    passengers: values.passengers,
    destination: values?.destination?.code,
    origin: values?.origin?.code,
    departureDate: values.departureDate ? formatDate(values.departureDate) : null,
    returnDate: values.returnDate ? formatDate(values.returnDate) : null,
  };
};
