import { ICoinLogo } from './types';

import { ReactComponent as BTCSvg } from '../../static/coins/BTC.svg';
import { ReactComponent as ETHSvg } from '../../static/coins/ETH.svg';
import { ReactComponent as TRXSvg } from '../../static/coins/TRX.svg';
import { ReactComponent as DAISvg } from '../../static/coins/DAI.svg';
import { ReactComponent as DASHSvg } from '../../static/coins/DASH.svg';
import { ReactComponent as BUSDSvg } from '../../static/coins/BUSD.svg';
import { ReactComponent as BNBSvg } from '../../static/coins/BNB.svg';
import { ReactComponent as USDCSvg } from '../../static/coins/USDC.svg';
import { ReactComponent as BCHSvg } from '../../static/coins/BCH.svg';
import { ReactComponent as LTCSvg } from '../../static/coins/LTC.svg';
import { ReactComponent as USDTERC20Svg } from '../../static/coins/USDT.ERC20.svg';
import { ReactComponent as USDTTRC20Svg } from '../../static/coins/USDT.TRC20.svg';
import { ReactComponent as USDTBEP20Svg } from '../../static/coins/USDT.BEP20.svg';

const coinsLogos = {
  BTC: <BTCSvg />,
  ETH: <ETHSvg />,
  TRX: <TRXSvg />,
  DAI: <DAISvg />,
  DASH: <DASHSvg />,
  BUSD: <BUSDSvg />,
  BNB: <BNBSvg />,
  USDC: <USDCSvg />,
  BCH: <BCHSvg />,
  LTC: <LTCSvg />,
  'USDT.ERC20': <USDTERC20Svg />,
  'USDT.TRC20': <USDTTRC20Svg />,
  'USDT.BEP20': <USDTBEP20Svg />,
};

const CoinLogo: React.FC<ICoinLogo> = ({
  coin,
}) => {
  return coinsLogos[coin];
};

export default CoinLogo;
