import moment from 'moment';
import _ from 'lodash';
import request from '../utils/request';
import { extractErrorFromResponse } from '../pages/FlightBookingPayment/utils';
import { showToast } from './common';

export const getFlightEndDate = (flightOrders = []) => {
  const finishDates = [];

  flightOrders.forEach(flightOrder => {
    const lastItinerary = flightOrder.itineraries[flightOrder.itineraries.length - 1];
    const lastSegment = lastItinerary.segments[lastItinerary.segments.length - 1];
    finishDates.push(moment(lastSegment.arrival.at));
  });
  
  return moment.max(finishDates).toDate();
};

export const handleAcceptNewPriceAndContinue = async ({
  amaClientRef,
  offers,
  setIsLoading,
}: {
  amaClientRef: string;
  offers: any[];
  setIsLoading: (isLoading) => void;
}) => {
  try {
    setIsLoading(true);
    await request.post(`white-label/flights/offers/refresh/${amaClientRef}`, { offers });
    setIsLoading(false);
    window.location.reload();
  } catch (err) {
    setIsLoading(false);
    showToast(extractErrorFromResponse(err));
  }
};
