import uniq from 'lodash/uniq';

export const getFlightReference = (associatedRecords) => {
  const flightReferences = [];

  associatedRecords.forEach((associatedRecord) => {
    if (associatedRecord.reference !== 'PENDING') {
      flightReferences.push(associatedRecord.reference);
    }
  });

  return uniq(flightReferences).join('/');
};
