import { mergeClassnames } from '@heathmont/moon-core-tw';

export const buttonsWrapperCN = (isMobile: boolean) => mergeClassnames([
  'flex',
  'fixed',
  'bottom-[20px]',
  'ml-[var(--scrollbar-width)]',
  'z-10',
  isMobile
    ? 'items-center w-[calc(100vw-40px)] left-[20px] gap-[20px]'
    : 'w-[586px] left-[50vw] -translate-x-1/2'
]);

