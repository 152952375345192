import { mergeClassnames } from '@heathmont/moon-core-tw';

export const wrapperCN = mergeClassnames([
  'w-full',
  'flex',
  'flex-col',
  'justify-center',
  'gap-[10px]',
  'bg-goku',
  'rounded-[12px]',
  'px-[48px]',
  'py-[32px]',
  'max-tablet:p-[24px]',
]);

export const textCN = (small: boolean) => mergeClassnames([
  'p-0',
  'm-0',
  'font-medium',
  'text-trunks',
  'text-[18px]',
  'leading-[22px]',
  'font-[monospace]',
  'h-[22px]',
  'w-[fit-content]',
  '[clip-path:inset(0_3ch_0_0)]',
  'animate-[textLoading_1s_steps(4)_infinite]',
  'block',
  small && 'text-[12px] leading-[14px] h-[14px]',
  'max-tablet:text-[12px] max-tablet:leading-[14px] max-tablet:h-[14px]',
  'max-mobile:text-[8px] max-mobile:leading-[10px] max-mobile:h-[10px]',
  'before:content-[attr(loading-text-attr)]'
]);


export const lineCN = mergeClassnames([
  'mt-[20px]',
  'w-full',
  'relative',
  'h-[3px]',
  'bg-beerus',
]);

export const lineProgressCN = (end: boolean) => mergeClassnames([
  'absolute',
  'top-[0]',
  'left-[0]',
  'h-[3px]',
  'bg-piccolo',
  end 
    ? 'w-[calc(100%-32.5px)] [transition:width_5s_linear]'
    : 'w-[0] transition-none',
]);

export const flightIconCN = (end: boolean) => mergeClassnames([
  'absolute',
  'top-2/4',
  '-translate-y-1/2',
  end
    ? 'left-[calc(100%-32.5px)] [transition:left_5s_linear]'
    : 'left-[0] transition-none'
]);
