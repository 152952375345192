import { mergeClassnames } from '@heathmont/moon-core-tw';

export const fieldWrapperCN = mergeClassnames([
  'w-full',
  'flex',
  'flex-col',
]);

export const InputCardNumberInputStyledDefault = {
  color: '#181B1C',
  fontWeight: '400',
  '::-webkit-input-placeholder': {
    color: '#A5ADBC',
  }
};

export const InputCardNumberInputStyledInvalid = {
  color: '#181B1C',
  fontWeight: '400',
  '::-webkit-input-placeholder': {
    color: '#A5ADBC',
  }
};
