import { connect } from 'react-redux';
import {
  getAccessToFlightBookingSearch,
  setEventLinkExpired,
  getEventLink,
} from '../../store/eventLink/actions';
import Component from './index.ui';

const mapDispatchToProps = {
  getAccessToFlightBookingSearch,
  setEventLinkExpired,
  getEventLink,
};

export default connect(null, mapDispatchToProps)(Component);
