export enum CreditCardOrderStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  AUTHORISED = 'authorised',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
};
export enum CreditCardOrderStatusLabel {
  pending = 'PENDING', 
  processing = 'PROCESSING',
  authorised = 'AUTHORISED',
  completed = 'COMPLETED',
  cancelled = 'CANCELLED',
  failed = 'FAILED',
};

export enum CreditCardOrderStatusDescription {
  pending = 'We are waiting payment from card',
  processing = 'We have detected payment and we are waiting for payment to be completed',
  authorised = 'We have received approval from the bank that issued the payment card',
  completed = 'Payment is complete and archived',
  cancelled = 'Payment is cancelled',
  failed = 'Payment is failed',
};

export interface CreditCardOrderShippingAddress {
  street_line_1: string;
  country_code: string;
  postcode: string;
  region?: string; 
  city: string;
};

export interface CreditCardOrderCustomer {
  full_name: string;
  email: string;
  id: string;
};

export interface CreditCarPaymentMethod {
  cardholder_name: string;
  card_last_four: string;
  card_expiry: string;
};

export interface CreditCarPayment {
  payment_method: CreditCarPaymentMethod;
  created_at: Date;
  updated_at: Date;
  currency: string;
  amount: number;
};

export interface CreditCardOrder {
  id: string;
  token: string;
  state: CreditCardOrderStatus;
  created_at: Date;
  updated_at: Date;
  amount: number;
  currency: string;
  outstanding_amount: number;
  capture_mode: 'automatic' | 'manual';
  enforce_challenge: 'automatic' | 'forced';
  description: string;
  checkout_url: string;
  shipping_address: CreditCardOrderShippingAddress;
  customer: CreditCardOrderCustomer;
  payments: CreditCarPayment[];
};
