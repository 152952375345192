import { ReactComponent as IconMinusSvg } from '../../static/icons/icon-minus.svg';
import { ReactComponent as IconPlusSvg } from '../../static/icons/icon-plus.svg';
import { IQuantityNumber } from './types';
import { quantityNumberCN } from './styles';

const QuantityNumber: React.FC<IQuantityNumber> = ({
  value,
  min,
  max,
  onChange,
}) => {
  const handleMinus = () => {
    const newValue = value - 1;

    if (newValue >= min) {
      onChange(newValue);
    }
  };

  const handlePlus = () => {
    const newValue = value + 1;

    if (newValue <= max) {
      onChange(newValue);
    }
  };

  return (
    <div className='flex gap-[10px] items-center'>
      <div className={quantityNumberCN(value <= min)} onClick={handleMinus}>
        <IconMinusSvg />
      </div>
      {<div className='text-moon-16 text-popo'>{value}</div>}
      <div className={quantityNumberCN(value >= max)} onClick={handlePlus}>
        <IconPlusSvg />
      </div>
    </div>
  );
};

export default QuantityNumber;
