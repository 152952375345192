import { useEffect, useMemo, useState } from 'react';
import { GenericAlarmRound, SecurityQrCode, FilesCopy } from '@heathmont/moon-icons-tw';
import { Button, InsetInput, mergeClassnames } from '@heathmont/moon-core-tw';
import { ICryptoPaymentCheckout } from './types';
import {
  noteWrapperCN,
  qrCodeWrapper,
  noteTextCN,
  btnInputCN,
  iconCN,
} from './styles';
import {
  formatTransactionRaws,
  isPendingTransaction,
  extractQrCodeUrl,
} from './utils';
import useIsMobile from '../../hooks/useIsMobile';
import { CryptoTransactionStatus, CryptoTransactionStatusLabel } from '../../enums/CryptoTransaction';
import TransactionDetails from '../TransactionDetails';
import { ReactComponent as SwapinLogoSvg } from '../../static/icons/swapin-logo.svg';
import { ReactComponent as ScanIconSvg } from '../../static/icons/scan-icon.svg';
import { showToast } from '../../utils/common';
import Timer from '../Timer';
import moment from 'moment';
import PaymentCheckout from '../PaymentCheckout';
import { isWaitingForCryptoPaymentStatus } from '../../utils/payments';

const MAX_TIME_FOR_PAYMENT_IN_MINUTES = 30;
const FREQUENCY_OF_CHECKING_TRANSACTION_STATUS_IN_SECONDS = 15 * 1000;

const CryptoPaymentCheckout: React.FC<ICryptoPaymentCheckout> = ({
  needToRefetchTransaction,
  isRefreshLoading,
  paymentDetails,
  paymentError,
  transaction,
  isLoading,
  hasAccess,
  canRetry,
  onPaymentMethodChange,
  setPaymentDetails,
  fetchTransaction,
  setPaymentError,
  setCanRetry,
  onRefresh,
}) => {
  const isMobile = useIsMobile();
  const [isQrCodeVisibleOnMobile, setIsQrCodeVisibleOnMobile] = useState<boolean>(false);

  const deadline = useMemo(() => {
    return moment(transaction?.status_time, 'YYYY-MM-DD HH:mm:ss Z').add(MAX_TIME_FOR_PAYMENT_IN_MINUTES, 'minutes').toDate();
  }, [transaction?.status_time]);

  const copyAddressToClipboard = () => {
    showToast('Address copied', 'success');
    navigator.clipboard.writeText(transaction?.coin_address);
  };

  const toggleQrCode = () => {
    setIsQrCodeVisibleOnMobile(prevValue => !prevValue);
  };

  const onTimerFinish = () => {
    setPaymentDetails('The time for payment is over. You can try again, but the exchange rate can be different');
    setPaymentError(true);
    setCanRetry(true);
  };

  const getTransactionDetails = () => {
    return transaction?.status === CryptoTransactionStatus.INCOMPLETE
      ? `${paymentDetails}. Missing amount - ${transaction.missing_coin} ${transaction.coin}`
      : paymentDetails;
  };

  useEffect(() => {
    let interval;
  
    if (hasAccess) {
      interval = setInterval(() => {
        if (needToRefetchTransaction) {
          fetchTransaction();
        }
      }, FREQUENCY_OF_CHECKING_TRANSACTION_STATUS_IN_SECONDS);
    }

    return () => {
      clearInterval(interval);
    };
  }, [hasAccess, needToRefetchTransaction]);

  return (
    <PaymentCheckout
      isTryAgainLoading={isRefreshLoading}
      paymentDetails={getTransactionDetails()}
      poweredByIcon={(<SwapinLogoSvg />)}
      paymentError={paymentError}
      statusLabel={CryptoTransactionStatusLabel[transaction?.status]}
      isLoading={isLoading}
      isPending={isPendingTransaction(transaction)}
      canRetry={canRetry}
      onPaymentMethodChange={onPaymentMethodChange}
      handleTryAgain={onRefresh}
    >
      {
        !isLoading && transaction && (
          <>
            {
              isWaitingForCryptoPaymentStatus(transaction.status) && (
                <div className='flex justify-center items-center flex-col gap-[10px]'>
                  <Timer deadline={deadline} onTimerFinish={onTimerFinish} />
                  <div className={`${noteWrapperCN} justify-center`}>
                    <GenericAlarmRound className={iconCN} />
                    <span className={noteTextCN}>
                      You will have {MAX_TIME_FOR_PAYMENT_IN_MINUTES} mins to make deposit
                    </span>
                  </div>
                </div>
              )
            }
            <div className={qrCodeWrapper(!isMobile || isQrCodeVisibleOnMobile)}>
              <img
                className='max-w-[175px]'
                src={extractQrCodeUrl(transaction)}
                alt={transaction?.coin_address}
              />
              <span className={noteTextCN}>
                1 {transaction.coin} ≈ €{transaction.rate.toLocaleString()}
              </span>
              <span className={noteTextCN}>
                <ScanIconSvg />
                Scan with your wallet
              </span>
            </div>
            <div className='relative w-full'>
              <InsetInput
                className='rounded-moon-i-md [&_input]:rounded-moon-i-md [&_input]:pr-[100px] [&_input]:whitespace-nowrap [&_input]:overflow-hidden [&_input]:overflow-ellipsis pointer-events-none'
                value={transaction?.coin_address}
                type='text'
                name='address'
              >
                <InsetInput.Label>Address</InsetInput.Label>
              </InsetInput>
              <div className='absolute right-[10px] top-2/4 flex gap-[7.5px]'>
                {
                  isMobile && (
                    <Button
                      className={mergeClassnames(btnInputCN, isQrCodeVisibleOnMobile && 'bg-beerus')}
                      iconOnly={<SecurityQrCode className={iconCN} />}
                      variant={'outline'}
                      size="sm"
                      onClick={toggleQrCode}
                    />
                  )
                }
                <Button
                  className={btnInputCN}
                  iconOnly={<FilesCopy className={iconCN} />}
                  variant={'outline'}
                  size="sm"
                  onClick={copyAddressToClipboard}
                />
              </div>
            </div>
          </>
        )
      }
      <TransactionDetails
        isLoading={isLoading}
        currency={transaction?.coin}
        items={formatTransactionRaws(transaction)}
      />

      {!isLoading && (
        <div className={`${noteWrapperCN} mb-[16px]`}>
          <GenericAlarmRound className={iconCN} />
          <span className={noteTextCN}>
            Additional charges may occur depending on your crypto wallet provider. Pay exact amount. Smaller amount will cause unsuccessful payment and overpaid amount will be lost.
          </span>
        </div>
      )}
    </PaymentCheckout>
  );
};

export default CryptoPaymentCheckout;
