import axios from 'axios';
import qs from 'qs';
import { setEventLinkExpired } from '../store/eventLink/actions';
import * as sessionStorageHelper from './session-storage';
import { store } from './configureStore';
import { STATUS_CODE  } from '../constants';

const request: any = {};

request.init = () => {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/v${process.env.REACT_APP_API_VERSION}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
};

request.setPartnerEventAuthorized = () => {
  axios.interceptors.request.use(
    (axiosConfig) => {
      const { origin } = new URL(axiosConfig.url, axiosConfig.baseURL);
      const allowedOrigins = [process.env.REACT_APP_API_URL];
      const token = sessionStorageHelper.get('event_token');

      if (allowedOrigins.includes(origin) && token) {
        axiosConfig.headers['x-event-token'] = token;
      }

      axiosConfig.paramsSerializer = (params) => qs.stringify(params);
      
      return axiosConfig;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use((response) => response, (error) => {
    if (error?.response?.status === STATUS_CODE.USER_UNAUTHORIZED_PARTNER_EVENT) {
      store.dispatch(setEventLinkExpired());
    }

    return Promise.reject(error);
  });
};

request.get = async (endpoint, params) =>
  axios.get(endpoint, params).then(
    (response) => response,
    (error) => {
      console.error(error.response);
      throw error;
    }
  );

request.post = async (endpoint, payload = {}, params = {}) =>
  axios.post(endpoint, payload, params).then(
    (response) => response,
    (error) => {
      throw error;
    }
  );

request.delete = async (endpoint, params = {}) =>
  axios.delete(endpoint, params).then(
    (response) => response,
    (error) => {
      throw error;
    }
  );

request.update = async (endpoint, payload = {}, params = {}) =>
  axios.put(endpoint, payload, params).then(
    (response) => response,
    (error) => {
      throw error;
    }
  );

request.extractErrors = (error) => {
  let errors = [];

  if (error?.response?.data?.error) {
    errors = error.response.data.error;
  } else if (error?.message) {
    errors = [{ message: error.message }];
  }
  
  return errors.length ? errors : null;
};

request.extractStatus = (error) => {
  return error?.response?.status;
};

export default request;
