export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
};

export enum GENDER_LABEL {
  MALE = 'Male',
  FEMALE = 'Female',
}

export const genderOptions = [
  {
    value: GENDER.MALE,
    label: GENDER_LABEL.MALE,
  },
  {
    value: GENDER.FEMALE,
    label: GENDER_LABEL.FEMALE,
  },
];
