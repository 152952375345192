import { Tooltip as MoonTooltip } from "@heathmont/moon-core-tw";
import { ITooltip } from './types';

const Tooltip: React.FC<ITooltip> = ({ label, position = 'top-center', children }) => {
  return (
    <MoonTooltip>
      <MoonTooltip.Trigger className="cursor-pointer">
        {children}
      </MoonTooltip.Trigger>
      <MoonTooltip.Content position={position}>
        {label}
        <MoonTooltip.Arrow />
      </MoonTooltip.Content>
    </MoonTooltip>
  );
};

export default Tooltip;
