import { mergeClassnames } from '@heathmont/moon-core-tw';

export const textCN = mergeClassnames([
  'text-moon-16',
  'font-normal',
  'text-bulma',
]);

export const fieldWrapperCN = mergeClassnames([
  'w-full',
  'flex', 
  'flex-col',
]);
