import { mergeClassnames } from '@heathmont/moon-core-tw';

export const inputCN = (open: boolean) => mergeClassnames([
  'rounded-moon-i-md',
    open && '[&_input]:shadow-input-focus',
    open && 'hover:[&_input]:shadow-input-focus',
    '[&_input]:h-[72px]',
    '[&_input]:rounded-moon-i-md',
    '[&_input]:cursor-pointer',
    '[&_input]:overflow-ellipsis',
  ]);

export const popoverPanelCN = mergeClassnames([
  'w-auto',
  'flex',
  'flex-col',
  'shadow-moon-none',
  'p-0',
  'max-tablet:!fixed',
  'max-tablet:!top-2/4',
  'max-tablet:!left-2/4',
  'max-tablet:!-translate-x-1/2',
  'max-tablet:!-translate-y-1/2',
  'max-tablet:!z-10',
  'max-tablet:min-h-[300px]',
  'max-tablet:max-h-[calc(100dvh-80px)]',
  'max-tablet:overflow-y-auto',
]);

export const labelCN = (hasValue: boolean) => mergeClassnames(
  hasValue ? ['tracking-[1.5px]', 'uppercase'] : []
);

    