import { useState } from 'react';
import { useFormik, FormikProvider } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { Button } from '@heathmont/moon-core-tw';
import FlightBookingPassengerAndClassFilter from '../FlightBookingPassengerAndClassFilter';
import FlightBookingPopularDestinations from '../FlightBookingPopularDestinations';
import FlightBookingDestinationFilter from '../FlightBookingDestinationsFilter';
import FlightBookingDatesRangePicker from '../FlightBookingDatesRangePicker';
import { IFlightBookingSearchForm } from './types';
import { formWrapperCN, searchButtonCN } from './styles';
import { flightBookingSearchSchema, initialValues } from './schema';
import { showToast } from '../../utils/common';

const FlightBookingSearchForm: React.FC<IFlightBookingSearchForm> = ({
  withPopularDestinations,
  destinationOptions,
  originOptions,
  searchParams,
  disabled,
  onSubmit,
}) => {
  const [popularToDestinationChanged, setPopularToDestinationChanged] = useState(false);
  const [popularToDestination, setPopularToDestination] = useState(null);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: flightBookingSearchSchema,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: searchParams || initialValues,
    onSubmit: () => {},
  });
  const { values, setFieldValue, validateForm } = formik;

  const handleSelectPopularDestination = (code: string) => {
    setPopularToDestinationChanged(!popularToDestinationChanged);
    setPopularToDestination(code)
  };

  const handleSubmit = async () => {
    const result = await validateForm();

    if (isEmpty(result)) {
      return onSubmit(values);
    } else {
      const errors = [];
      
      Object.values(result).forEach(err => {
        if (Array.isArray(err)) {
          errors.push(...err);
        } else {
          errors.push(err);
        }
      });

      errors.forEach(error => {
        showToast(error);
      });
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <div className={formWrapperCN(disabled)}>
          <FlightBookingDestinationFilter
            popularToDestinationChanged={popularToDestinationChanged}
            defaultDestinationOptions={destinationOptions}
            popularToDestination={popularToDestination}
            defaultOriginOptions={originOptions}
            destination={values.destination}
            origin={values.origin}
            setFieldValue={setFieldValue}
          />
          <FlightBookingDatesRangePicker
            departureDate={values.departureDate}
            returnDate={values.returnDate}
            setFieldValue={setFieldValue}
          />
          <FlightBookingPassengerAndClassFilter
            passengers={values.passengers}
            cabin={values.cabin}
            setFieldValue={setFieldValue}
          />
          <Button className={searchButtonCN} size='xl' onClick={handleSubmit}>
            Search
          </Button>
        </div>
        {withPopularDestinations && (
          <FlightBookingPopularDestinations onSelect={handleSelectPopularDestination} />
        )}
      </FormikProvider>
    </>
  );
};

export default FlightBookingSearchForm;
