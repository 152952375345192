import { REGION_TYPES } from './RegionTypes';

export enum DESTINATION_GROUP_TYPES {
  POPULAR_CITIES = 'popular_cities',
  SEARCH_RESULTS = 'search_results',
};

export interface DestinationOption {
  subLocation?: boolean;
  country: string;
  popular: boolean;
  image?: any;
  label: string;
  value: string;
  code: string;
  city: string;
  name: string;
  type: REGION_TYPES;
}; 

export interface DestinationsGroup {
  label: string,
  type: DESTINATION_GROUP_TYPES,
  options: DestinationOption[],
};
