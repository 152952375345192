import {
  SET_EVENT_LINK_ERROR,
  RESET_EVENT_LINK,
  SET_EVENT_LINK,
} from './actionTypes';
import * as sessionStorageHelper from '../../utils/session-storage';

const initState = {
  eventLink: null,
  confirmed: !!sessionStorageHelper.get('event_token'),
  error: null,
};

const eventLinkReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_EVENT_LINK: {
      return {
        ...state,
        eventLink: action.payload.eventLink,
        confirmed: true,
        error: null,
      };
    }

    case RESET_EVENT_LINK: {
      return {
        ...state,
        eventLink: null,
        confirmed: false,
        error: null,
      };
    }

  case SET_EVENT_LINK_ERROR: {
      return {
        ...state,
        eventLink: null,
        confirmed: false,
        error: action.payload.error,
      };
    }

    default:
      return state;
  }
};

export default eventLinkReducer;
