import { mergeClassnames } from '@heathmont/moon-core-tw';

export const itineraryOptionWrapperCN = mergeClassnames([
  'flex',
  'flex-col',
  'gap-[10px]',
  'w-full',
])

export const itineraryOptionTitleCN = mergeClassnames([
  'text-trunks',
  'font-medium',
  'text-moon-16',
]);

export const itineraryPriceDifferenceCN = (negative: boolean) => mergeClassnames([
  'flex',
  'items-center',
  'gap-[1px]',
  'text-moon-12',
  'ml-[5px]',
  'tracking-[1px]',
  'max-tablet:text-moon-10',
  negative ? 'text-roshi' : 'text-chichi',
]);
