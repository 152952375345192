import { mergeClassnames } from '@heathmont/moon-core-tw';

export const inputCN = ({
  lastSelect,
  isMobile,
  open,
}: {
  lastSelect: boolean,
  isMobile: boolean,
  open: boolean,
}) => mergeClassnames([
  'rounded-moon-i-md',
  open && '[&_input]:shadow-input-focus',
  open && 'hover:[&_input]:shadow-input-focus',
  open ? '[&_input]:cursor-text' : '[&_input]:cursor-pointer',
  !isMobile && !lastSelect && '[&_input]:pr-8',
  !isMobile && lastSelect && '[&_input]:pl-8',
  '[&_input]:h-[72px]',
  '[&_input]:rounded-moon-i-md',
  '[&_input]:overflow-ellipsis',
]);

export const labelCN = ({
  lastSelect,
  withValue,
  isMobile,
  open,
}: {
  lastSelect: boolean;
  withValue: boolean;
  isMobile: boolean;
  open: boolean;
}) => mergeClassnames([
  !isMobile && !lastSelect && 'pr-4',
  !isMobile && lastSelect && 'pl-4',
  (open || withValue) && 'tracking-[1.5px]',
  (open || withValue) && 'uppercase',
]);
