import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';

export const definePasswordModalTitle = (page: string): string => {
  if (page === FLIGHT_BOOKING_STEP.PAYMENT_CHANGE) {
    return 'To pay for flight';
  }

  if (page === FLIGHT_BOOKING_STEP.REQUEST_CHANGES) {
    return 'To request changes';
  }

  if (page === FLIGHT_BOOKING_STEP.ADDITIONAL_PAYMENT) {
    return 'To pay';
  }

  return 'To start booking';
};