import { mergeClassnames } from '@heathmont/moon-core-tw';
import { textEllipsisCN } from '../../styles/texts';

export const footerWrapperCN = mergeClassnames([
  'w-full',
  'flex',
  'flex-wrap',
  'gap-[52px]',
  'mx-[0]',
  'my-[80px]',
  'max-tablet:gap-[32px]',
  'max-tablet:mx-[0]',,
  'max-tablet:my-[60px]',
]);

export const footerSectionCN = mergeClassnames([
  'flex',
  'flex-col',
  'gap-[16px]',
  'max-tablet:w-[calc(50%-16px)]'
]);

export const footerAddressWrapperCN = mergeClassnames([
  'flex',
  'items-start',
  'w-full',
  'border-t',
  'border-beerus',
  'px-[0]',
  'py-[16px]',
  'gap-[4px]',
  '-mt-[20px]',
  'max-tablet:mt-0',
  'max-tablet:gap-0',
  'max-tablet:flex-col',
]);

export const iconCN = mergeClassnames([
  'flex-shrink-0',
  'w-[24px]',
  'h-[24px]',
]);

export const textCN = mergeClassnames([
  'font-normal',
  'text-moon-14',
  'text-trunks',
  'w-full',
  'flex',
  'items-center',
  'gap-[5px]',
  'h-[24px]',
  textEllipsisCN,
]);

export const footerAddressTextCN = mergeClassnames([
  textCN,
  'w-auto',
  'max-tablet:w-full',
]);

export const linkCN = mergeClassnames([
  textCN,
  'cursor-pointer',
  'underline',
  'hover:text-[#5b5b5b]',
  'hover:underline',
]);

