import { useMemo } from 'react';
import { ControlsCloseSmall } from '@heathmont/moon-icons-tw';
import { Button, mergeClassnames } from '@heathmont/moon-core-tw';
import { IFlightBookingAppliedFilters } from './types';
import {
  appliedFiltersWrapperCN,
  filterChipClearButtonCN,
  filterChipsContainerCN,
  filterChipCN,
} from './styles';
import { ReactComponent as FilterSvg } from '../.././static/icons/filter.svg';
import { prepareFiltersChipsData } from './utils';
import FlightBookingSorting from '../FlightBookingSorting';
import { btnChipCN } from '../../styles/buttons';

const FlightBookingAppliedFilters: React.FC<IFlightBookingAppliedFilters> = ({
  destination,
  isMobile,
  airlines,
  disabled,
  filters,
  isRound,
  origin,
  sortBy,
  openAllFilters,
  resetAirlines,
  resetFilter,
  setSortBy,
}) => {
  const chips = useMemo(() => {
    return prepareFiltersChipsData({
      destination,
      airlines,
      filters,
      isRound,
      origin,
    });
  }, [filters, airlines]);

  const renderChips = () => (
    <>
      <Button
        className={mergeClassnames('tablet:w-[115px] tablet:flex-shrink-0 mobile:w-[calc(50%-5px)] w-full', btnChipCN)}
        iconLeft={<FilterSvg />}
        onClick={openAllFilters}
      >
        All Filters
      </Button>

      {
        chips.map(chip => (
          <div className={filterChipCN} key={chip.field}>
            <span>
              {chip.label}
              {chip.labelValue && <b> {chip.labelValue}</b>}
              {chip.number && (
                <span className='text-trunks ml-[2.5px]'>({chip.number})</span>
              )}
            </span>
            
            {chip.isClearable && (
              <div className={filterChipClearButtonCN} onClick={() => {
                return chip.isAirlineFilter
                  ? resetAirlines()
                  : resetFilter(chip.field) 
              }}>
                <ControlsCloseSmall fontSize={'1.2rem'}/>
              </div>
            )}
          </div>
        ))
      }
    </>
  );

  return (
    <div className={appliedFiltersWrapperCN(disabled)}>
      {isMobile ? renderChips() : (
        <div className={filterChipsContainerCN}>
          {renderChips()}
        </div>
      )}
      
      <FlightBookingSorting
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
    </div>
  );
};

export default FlightBookingAppliedFilters;
