import { Button, mergeClassnames } from '@heathmont/moon-core-tw';
import { ReactComponent as SuccessIconSvg } from '../../static/icons/success-icon.svg';
import { ReactComponent as ErrorIconSvg } from '../../static/icons/error-icon.svg';
import {
  buttonsWrapperCN,
  subTitleCN,
  wrapperCN,
  titleCN,
  iconCN,
} from './styles';
import { IFlightBookingInfoView } from './types';
import { btnSecondaryCN } from '../../styles/buttons';

const FlightBookingInfoView: React.FC<IFlightBookingInfoView> = ({
  secondaryButtonLoading,
  secondaryButtonLabel,
  submitButtonLoading,
  submitButtonLabel,
  isSuccess = false,
  subTitle,
  loading = false,
  title,
  handleSecondaryButtonClick,
  onClick,
}) => {
  return (
    <div className={wrapperCN}>
      {isSuccess ? (
        <SuccessIconSvg className={iconCN} />
      ) : (
        <ErrorIconSvg className={iconCN} />
      )}

      {isSuccess ? (
        <span className={titleCN}>Ta-da!</span>
      ) : (
        <span className={titleCN}>Oops</span>
      )}

      {title && <span className={titleCN}>{title}</span>}

      {subTitle && <span className={subTitleCN}>{subTitle}</span>}

      <div className={buttonsWrapperCN}>
        <Button
          className='text-moon-20'
          fullWidth
          animation={submitButtonLoading ? 'progress' : undefined}
          disabled={loading || submitButtonLoading}
          size='xl'
          onClick={onClick}
        >
          {submitButtonLabel}
        </Button>
        {handleSecondaryButtonClick && (
          <Button
            className={mergeClassnames(btnSecondaryCN, 'text-moon-20')}
            fullWidth
            animation={secondaryButtonLoading ? 'progress' : undefined}
            disabled={loading || secondaryButtonLoading}
            size='xl'
            onClick={handleSecondaryButtonClick}
          >
            {secondaryButtonLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FlightBookingInfoView;
