import FlightBookingPageWrapper from '../../components/FlightBookingPageWrapper';
import useIsMobile from '../../hooks/useIsMobile';
import TermsAndConditionsContent from '../../components/TermsAndConditionsContent';
import Accordion from '../../components/Accordion';
import { pageContentCN, pageContentWrapperCN } from '../../styles/wrappers';
import { mergeClassnames } from '@heathmont/moon-core-tw';
import Footer from '../../components/Footer';
import { IFlightBookingTermsAndConditions } from './types';

const FlightBookingTermsAndConditions: React.FC<IFlightBookingTermsAndConditions> = ({
  setIsContactFormOpen,
  isContactFormOpen,
}) => {
  const isMobile = useIsMobile();

  return (
    <FlightBookingPageWrapper
      passwordRequired={false}
      isMobile={isMobile}
      page={'flight-booking-terms-and-conditions'}
    >
      <div className={mergeClassnames(pageContentWrapperCN, 'mb-0')}>
        <div className={mergeClassnames(pageContentCN, 'mb-0')}>
          <Accordion
            openByDefault
            withoutArrow
            disabled
            title='General Terms & Conditions'
          >
            <TermsAndConditionsContent />
          </Accordion>
        </div>
        <Footer
          isContactFormOpen={isContactFormOpen}
          setIsContactFormOpen={setIsContactFormOpen}
        />
      </div>
    </FlightBookingPageWrapper>
  );
};

export default FlightBookingTermsAndConditions;
