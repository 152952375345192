import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './utils/configureStore';
import App from './components/App';

import './styles.scss';

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <Suspense fallback="loading...">
      <App />
    </Suspense>
  </Provider>
);
