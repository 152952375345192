import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from '../ErrorBoundary';
import * as i18n from '../../utils/i18n';
import request from '../../utils/request';
import Routes from '../Routes';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import './App.scss';

const App = () => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    request.init();
    i18n.init();
    setIsReady(true);
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <BrowserRouter>
      <Helmet>
        <title />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Helmet>
      <ErrorBoundary>
        <Routes />
        <ToastContainer />
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
