import forOwn from 'lodash/forOwn';

export const getErrorMessages = (errors = []) => {
  const errorMessages = [];

  if (typeof errors === 'string') {
    errorMessages.push(errors);
  } else if (typeof errors === 'object') {
    forOwn(errors, (err) => {
      if (typeof err === 'string') {
        errorMessages.push(err);
      }

      if (typeof err === 'object') {
        errorMessages.push(...Object.values(err));
      }
    });
  }

  return errorMessages;
};
