import { useState, useEffect } from 'react';
import { ReactComponent as FlightSvg } from '../../static/icons/flight.svg';
import {
  lineProgressCN,
  flightIconCN,
  wrapperCN,
  lineCN,
  textCN,
} from './styles';
import { IFlightBookingInitialLoader } from './types';

const FlightBookingInitialLoader: React.FC<IFlightBookingInitialLoader> = ({
  small = false,
  texts,
}) => {
  const [progress, setProgress] = useState<{
    currentTextIndex: number,
    count: number,
    end: boolean,
  }>({
    currentTextIndex: 0,
    count: 0,
    end: false,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevState) => {
        return {
          currentTextIndex: prevState.count === 50 || prevState.count === 100
            ? prevState.currentTextIndex === texts.length - 1 ? 0 : prevState.currentTextIndex + 1
            : prevState.currentTextIndex,
          count: prevState.count === 100 ? 0 : prevState.count + 10,
          end: prevState.count !== 100,
        };
      });
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={wrapperCN}>
      <h3 className={textCN(small)} loading-text-attr={`${texts[progress.currentTextIndex]}...`} />
      <div className={lineCN}>
        <div className={lineProgressCN(progress.end)} />
        <div className={flightIconCN(progress.end)}>
          <FlightSvg className='fill-piccolo rotate-90 h-[37.5px] w-[37.5px]' />
        </div>
      </div>
    </div>
  );
};

export default FlightBookingInitialLoader;
