import { Route, Routes, Navigate } from 'react-router-dom';

import FlightBookingTermsAndConditions from '../../pages/FlightBookingTermsAndConditions';
import FlightBookingAdditionalPayment from '../../pages/FlightBookingAdditionalPayment';
import FlightBookingSuccessfulPayment from '../../pages/FlightBookingSuccessfulPayment';
import FlightBookingPrivacyAndPolicy from '../../pages/FlightBookingPrivacyAndPolicy';
import FlightBookingRequestChanges from '../../pages/FlightBookingRequestChanges';
import FlightBookingCryptoPayment from '../../pages/FlightBookingCryptoPayment';
import FlightBookingPaymentChange from '../../pages/FlightBookingPaymentChange';
import FlightBookingConfirmation from '../../pages/FlightBookingConfirmation';
import FlightBookingCardPayment from '../../pages/FlightBookingCardPayment';
import FlightBookingPassengers from '../../pages/FlightBookingPassengers';
import FlightBookingOverview from '../../pages/FlightBookingOverview';
import CryptoPaymentCheckout from '../../pages/CryptoPaymentCheckout';
import FlightBookingPayment from '../../pages/FlightBookingPayment';
import FlightBookingAboutUs from '../../pages/FlightBookingAboutUs';
import FlightBookingSearch from '../../pages/FlightBookingSearch';
import CardPaymentCheckout from '../../pages/CardPaymentCheckout';
import FlightBookingList from '../../pages/FlightBookingList';

const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<FlightBookingSearch />} />
      <Route path="/flight-booking-crypto-payment/:amaClientRef" element={<FlightBookingCryptoPayment />} />
      <Route path="/flight-booking-request-changes/:amaClientRef" element={<FlightBookingRequestChanges />} />
      <Route path="/flight-booking-payment-change/:amaClientRef" element={<FlightBookingPaymentChange />} />
      <Route path="/flight-booking-card-payment/:amaClientRef" element={<FlightBookingCardPayment />} />
      <Route path="/flight-booking-confirmation/:amaClientRef" element={<FlightBookingConfirmation />} />
      <Route path="/flight-booking-terms-and-conditions" element={<FlightBookingTermsAndConditions />} />
      <Route path="/flight-booking-successful-payment" element={<FlightBookingSuccessfulPayment />} />
      <Route path="/flight-booking-additional-payment" element={<FlightBookingAdditionalPayment />} />
      <Route path="/flight-booking-privacy-and-policy" element={<FlightBookingPrivacyAndPolicy />} />
      <Route path="/flight-booking-passengers" element={<FlightBookingPassengers />} />
      <Route path="/flight-booking-overview" element={<FlightBookingOverview />} />
      <Route path="/crypto-payment-checkout" element={<CryptoPaymentCheckout />} />
      <Route path="/flight-booking-payment" element={<FlightBookingPayment />} />
      <Route path="/card-payment-checkout" element={<CardPaymentCheckout />} />
      <Route path="/flight-booking-list" element={<FlightBookingList />} />
      <Route path="/about-us" element={<FlightBookingAboutUs />} />
      <Route element={<Navigate to="/" />} />
    </Routes>
  );
};

export default RoutesComponent;
