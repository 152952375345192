export enum BAGGAGE_LABEL_VALUE {
  OUTBOUND = 0,
  RETURN = 1,
  OUTBOUND_AND_RETURN = 2,
};

export enum BAGGAGE_LABEL {
  OUTBOUND = 'outbound',
  RETURN = 'return',
  OUTBOUND_AND_RETURN = 'outbound and return',
};
