export enum REQUEST_CHANGES_OPTION {
  CANCEL_TRIP = 'cancel_trip',
  REQUEST_CHANGES = 'request_changes',
};

export enum REQUEST_CHANGES_OPTION_LABEL {
  CANCEL_TRIP = 'Cancel trip',
  REQUEST_CHANGES = 'Request changes',
}

export const requestChangesOptions = [
  {
    value: REQUEST_CHANGES_OPTION.CANCEL_TRIP,
    label: REQUEST_CHANGES_OPTION_LABEL.CANCEL_TRIP,
  },
  {
    value: REQUEST_CHANGES_OPTION.REQUEST_CHANGES,
    label: REQUEST_CHANGES_OPTION_LABEL.REQUEST_CHANGES,
  },
];
