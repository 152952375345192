import { useState, useEffect } from 'react';
import { useFormik, FormikProvider } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { Hint, Textarea, mergeClassnames } from '@heathmont/moon-core-tw';
import { IFlightBookingFeedbackDialog } from './types';
import Modal from '../Modal';
import {
  fieldWrapperCN,
  textCN,
} from './styles';
import { initialValues, feedbackFormSchema } from './schema';
import { handleSubmitFeedback } from './utils';
import StarRatingFormField from '../StarRatingFormField';

const FlightBookingFeedbackDialog: React.FC<IFlightBookingFeedbackDialog> = ({
  tripId,
  userId,
  onClose,
}) => { 
  const [isFeedbackFormLoading, setIsFeedbackFormLoading] = useState<boolean>(false);
  const formik = useFormik({
    validationSchema: feedbackFormSchema,
    validateOnBlur: true,
    initialValues,
    onSubmit: () => {},
  });
  const { values, errors, touched, setFieldValue, setFieldTouched, validateForm } = formik; 
  
  useEffect(() => {
    validateForm();
  }, []);

  useEffect(() => {
    validateForm();
  }, [values.webAppRate]);

  return (
    <Modal
      submitButtonLabel="Share feedback"
      cancelButtonLabel="No, thank you"
      isLoading={isFeedbackFormLoading || !isEmpty(errors)}
      title="Your booking was successful"
      onSubmit={() => handleSubmitFeedback({
        submittedBy: userId,
        webAppRate: values.webAppRate,
        comment: values.comment,
        tripId,
        setIsFeedbackFormLoading,
        onClose,
      })}
      onClose={onClose}
    >
      <FormikProvider value={formik}>
        <div className='w-full flex flex-col gap-[20px]'>
          <div className='w-full flex flex-col gap-[10px]'>
            <p className={textCN}>{`
              Yolo Travel is a web app developed in-house by our own travel team
              and also a proud part of the Yolo Investments portfolio.
            `}</p>
            <p className={textCN}>{`
              Please share what you liked, what could have been better,
              and any features you think are missing to help us make a difference.
            `}</p>
          </div>

          <div className='w-full flex flex-col gap-[16px]'>
            <div className={mergeClassnames(fieldWrapperCN, 'gap-0')}>
              <div tabIndex={1} />
              <StarRatingFormField
                label='How was your experience?'
                value={values.webAppRate}
                onChange={(value) => setFieldValue('webAppRate', value)}
              />
            </div>

            <div className={fieldWrapperCN}>
              <Textarea
                placeholder='Please, share your experience during the journey'
                className="rounded-moon-i-md [&_textarea]:rounded-moon-i-md resize-y"
                error={!!(errors?.comment && touched?.comment)}
                value={values.comment}
                rows={3}
                onChange={({ target: { value } }) => setFieldValue('comment', value)}
                onBlur={() => setFieldTouched('comment')}
              />
              {!!(errors?.comment && touched?.comment) && (
                <Hint error>{errors?.comment}</Hint>
              )}
            </div>
          </div>
        </div>
      </FormikProvider>
    </Modal>
  );
};

export default FlightBookingFeedbackDialog;
