import { setFeedbackModalState } from '../../utils/feedback-modal';
import {
  SET_IS_TERMS_AND_CONDITIONS_OPEN,
  SET_IS_MORE_FARE_RULES_OPEN,
  SET_IS_FEEDBACK_FORM_OPEN,
  SET_IS_PRICE_CHANGE_OPEN,
  SET_IS_CONTACT_FORM_OPEN,
} from './actionTypes';

export const setIsTermsAndConditionsOpen = (isOpen: boolean) => ({
  payload: { isOpen },
  type: SET_IS_TERMS_AND_CONDITIONS_OPEN,
});

export const setIsMoreFareRulesModalOpen = (isOpen: boolean, offerId?: string) => ({
  payload: {
    offerId,
    isOpen,
  },
  type: SET_IS_MORE_FARE_RULES_OPEN,
});

export const setIsPriceChangedModalOpen = (isOpen: boolean) => ({
  payload: { isOpen },
  type: SET_IS_PRICE_CHANGE_OPEN,
});

export const setIsContactFormOpen = (isOpen: boolean) => ({
  payload: { isOpen },
  type: SET_IS_CONTACT_FORM_OPEN,
});

export const setIsFeedbackFormOpen = (isOpen: boolean, amaClientRef: string) => {
  setFeedbackModalState(amaClientRef, isOpen);
  return {
    payload: { isOpen },
    type: SET_IS_FEEDBACK_FORM_OPEN,
  };
};
