import moment from 'moment';
import { getCityByIataCode } from '../utils/flight-offer';

export const getDepartureCity = (offers = []) => {
  if (!offers?.length) {
    return null;
  }

  const outboundFlight = offers[0]?.itineraries[0];
  const { iataCode } = outboundFlight?.segments[0]?.departure;
  return getCityByIataCode(iataCode);
};

export const getDepartureDate = (offers: any[]) => {
  if (!offers.length) {
    return null;
  }

  const outboundFlight = offers[0]?.itineraries[0];
  const { at } = outboundFlight?.segments[0]?.departure;
  return moment.utc(at).format();
};

export const getArrivalCity = (offers = []) => {
  if (!offers?.length) {
    return null;
  }

  const outboundFlight = offers[0]?.itineraries[0];
  const { iataCode } = outboundFlight?.segments[outboundFlight.segments.length - 1]?.arrival;
  return getCityByIataCode(iataCode);
};

export const getReturnDate = (offers: any[]) => {
  if (!offers.length) {
    return null;
  }

  if (offers?.length > 1) {
    const returnFlight = offers[offers?.length - 1]?.itineraries[0];
    const { at } = returnFlight?.segments[0]?.departure;
    return moment.utc(at).format();
  }

  if (offers?.length === 1 && offers[0]?.itineraries?.length > 1) {
    const itineraries = offers[0].itineraries;
    const returnFlight = itineraries[itineraries.length - 1];
    const { at } = returnFlight?.segments[0]?.departure;
    return moment.utc(at).format();
  }

  return null;
};
