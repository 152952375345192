import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import { usePageTitle } from '../../hooks/usePageTitle';
import request from '../../utils/request';
import { IFlightBookingPageWrapper } from './types';
import {
  backgroundImageCN,
  logoWrapperCN,
  bannerCN,
  wrapperCN,
} from './styles';
import EventCodeConfirmationModal from '../EventCodeConfirmationModal';
import YoloTravelLogo from '../YoloTravelLogo';
import { detectScrollBarWidth } from './utils';

import BannerImg from '../../static/banner.png';
import SkyBackGroundImg from '../../static/sky-bg.png';

const FlightBookingPageWrapper: React.FC<IFlightBookingPageWrapper> = ({
  withBackgroundImage,
  passwordRequired = true,
  backgroundColor,
  lightLogo = false,
  hasAccess,
  isMobile,
  children,
  error,
  page,
  getAccessToFlightBookingSearch,
  setEventLinkErrorAction,
  setEventLinkExpired,
  renderBanner,
  getEventLink,
  onScroll,
}) => {
  const navigate = useNavigate();
  const pageTitle = usePageTitle(`html.${page}.title`);

  const onIdle = () => {
    setEventLinkExpired();
  };

  const { pause, start } = useIdleTimer({
    throttle: 500,
    timeout: 60_000 * +(process.env.REACT_APP_TIMEOUT_ACCESS_TO_WHITE_LABEL || 30),
    onIdle,
  });

  const backToFlightsSearch = () => {
    navigate('/');
  };

  useEffect(() => {
    if (passwordRequired) {
      request.setPartnerEventAuthorized();
      getEventLink();
    }
  }, []);

  useEffect(() => {
    if (hasAccess) {
      start();
    } else {
      pause();
    }
  }, [hasAccess]);

  useEffect(() => {
    detectScrollBarWidth();
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <main
        className={wrapperCN({ backgroundColor, hasAccess: !passwordRequired || hasAccess })}
        style={{ scrollbarGutter: !renderBanner ? 'stable both-edges' : 'auto' }}
        onScroll={onScroll}
      >
        <div className={logoWrapperCN}>
          <div className='cursor-pointer w-auto max-tablet:w-[42px]' onClick={backToFlightsSearch}>
            <YoloTravelLogo
              extended={!isMobile}
              light={lightLogo} 
            />
          </div>
        </div>

        {renderBanner && (
          <div
            className={bannerCN}
            style={{
              background: `url(${BannerImg}) no-repeat center center scroll`,
              backgroundSize: 'cover',
            }}
          >
            <Container maxWidth="lg">
              {renderBanner()}
            </Container>
          </div>
        )}

        {withBackgroundImage ? (
          <div
            className={backgroundImageCN}
            style={{
              background: `url(${SkyBackGroundImg}) no-repeat center center scroll`,
              backgroundSize: 'cover',
            }}
          >
            <Container maxWidth="lg">
              {children}
            </Container>
          </div>
        ) : (
          <Container maxWidth="lg">
            {children}
          </Container>
        )}
      </main>

      {passwordRequired && !hasAccess && (
        <EventCodeConfirmationModal
          submitError={error}
          page={page}
          resetSubmitError={() => setEventLinkErrorAction(null)}
          onSubmit={getAccessToFlightBookingSearch}
        />
      )}
    </>
  );
};

export default FlightBookingPageWrapper;
