import { mergeClassnames } from '@heathmont/moon-core-tw';

export const formWrapperCN = (disabled: boolean) => mergeClassnames([
  'w-full',
  'flex',
  'gap-[5px]',
  'max-laptop:grid',
  'max-laptop:grid-cols-[repeat(2,_1fr)]',
  'max-laptop:gap-x-[8px]',
  'max-laptop:gap-y-[8px]',
  'max-tablet:grid-cols-[1fr]',
  disabled && 'pointer-events-none',
]);

export const searchButtonCN = mergeClassnames([
  'text-moon-20',
  'font-medium',
  'h-[72px]',
  'min-w-[142px]',
  'max-mobile:h-[58px]',
  'rounded-moon-i-md', 
]);
