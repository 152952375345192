import { IFlightBookingPriceChangedDialog } from './types';
import Modal from '../Modal';

const FlightBookingPriceChangedDialog: React.FC<IFlightBookingPriceChangedDialog> = ({
  isLoading,
  oldPrice,
  newPrice,
  onCancel,
  onAgree,
}) => { 
  return (
    <Modal
      submitButtonLabel="Continue"
      cancelButtonLabel="Cancel"
      isLoading={isLoading}
      title="Price changed"
      onSubmit={onAgree}
      onCancel={onCancel}
    >
      <p className='text-moon-16 font-normal'>
        The offer(s) price was changed from <strong>{oldPrice} EUR</strong> to <strong>{newPrice} EUR</strong>. Do you want to continue?
      </p>
    </Modal>
  );
};

export default FlightBookingPriceChangedDialog;
