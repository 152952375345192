import { connect } from 'react-redux';
import CardPaymentCheckout from './CardPaymentCheckout.ui';

const mapStateToProps = ({
  eventLinkReducer,
}) => {
  return {
    hasAccess: !!(eventLinkReducer.confirmed && eventLinkReducer.eventLink),
  };
};

export default connect(mapStateToProps)(CardPaymentCheckout);
