import { mergeClassnames } from '@heathmont/moon-core-tw';
import { textEllipsisCN } from '../../styles/texts';

export const detailsCN = mergeClassnames([
  'flex',
  'w-full',
  'bg-[linear-gradient(rgba(255,_255,_255,_1),_rgba(240,_243,_251,_1))]',
  'rounded-[12px]',
  'px-[32px]',
  'py-[24px]',
  'justify-between',
  'flex-wrap',
  'border-t',
  'border-dashed',
  'border-beerus',
  'gap-[6px]',
  'max-tablet:px-[16px]',
  'max-tablet:py-[24px]',
  'max-tablet:gap-[12px]',
]);

export const detailsItemCN = ({ start, center, end }: { start?: boolean, center?: boolean, end?: boolean }) => mergeClassnames([
  'flex',
  'flex-col',
  'w-[calc(100%/3-4px)]',
  'mb-[10px]',
  'max-tablet:w-[calc(100%/3-8px)]',
  start && 'items-start',
  center && 'items-center',
  end && 'items-end',
]);

export const detailsTitleCN = mergeClassnames([
  'font-normal',
  'text-moon-14',
  'text-trunks',
]);

export const detailsValueCN = mergeClassnames([
  textEllipsisCN,
  'font-normal',
  'text-moon-20',
  'text-popo',
  'max-w-full',
  'max-tablet:text-moon-16'
]);

export const detailsReferenceCN = mergeClassnames([
  'w-full',
  'flex',
  'justify-center',
  'mt-[18px]',
  'mb-[12px]',
  'gap-[5px]',
  'max-tablet:mt-[12px]',
  'max-tablet:mb-[8px]'
]);

export const referenceCN = mergeClassnames([
  'text-popo',
  'text-moon-20',
  'max-tablet:text-moon-16',
]);
