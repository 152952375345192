import { connect } from 'react-redux';
import FlightBookingAboutUs from './FlightBookingAboutUs.ui';
import { setIsContactFormOpen } from '../../store/flightBookingsDialogs/actions';

const mapStateToProps = ({
  flightBookingsDialogsReducer,
}) => {
  return {
    isContactFormOpen: flightBookingsDialogsReducer.isContactFormOpen,
  };
};

const mapDispatchToProps = {
  setIsContactFormOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingAboutUs);
