import { Button } from '@heathmont/moon-core-tw';
import { ISelectActions } from './types';
import { btnSecondaryCN } from '../../styles/buttons';

const SelectActions: React.FC<ISelectActions> = ({
  handleCancel,
  handleSave,
}) => {
  return (
    <div className="w-full flex gap-[5px]">
      <Button className={btnSecondaryCN} fullWidth size="xl" onClick={handleCancel}>
        Cancel
      </Button>
      <Button fullWidth size="xl" onClick={handleSave}>
        Save
      </Button>
    </div>
  );
};

export default SelectActions;
