import { mergeClassnames } from '@heathmont/moon-core-tw';
import { textEllipsisCN } from '../../styles/texts';

export const wrapperCN = mergeClassnames([
  'w-full',
  'flex',
  'flex-wrap',
  'gap-[4.5px]',
  'mx-[0]',
  'my-[50px]',
  'max-mobile:my-[20px]',
]);

export const destinationCN = (isLast: boolean) => mergeClassnames([
  'rounded-[12px]',
  'px-[30px]',
  'py-[40px]',
  '[background-size:auto_100%]',
  'bg-no-repeat',
  'bg-[center_top]',
  '[transition:all_0.5s_ease-in-out]',
  'cursor-pointer',
  'h-[387px]',
  'max-laptop:h-[350px]',
  'max-laptop:px-[20px]',
  'max-laptop:py-[20px]',
  'max-mobile:h-[200px]',
  'hover:[background-size:auto_110%]',
  isLast ? 'w-[calc(50%-3px)]' : 'w-[calc(50%/2-3px)]',
  'max-laptop:w-[calc(100%/3-3px)]',
  isLast ? 'max-tablet:w-full' : 'max-tablet:w-[calc(50%-4.5px)]',
]);

export const cityTextCN = mergeClassnames([
  'text-moon-32',
  'font-bold',
  'text-goku',
  'max-laptop:text-moon-28',
  'max-tablet:text-moon-24',
  textEllipsisCN,
]);

export const countryTextCN = mergeClassnames([
  'text-moon-18',
  'font-normal',
  'text-goku',
  'max-laptop:text-moon-16',
  'max-tablet:text-moon-12',
  '[text-shadow:0_0_0.01px_black]',
  textEllipsisCN,
]);
