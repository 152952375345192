import { PAYMENT_FEE_PERCENT } from "../../constants";
import { PAYMENT_TYPE } from "../../enums/PaymentTypes";

export const getFieldBaseProps = (field) => {
  return {
    name: field, 
    id: field,
  };
};

export const getInformationAboutFee = (paymentType:PAYMENT_TYPE): string => {
  if (paymentType === PAYMENT_TYPE.CRYPTO) {
    return `Crypto payment provider fee: ${PAYMENT_FEE_PERCENT.SWAPIN.MIN}-${PAYMENT_FEE_PERCENT.SWAPIN.MAX}%. Additional charges may occur depending on your crypto wallet provider`;
  }

  return 'No additional fee';
};
