import { mergeClassnames } from '@heathmont/moon-core-tw';

export const priceWrapperCN = (top: boolean) => mergeClassnames([
  'absolute',
  'top-2/4',
  '-translate-y-1/2',
  'flex',
  'items-center',
  'gap-[10px]',
  'right-[112px]',
  'max-laptop:right-[75px]',
  'max-mobile:right-[25px]',
  top && 'max-mobile:right-[12px] max-mobile:mt-[16px]',
]);

export const priceCN = mergeClassnames([
  'text-bulma',
  'bg-goku',
  'font-medium',
  'hidden',
  'max-laptop:block',
  'max-laptop:text-[32px]',
  'max-laptop:leading-[40.83px]',
  'max-laptop:px-[10px]',
  'max-laptop:py-[5px]',
  'max-tablet:text-[24px]',
  'max-tablet:leading-[32px]',
  'max-tablet:px-[5px]',
  'max-tablet:py-[0]',
  'max-mobile:text-[18px]',
  'max-mobile:leading-[24px]',
  'max-mobile:p-0',
]); 

export const labelCN = ({
  cheapest,
  optimal,
  fastest,
}: {
  cheapest?: boolean;
  optimal?: boolean;
  fastest?: boolean;
}) => mergeClassnames([
  'text-goku',
  'uppercase',
  'rounded-[4px]',
  'px-[8px]',
  'py-[2px]',
  'text-[12px]',
  'leading-[16px]',
  'max-tablet:text-[10px]',
  'max-tablet:leading-[12x]',
  cheapest && 'bg-[#12CB67;]',
  optimal && 'bg-[#00B3FF]',
  fastest && 'bg-[#FFC800]',
]);

export const iconWrapperCN = mergeClassnames([
  'h-[32px]',
  'w-[32px]',
  'max-laptop:h-[22.5px]',
  'max-laptop:w-[22.5px]',
]);

export const iconCN = mergeClassnames([
  'h-full',
  'w-full',
  'font-bold',
]);

export const timeClockIconCN = mergeClassnames([
  iconCN,
  'text-piccolo',
]);
