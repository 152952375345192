import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import { Button } from '@heathmont/moon-core-tw';
import useIsMobile from '../../hooks/useIsMobile';
import { IDateRangePicker  } from './types';
import { closeButtonCN, dateRangeCN, returnButtonCN, returnButtonWrapperCN, wrapperCN } from './styles';

import './styles.scss';

const DateRangePicker: React.FC<IDateRangePicker> = ({
  startDate,
  endDate,
  handleChange,
  handleClose,
}) => {
  const isMobile = useIsMobile();
  const onChange = (params) => {
    const { startDate, endDate } = params.selection;
    const isStartAndEndTheSame = startDate.getTime() === endDate.getTime();

    handleChange({ startDate, endDate: isStartAndEndTheSame ? null : endDate }, false);
  
    if (!isStartAndEndTheSame) {
      setTimeout(() => {
        handleClose();
      }, 500);
    }
  };

  const onOneWayApply = () => {
    handleChange({ startDate, endDate: null }, true);
  };

  return (
    <div className={wrapperCN}>
      <DateRange
        showSelectionPreview={false}
        showMonthAndYearPickers={false}
        weekdayDisplayFormat={'EEEEEE'}
        monthDisplayFormat={'MMMM'}
        showDateDisplay={false}
        showMonthArrow={!isMobile}
        headerContent={null}
        weekStartsOn={1}
        showPreview={false}
        className={dateRangeCN(isMobile)}
        months={isMobile ? 12 : 2}
        minDate={addDays(new Date(), 1)}
        maxDate={addDays(new Date(), 365)}
        direction={isMobile ? 'vertical' : 'horizontal'}
        ranges={[{
          startDate: startDate ? new Date(startDate) : null,
          endDate: endDate ? new Date(endDate) : new Date(''),
          key: 'selection',
        }]}
        rangeColors={[endDate ? '#E2E7F4' : '#ffffff']}	
        scroll={{ enabled: !!isMobile }}
        onChange={onChange}
        onInit={onChange}
      />
      {isMobile && (
        <div className={closeButtonCN} onClick={handleClose}>
          Close
        </div>
      )}
      <div className={returnButtonWrapperCN}>
        <Button
          className={returnButtonCN}
          size='xl'
          onClick={onOneWayApply}
        >
          I don’t need a return ticket
        </Button>
      </div>
    </div>
  );
};

export default DateRangePicker;
