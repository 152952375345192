export enum REGION_TYPES {
  AIRPORT = 'Airport',
  BUS_STATION = 'Bus Station',
  CITY = 'City',
  CONTINENT = 'Continent',
  COUNTRY = 'Country',
  MULTI_CITY = 'Multi-City (Vicinity)',
  MULTI_REGION = 'Multi-Region (within a country)',
  NEIGHBORHOOD = 'Neighborhood',
  POINT_OF_INTEREST = 'Point of Interest',
  PROVINCE = 'Province (State)',
  RAILWAY_STATION = 'Railway Station',
  STREET = 'Street',
  SUBWAY = 'Subway (Entrace)',
};