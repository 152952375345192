import Skeleton from 'react-loading-skeleton';
import { IFlightItinerary } from './types';
import {  
  daysDifferenceWrapperLabelCN,
  daysDifferenceLabelCN,
  extraInfoSubTitleCN,
  flightWayStopDotCN,
  segmentDurationCN,
  extraInfoTitleCN,
  placeAndTimeCN,
  airlineLogoCN,
  flightPlaceCN,
  flightTimeCN,
  wayStopLabel,
  flightWayCN,
  itineraryCN,
  wayLabelCN,
  wayLineCN,
} from './styles';
import {
  getNoteAboutArrivalNextDay,
  getFlightDurationText,
  getNoteAboutStop,
  formatFlightDate,
} from './utils';
import Tooltip from '../Tooltip';

const FlightItinerary: React.FC<IFlightItinerary> = ({
  departure,
  isLoading,
  isReturn,
  segments,
  isOneWay,
  arrival,
  stops,
  logo,
  onClick,
}) => {
  const itineraryDate = formatFlightDate(departure, arrival);

  return (
    <div className={itineraryCN({ isLoading, isReturn, isOneWay })} onClick={onClick}>
      <div className={airlineLogoCN}>
        {isLoading ? (
          <Skeleton
            containerClassName='block w-full h-full'
            circle
            inline
            style={{ height: '100%', width: '100%' }}
          />
        ) : (
          <img className='max-w-full max-h-full' src={logo.url} alt={logo.alt} />
        )}
      </div>
      <div className='flex gap-[40px] items-center max-tablet:gap-[7.5px]'>
        <div className={placeAndTimeCN({ end: true })}>
          <span className={flightTimeCN}>
            {isLoading ? (
              <Skeleton style={{ height: '100%', width: '42px' }} inline />
            ) : (
              itineraryDate?.departureAt
            )}
          </span>
          <span className={flightPlaceCN}>
            {isLoading ? (
              <Skeleton style={{ height: '100%', width: '30px' }} inline />
            ) : (
              departure?.iataCode
            )}
          </span>
        </div>

        <div className={flightWayCN}>
          <div className={wayLabelCN({ visible: true, visibleOnlyOnMobile: true })}>
            {isLoading ? (
              <Skeleton style={{ height: '100%', width: '30px' }} inline />
            ) : (
              getFlightDurationText(departure, arrival)
            )}
          </div>
          <div className={wayLineCN}>
            <div className='w-full flex'>
              {isLoading ? (
                <div className='relative w-full'>
                  <div className={segmentDurationCN}>
                    <Skeleton style={{ height: '100%', width: '100%' }} inline />
                  </div>
                </div>
              ) : (
                segments.map(segment => {
                  const segmentDuration = getFlightDurationText(segment.departure, segment.arrival);
                  return (
                    <div
                      className='relative'
                      style={{ width: `${100 / segments.length}%` }}
                      key={segment.id}
                    >
                      <div className={segmentDurationCN}>
                        {segmentDuration}
                      </div>
                    </div>
                  )
                })
              )}
            </div>
            <div className='w-full flex'>
              {!isLoading && stops.map(stop => (
                <div
                  className='relative'
                  style={{ width: `${100 / (stops.length + 1)}%` }}
                  key={stop.code}
                >
                  <div className={flightWayStopDotCN} />
                  <div className='absolute top-[10px] right-[0] translate-x-1/2'>
                    <Tooltip label={getNoteAboutStop(stop.code, stop.duration)}>
                      <div className={wayStopLabel}>{stop.code}</div>
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={wayLabelCN({ visible: !stops?.length })}>
            {isLoading ? (
              <Skeleton style={{ height: '100%', width: '30px' }} inline />
            ) : (
              'Non-stop'
            )}
          </div>
        </div>

        <div className={placeAndTimeCN({ start: true })}>
          <span className={flightTimeCN}>
            {isLoading ? (
              <Skeleton style={{ height: '100%', width: '42px' }} inline />
            ) : (
              <>
                {itineraryDate?.arrivalAt}
                {itineraryDate?.departureArrivalDifference > 0 && (
                  <div className={daysDifferenceWrapperLabelCN}>
                    <Tooltip label={getNoteAboutArrivalNextDay(arrival.at)}>
                      <div className={daysDifferenceLabelCN}>
                        +{itineraryDate?.departureArrivalDifference}
                      </div>
                    </Tooltip>
                  </div>
                )}
              </>
            )}
          </span>
          <span className={flightPlaceCN}>
            {isLoading ? (
              <Skeleton style={{ height: '100%', width: '30px' }} inline />
            ) : (
              arrival?.iataCode
            )}
          </span>
        </div>
      </div>
      <div className='ml-[10%] flex flex-col gap-[5px] max-laptop:hidden'>
        <div className={extraInfoTitleCN}>
          {isLoading ? (
            <Skeleton style={{ height: '100%', width: '60px' }} inline />
          ) : (
            getFlightDurationText(departure, arrival)
          )}
        </div>
        <div className={extraInfoSubTitleCN}>
          {isLoading ? (
            <Skeleton style={{ height: '100%', width: '60px' }} inline />
          ) : (
            `${departure?.iataCode} - ${arrival?.iataCode}`
          )}
        </div>
      </div>
    </div>
  );
};

export default FlightItinerary;
