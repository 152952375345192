import { useMemo, useState, useEffect } from 'react'; 
import { Popover, InsetInput } from '@heathmont/moon-core-tw';
import { ControlsChevronDownSmall, ControlsChevronUpSmall } from '@heathmont/moon-icons-tw';
import { ReactComponent as RadioUncheckedSvg } from '../../static/icons/radio-unchecked.svg';
import { ReactComponent as RadioCheckedSvg } from '../../static/icons/radio-checked.svg';
import { CABIN_LABEL, cabinOptions } from '../../enums/CabinTypes';
import { IFlightBookingPassengerAndClassFilter } from './types';
import {
  optionSubTitleCN,
  optionTitleCN,
  groupLabelCN,
  optionCN,
  groupCN,
  inputCN,
  menuCN,
} from './styles';

import SelectActions from '../SelectActions';
import QuantityNumber from '../QuantityNumber';
import { MAX_NUMBER_PASSENGERS, MIN_NUMBER_PASSENGERS } from '../../constants';

const FlightBookingPassengerAndClassFilter: React.FC<IFlightBookingPassengerAndClassFilter> = ({
  passengers,
  cabin,
  setFieldValue,
}) => {
  const [passengersValue, setPassengersValue] = useState(passengers);
  const [cabinValue, setCabinValue] = useState(cabin);
  const placeholderSlot = useMemo(() => {
    return `${passengers} passenger${passengers > 1 ? 's' : ''}`
  }, [passengers, cabin]);

  const handleSave = (close) => {
    setFieldValue('cabin', cabinValue);
    setFieldValue('passengers', passengersValue);
    close();
  };

  const handleCancel = (close) => {
    setCabinValue(cabin);
    setPassengersValue(passengers);
    close();
  };

  useEffect(() => {
    setPassengersValue(passengers);
    setCabinValue(cabin);
  }, [cabin, passengers]);

  return (
    <Popover position='bottom-end'>
      {({ open }) => {
        return (
          <>
            <Popover.Trigger>
              <>
                <InsetInput
                  className={inputCN(open)}
                  value={placeholderSlot}
                  onChange={() => {}}
                >
                  <InsetInput.Label className='tracking-[1.5px]'>
                    {CABIN_LABEL[cabin].toUpperCase()}
                  </InsetInput.Label>
                </InsetInput>
                <div className='absolute right-4 top-2/4 -translate-y-1/2'>
                  {open ? (
                    <ControlsChevronUpSmall className='text-moon-24' />
                  ) : (
                    <ControlsChevronDownSmall className='text-moon-24' />
                  )}
                </div>
              </>
            </Popover.Trigger>
            <Popover.Panel className={menuCN}>
              {({ close }) => (
                <>
                  <div>
                    <div className={groupLabelCN}>Passengers</div>
                      <div className={groupCN}>
                        <div className='flex flex-col'>
                          <div className={optionTitleCN}>
                            Adults
                          </div>
                          <div className={optionSubTitleCN}>
                            18 years and older
                          </div>
                        </div>
                        <QuantityNumber
                          value={passengersValue}
                          min={MIN_NUMBER_PASSENGERS}
                          max={MAX_NUMBER_PASSENGERS}
                          onChange={(value) => setPassengersValue(value)}
                        />
                      </div>
                  </div>
                  
                  <div>
                    <div className={groupLabelCN}>Cabin class</div>
                    <div>
                      {cabinOptions.map((cabinOption, index) => (
                        <div
                          className={optionCN({ firstOption: index === 0, lastOption: index + 1 === cabinOptions.length })}
                          key={cabinOption.value}
                          onClick={() => setCabinValue(cabinOption.value)}
                        >
                          <div className='flex flex-col'>
                            <div className={optionTitleCN}>
                              {cabinOption.label}
                            </div>
                          </div>
                          {cabinOption.value === cabinValue ? (
                            <RadioCheckedSvg />
                            ) : (
                            <RadioUncheckedSvg />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <SelectActions
                    handleCancel={() => handleCancel(close)}
                    handleSave={() => handleSave(close)}
                  />
                </>
              )}
            </Popover.Panel>
          </>
        );
      }}
    </Popover>
  );
};

export default FlightBookingPassengerAndClassFilter;
