export enum PAYMENT_STATUS {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed'
};

export const paymentStatusOptions = [
  {
    value: PAYMENT_STATUS.PENDING,
    label: 'Pending',
  },
  {
    value: PAYMENT_STATUS.SUCCESS,
    label: 'Success'
  },
  {
    value: PAYMENT_STATUS.FAILED,
    label: 'Failed'
  },
];
