import { bagsOptions } from '../../enums/BagsOption';
import { numberOfStopsOptions } from '../../enums/NumberOfStopsOption';
import { durationOptions } from '../../enums/DurationOption';
import { departureTimeOptions } from '../../enums/DepartureTimeOption';

export const initFilters = (filters, destination, origin, prices) => {
  return {
    maxPrice: {
      label: 'Price',
      name: 'maxPrice',
      value: filters.maxPrice,
      min: prices[0],
      max: prices[1],
    },
    withBaggage: {
      label: 'Baggage',
      options: bagsOptions,
      value: bagsOptions.find(({ value }) => value === filters.withBaggage),
      isMulti: false,
    },
    maxNumberOfConnections: {
      label: 'Number of layovers',
      options: numberOfStopsOptions,
      value: numberOfStopsOptions.find(({ value }) => value === filters.maxNumberOfConnections),
      isMulti: false,
    },
    maxDuration: {
      label: 'Duration',
      options: durationOptions,
      value: durationOptions.find(({ value }) => value === filters.maxDuration),
      isMulti: false,
    },
    departureTimeOrigin: {
      label: origin?.name
        ? `Departure from ${origin.name}`
        : 'Departure time (origin)',
      options: departureTimeOptions,
      value: departureTimeOptions.find(({ value }) => value === filters.departureTimeOrigin),
      isMulti: false,
    },
    arrivalTimeOrigin: {
      label: destination?.name
        ? `Arrival to ${destination.name}`
        : 'Arrival time (origin)',
      options: departureTimeOptions,
      value: departureTimeOptions.find(({ value }) => value === filters.arrivalTimeOrigin),
      isMulti: false,
    },
    departureTimeReturn: {
      label: destination?.name
        ? `Departure from ${destination.name}`
        : 'Departure time (return)',
      options: departureTimeOptions,
      value: departureTimeOptions.find(({ value }) => value === filters.departureTimeReturn),
      isMulti: false,
    },
    arrivalTimeReturn: {
      label: origin?.name
        ? `Arrival to ${origin.name}`
        : 'Arrival time (return)',
      options: departureTimeOptions,
      value: departureTimeOptions.find(({ value }) => value === filters.arrivalTimeReturn),
      isMulti: false,
    },
  };
};

export const formatAirlines = (airlines = []) => {
  return airlines.map(({ code, name }) => ({
    label: name,
    value: code,
  }));
};

export const initAirlines = (airlines = []) => {
  const airlinesOptions = formatAirlines(airlines);
  return {
    label: 'Airlines',
    options: airlinesOptions,
    value: formatAirlines(airlines.filter(airline => airline.checked)),
    isMulti: true,
  };
};
