import { connect } from 'react-redux';
import FlightBookingSearch from './FlightBookingSearch.ui';
import { setEventDataToSearchParams, setSearchParams } from '../../store/flightBookings/actions';
import { setIsContactFormOpen } from '../../store/flightBookingsDialogs/actions';

const mapStateToProps = ({
  flightBookingsDialogsReducer,
  flightBookingsReducer,
  eventLinkReducer,
}) => {
  return {
    isContactFormOpen: flightBookingsDialogsReducer.isContactFormOpen,
    searchParams: flightBookingsReducer.search,
    hasAccess: !!(eventLinkReducer.confirmed && eventLinkReducer.eventLink),
    eventLink: eventLinkReducer.eventLink,
  };
};

const mapDispatchToProps = {
  setEventDataToSearchParams,
  setIsContactFormOpen,
  setSearchParams,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingSearch);
