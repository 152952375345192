import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { IPaymentFormWrapper } from './types';
import { useFormik, FormikProvider } from 'formik';
import { paymentSchema } from './schema';
import FlightBookingPaymentForm from '../FlightBookingPaymentForm';
import Accordion from '../Accordion';
import { coinsOptions } from '../../enums/Coins';
import { getOptionsOnInit, handleFetchCoins } from './utils';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { initPayment } from '../../store/flightBookings/utils';

const PaymentFormWrapper: React.FC<IPaymentFormWrapper> = ({
  isCryptoUnavailable,
  isLowCostBooking,
  passengers,
  isLoading,
  hasAccess,
  payment,
  handleSelectPaymentType,
  handleSelectPaymentCoin,
  setIsPaymentFormLoading,
  setIsCryptoUnavailable,
  setPaymentPayerDetails,
  setIsPaymentFormValid,
  setPaymentPayerType,
}) => {
  const [coins, setCoins] = useState<any[]>([]);
  const [coinsLoading, setCoinsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState({
    travelDocumentsTypes: [],
    countries: [],
    loading: true,
  });
  const source = useRef(axios.CancelToken.source());
  const formik = useFormik({
    validationSchema: paymentSchema,
    validateOnBlur: true,
    initialValues: {
      payment: initPayment({
        shouldResetPayerData: false,
        passengers,
        payment,
      }),
    },
    onSubmit: () => {},
  });
  const { values, errors, touched, setFieldValue, setValues, setFieldTouched, validateForm } = formik;

  useEffect(() => {
    if (hasAccess) {
      getOptionsOnInit(options, setOptions);
      validateForm();
    }
  }, [hasAccess]);

  useEffect(() => {
    if (hasAccess) {
      if (payment.paymentType === PAYMENT_TYPE.CRYPTO) {
        source.current = axios.CancelToken.source();
        handleFetchCoins({
          setIsCryptoUnavailable,
          setCoinsLoading,
          setCoins,
          source: source.current,
        });
      } else {
        source?.current?.cancel && source.current.cancel();
      }
    }
  }, [payment.paymentType, hasAccess]);

  useEffect(() => {
    setValues({ payment });
    validateForm({ payment });
  }, [payment.paymentType, payment.payerType, payment.coin]);

  useEffect(() => {
    setIsPaymentFormLoading(coinsLoading || options.loading);
  }, [coinsLoading, options.loading]);

  useEffect(() => {
    setIsPaymentFormValid(isEmpty(errors))
  }, [errors]);

  useEffect(() => {
    if (!isLoading) {
      setValues({
        payment: initPayment({
          shouldResetPayerData: false,
          travelDocumentsTypes: options.travelDocumentsTypes,
          passengers,
          payment,
        }),
      });
    }
  }, [isLoading])

  return (
    <Accordion
      openByDefault={!isLoading && !options.loading}
      disabled={isLoading || options.loading}
      title={(
        <>
          {isLoading || options.loading ? (
            <Skeleton style={{ height: '100%', width: '150px' }} inline />
          ): (
            <>Payment details</>
          )}
        </>
      )}
      key={`${isLoading}_${options.loading}`}
    >
      <FormikProvider value={formik}>
        <FlightBookingPaymentForm
          travelDocumentsTypes={options.travelDocumentsTypes}
          isCryptoUnavailable={isCryptoUnavailable}
          isLowCostBooking={isLowCostBooking}
          coinsLoading={coinsLoading}
          countries={options.countries}
          touched={get(touched, 'payment', {})}
          payment={values.payment}
          errors={get(errors, 'payment', {})}
          coins={coins?.length ? coins : coinsOptions}
          handleSelectPaymentType={handleSelectPaymentType}
          handleSelectPaymentCoin={handleSelectPaymentCoin}
          setPaymentPayerDetails={setPaymentPayerDetails}
          setPaymentPayerType={setPaymentPayerType}
          setFieldTouched={(field) => setFieldTouched(`payment.${field}`, true, false)}
          setFieldValue={(field, value) => setFieldValue(`payment.${field}`, value)}
        />
      </FormikProvider>
    </Accordion>
  );
};

export default PaymentFormWrapper;
