import { useEffect, useState } from 'react';
import { mergeClassnames } from '@heathmont/moon-core-tw';
import { IFlightBookingAllFilters } from './types';
import { initFilters, initAirlines } from './utils';
import FlightBookingFilterOptions from '../FlightBookingFilterOptions';
import { getAirlineLogoUrl } from '../FlightBookingGroup/utils';
import FlightBookingRange from '../FlightBookingRange';
import Modal from '../Modal';
import { initialFilters } from '../../store/flightBookings/reducer';
import { textEllipsisCN } from '../../styles/texts';

const FlightBookingAllFilters: React.FC<IFlightBookingAllFilters> = ({
  destination,
  airlines,
  filters,
  isRound,
  prices,
  origin,
  onSubmit,
  onCancel,
}) => {
  const [allFilters, setAllFilters] = useState(initFilters(filters, destination, origin, prices));
  const [airlinesFilter, setAirlinesFilter] = useState(initAirlines(airlines));

  useEffect(() => {
    setAllFilters(initFilters(filters, destination, origin, prices))
  }, [filters, destination, origin]);

  useEffect(() => {
    setAirlinesFilter(initAirlines(airlines))
  }, [airlines]);

  const handleSubmit = () => {
    
    const updatedFilters = Object.keys(allFilters).reduce((updateFilters, key) => {
      const selectedValue = allFilters[key].value;

      return {
        ...updateFilters,
        [key]: allFilters[key].isMulti 
          ? selectedValue.map(({ value }) => value)
          : selectedValue && typeof selectedValue === 'object'
            ? selectedValue.value
            : selectedValue,
      };
    }, {});
    const updatedAirlines = airlines.map(airline => ({
      ...airline,
      checked: airlinesFilter.value.some(({ value }) => airline.code === value),
    }));

    onSubmit({
      airlines: updatedAirlines,
      filters: updatedFilters,
    });
  };

  const handleResetFilters = () => {
    onSubmit({
      airlines: airlines.map(airline => ({ ...airline, checked: true })),
      filters: initialFilters,
    });
  };

  const handleChangeFilterValue = (field, value) => {
    setAllFilters({
      ...allFilters,
      [field]: {
        ...allFilters[field],
        value,
      }
    })
  };
 
  return (
    <Modal
      resetButtonLabel="Reset all"
      title="Filters"
      onSubmit={handleSubmit}
      onClose={onCancel}
      onReset={handleResetFilters}
    >
      <FlightBookingRange
        value={allFilters.maxPrice.value}
        label={allFilters.maxPrice.label}
        name={allFilters.maxPrice.name}
        min={allFilters.maxPrice.min}
        max={allFilters.maxPrice.max}
        onChange={(selectedValue) => handleChangeFilterValue('maxPrice', selectedValue)}
      />
      <FlightBookingFilterOptions
        selectedOption={allFilters.withBaggage.value}
        options={allFilters.withBaggage.options}
        label={allFilters.withBaggage.label}
        isMulti={allFilters.withBaggage.isMulti}
        onChange={(selectedValue) => handleChangeFilterValue('withBaggage', selectedValue)}
      />
      <FlightBookingFilterOptions
        selectedOption={allFilters.maxNumberOfConnections.value}
        options={allFilters.maxNumberOfConnections.options}
        label={allFilters.maxNumberOfConnections.label}
        isMulti={allFilters.maxNumberOfConnections.isMulti}
        onChange={(selectedValue) => handleChangeFilterValue('maxNumberOfConnections', selectedValue)}
      />
      <FlightBookingFilterOptions
        selectedOption={allFilters.maxDuration.value}
        options={allFilters.maxDuration.options}
        label={allFilters.maxDuration.label}
        isMulti={allFilters.maxDuration.isMulti}
        onChange={(selectedValue) => handleChangeFilterValue('maxDuration', selectedValue)}
      />
      <FlightBookingFilterOptions
        selectedOption={allFilters.departureTimeOrigin.value}
        options={allFilters.departureTimeOrigin.options}
        label={allFilters.departureTimeOrigin.label}
        isMulti={allFilters.departureTimeOrigin.isMulti}
        onChange={(selectedValue) => handleChangeFilterValue('departureTimeOrigin', selectedValue)}
      />
      <FlightBookingFilterOptions
        selectedOption={allFilters.arrivalTimeOrigin.value}
        options={allFilters.arrivalTimeOrigin.options}
        label={allFilters.arrivalTimeOrigin.label}
        isMulti={allFilters.arrivalTimeOrigin.isMulti}
        onChange={(selectedValue) => handleChangeFilterValue('arrivalTimeOrigin', selectedValue)}
      />
      {isRound && (
        <>
          <FlightBookingFilterOptions
            selectedOption={allFilters.departureTimeReturn.value}
            options={allFilters.departureTimeReturn.options}
            label={allFilters.departureTimeReturn.label}
            isMulti={allFilters.departureTimeReturn.isMulti}
            onChange={(selectedValue) => handleChangeFilterValue('departureTimeReturn', selectedValue)}
          />
          <FlightBookingFilterOptions
            selectedOption={allFilters.arrivalTimeReturn.value}
            options={allFilters.arrivalTimeReturn.options}
            label={allFilters.arrivalTimeReturn.label}
            isMulti={allFilters.arrivalTimeReturn.isMulti}
            onChange={(selectedValue) => handleChangeFilterValue('arrivalTimeReturn', selectedValue)}
          />
        </>
      )}
      <FlightBookingFilterOptions
        selectedOption={airlinesFilter.value}
        options={airlinesFilter.options}
        label={airlinesFilter.label}
        isMulti={airlinesFilter.isMulti}
        renderLabel={(option) => {
          const url = getAirlineLogoUrl(option.value, option.label);
          return (
            <div className='flex items-center'>
              <div className='w-[25px] h-[25px] mr-[10px] max-mobile:w-[15px] max-mobile:h-[15px]'>
                <img className='max-w-full max-h-full' src={url} alt={option.label} />
              </div>
              <div className={mergeClassnames('flex-grow max-tablet:text-moon-12', textEllipsisCN)}>
                {option.label}
              </div>
            </div>
          )
        }}
        onChange={(selectedValue) => setAirlinesFilter({
          ...airlinesFilter,
          value: Array.isArray(selectedValue) ? selectedValue : [selectedValue],
        })}
      />
    </Modal>
  );
};

export default FlightBookingAllFilters;
