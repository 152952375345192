import { useState, useEffect, useMemo } from 'react'; 
import { Group } from '@heathmont/moon-core-tw';
import { ReactComponent as SwapSvg } from '../.././static/icons/swap.svg';
import { DestinationsGroup } from '../../enums/Destination';
import useIsMobile from '../../hooks/useIsMobile';
import DestinationSearchSelect from '../DestinationSearchSelect';
import { IFlightBookingDestinationFilter } from './types';
import { formatDestinations } from '../../utils/destinations';
import { POPULAR_DESTINATIONS } from '../../constants';
import { swapIconCN } from './styles';

const FlightBookingDestinationFilter: React.FC<IFlightBookingDestinationFilter> = ({
  popularToDestinationChanged,
  defaultDestinationOptions,
  popularToDestination,
  defaultOriginOptions,
  destination,
  origin,
  setFieldValue,
}) => {
  const isMobile = useIsMobile();
  const popularDestinations = useMemo(() => formatDestinations({
    destinations: POPULAR_DESTINATIONS,
    popular: true,
  }), []);
  const [originOptionsGroup, setOriginOptionsGroup] = useState<DestinationsGroup>(popularDestinations);
  const [destinationOptionsGroup, setDestinationOptionsGroup] = useState<DestinationsGroup>(popularDestinations);

  useEffect(() => {
    const selected = popularDestinations.options.find(({ value }) => value === popularToDestination);
  
    if (selected) {
      setDestinationOptionsGroup(popularDestinations);
      setFieldValue('destination', selected);
    }
  }, [popularToDestinationChanged]);

  useEffect(() => {
    if (defaultOriginOptions?.length) {
      setOriginOptionsGroup(formatDestinations({
        destinations: defaultOriginOptions,
        popular: false,
      }));
    }
  }, [defaultOriginOptions]);

  useEffect(() => {
    if (defaultDestinationOptions?.length) {
      setDestinationOptionsGroup(formatDestinations({
        destinations: defaultDestinationOptions,
        popular: false,
      }));
    }
  }, [defaultDestinationOptions]);

  const handleSwap = () => {
    setFieldValue('origin', destination);
    setFieldValue('destination', origin);
    setOriginOptionsGroup(destinationOptionsGroup);
    setDestinationOptionsGroup(originOptionsGroup);
  };

  return (
    <Group className='rounded-moon-i-md' orientation={isMobile ? 'vertical' : 'horizontal'}>
      <DestinationSearchSelect
        optionsGroup={originOptionsGroup}
        selected={origin}
        label='Origin'
        setOptionsGroup={setOriginOptionsGroup}
        setFieldValue={value => setFieldValue('origin', value)}
      />
      <DestinationSearchSelect
        optionsGroup={destinationOptionsGroup}
        lastSelect
        selected={destination}
        label='Destination'
        setOptionsGroup={setDestinationOptionsGroup}
        setFieldValue={value => setFieldValue('destination', value)}
      />
      <div className={swapIconCN(isMobile)} onClick={handleSwap}>
        <SwapSvg />
      </div>
    </Group>
  );
};

export default FlightBookingDestinationFilter;
