export enum FeedbackModalState {
  CLOSED = 'closed',
  OPENED = 'opened', 
};

const FEEDBACK_MODAL_STATE_KEY = 'feedback_modal';

export const setFeedbackModalState = (amaClientRef: string, isOpen: boolean) => {
  const state = isOpen ? FeedbackModalState.OPENED : FeedbackModalState.CLOSED;
  localStorage.setItem(`${FEEDBACK_MODAL_STATE_KEY}:${amaClientRef}`, state);
};

export const getFeedbackModalState = (amaClientRef: string): FeedbackModalState | null  => {
  const state = localStorage.getItem(`${FEEDBACK_MODAL_STATE_KEY}:${amaClientRef}`) as FeedbackModalState | null;
  return state;
};
