import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';

export const useQuery = () => {
  const { search } = useLocation();

  const setQuery = (params) => {
    let queryParams = '';

    if (!isEmpty(params)) {
      queryParams = `?${qs.stringify(params)}`;
    }

    history.replaceState(null, '', `${window.location.pathname}${queryParams}`);
  };

  const getQuery = () => {
    const searchUrl = search && search.startsWith('?') ? search.replace('?', '') : search;
    return searchUrl ? qs.parse(searchUrl) : {};
  };

  return { getQuery, setQuery };
};
