import { mergeClassnames } from '@heathmont/moon-core-tw';
import { btnSecondaryCN } from '../../styles/buttons';

export const wrapperCN = mergeClassnames([
  'relative',
  'w-full',
  'h-full',
  'overflow-y-auto',
  'flex',
  'flex-col',
  'p-[32px]',
  'max-laptop:p-[20px]',
]);

export const dateRangeCN = (isMobile: boolean) => mergeClassnames([
  'date-range-picker',
  isMobile 
    ? 'date-range-picker--mobile'
    : 'date-range-picker--laptop'
]);

export const closeButtonCN = mergeClassnames([
  'absolute',
  'right-[20px]',
  'top-[20px]',
  'font-medium',
  'text-[12px]',
  'leading-[16px]',
  'text-trunks',
  'hover:text-[#5a5e64]',
  'p-0',
  'm-0',
  'cursor-pointer',
  'flex',
  'items-center',
  'justify-end',
  'flex-grow',
]);

export const returnButtonWrapperCN = mergeClassnames([
  'flex',
  'justify-center',
  'w-full',
  'max-tablet:w-[calc(100%-40px)]',
  'max-tablet:fixed',
  'max-tablet:top-[calc(100%-76px)]',
  'max-tablet:left-[20px]',
]);

export const returnButtonCN = mergeClassnames([
  'w-auto',
  'max-tablet:w-full',
  btnSecondaryCN,
  'text-moon-20',
  'font-medium',
]);
