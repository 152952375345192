import { useState, useEffect } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { InsetInput, Hint, Textarea } from '@heathmont/moon-core-tw';
import isEmpty from 'lodash/isEmpty';
import { IFlightBookingContactForm } from './types';
import Modal from '../Modal';
import { ReactComponent as SocialGmailSvg } from '../../static/icons/social-gmail.svg'
import {
  fieldWrapperCN,
  textCN,
} from './styles';
import { initialValues, contactFormSchema } from './schema';
import { handleSubmitContactForm } from './utils';

const FlightBookingContactForm: React.FC<IFlightBookingContactForm> = ({
  onClose,
}) => { 
  const [isContactFormLoading, setIsContactFormLoading] = useState<boolean>(false);
  const formik = useFormik({
    validationSchema: contactFormSchema,
    validateOnBlur: true,
    initialValues,
    onSubmit: () => {},
  });
  const { values, errors, touched, setFieldValue, setFieldTouched, validateForm } = formik; 
  
  useEffect(() => {
    validateForm();
  }, []);

  return (
    <Modal
      submitButtonLabel="Send"
      cancelButtonLabel="Cancel"
      isLoading={isContactFormLoading || !isEmpty(errors)}
      title="Contact form"
      onSubmit={() => handleSubmitContactForm({
        description: values.description,
        email: values.email,
        name: values.name,
        setIsContactFormLoading,
        onClose,
      })}
      onClose={onClose}
    >
      <FormikProvider value={formik}>
        <div className='w-full flex flex-col gap-[20px]'>
          <div className='w-full flex flex-col gap-[10px]'>
            <p className={textCN}>Please fill the form, if you would like to contact us.</p>
            <p className={textCN}>
              <SocialGmailSvg />
              {process.env.REACT_APP_CONTACT_EMAIL}
            </p>
          </div>

          <div className='w-full flex flex-col gap-[16px]'>
            <div className={fieldWrapperCN}>
              <InsetInput
                placeholder=' '
                className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
                error={!!(errors?.name && touched?.name)}
                name='name' 
                type='text'
                value={values.name}
                id='name'
                onChange={({ target: { value } }) => setFieldValue('name', value)}
                onBlur={() => setFieldTouched('name')}
              >
                <InsetInput.Label>Name</InsetInput.Label>
              </InsetInput>

              {!!(errors?.name && touched?.name) && (
                <Hint error>{errors?.name}</Hint>
              )}
            </div>

            <div className={fieldWrapperCN}>
              <InsetInput
                className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
                placeholder=' '
                error={!!(errors?.email && touched?.email)}
                name='email' 
                type='text'
                value={values.email}
                id='email'
                onChange={({ target: { value } }) => setFieldValue('email', value)}
                onBlur={() => setFieldTouched('email')}
              >
                <InsetInput.Label>Email</InsetInput.Label>
              </InsetInput>

              {!!(errors?.email && touched?.email) && (
                <Hint error>{errors?.email}</Hint>
              )}
            </div>

            <div className={fieldWrapperCN}>
              <Textarea
                placeholder='Please, describe your request or reason'
                className="rounded-moon-i-md [&_textarea]:rounded-moon-i-md resize-y"
                error={!!(errors?.description && touched?.description)}
                value={values.description}
                rows={3}
                onChange={({ target: { value } }) => setFieldValue('description', value)}
                onBlur={() => setFieldTouched('description')}
              />

              {!!(errors?.description && touched?.description) && (
                <Hint error>{errors?.description}</Hint>
              )}
            </div>
          </div>
        </div>
      </FormikProvider>
    </Modal>
  );
};

export default FlightBookingContactForm;
