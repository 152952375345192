import moment from 'moment';
import pick from 'lodash/pick';
import { Passenger } from '../../enums/Passenger';
import { Payment } from '../../enums/Payment';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { PAYER_TYPE } from '../../enums/PayerTypes';
import { COIN } from '../../enums/Coins';
import { PAYER_DOCUMENT_TYPES } from '../../enums/PayerDocumentTypes';
import { PASSENGER_DOCUMENT_TYPES, PassengerDocumentType } from '../../enums/PassengerDocumentTypes';

export const findOfferByItineraries = (group, itineraries) => {
  return group.flights.find(flight => {
    return flight.itineraries.every((itinerary, index) => {
      itinerary.id = itinerary.segments.map(segment => segment.id).join('_');
      return itinerary.id === itineraries[index].id
    });
  });
};

export const defineSelectedOffer = (group, selectedOrigin = null, selectedReturn = null) => {
  const selectedOriginItinerary = group?.originItineraries?.length
    ? (selectedOrigin || group?.originItineraries[0])
    : null;
  const selectedReturnItinerary = group?.returnItineraries?.length
    ? (selectedReturn || group?.returnItineraries[0])
    : null;
  const optimalItineraries = [selectedOriginItinerary, selectedReturnItinerary];
  const selectedOffer = findOfferByItineraries(group, optimalItineraries);

  return { selectedOriginItinerary, selectedReturnItinerary, selectedOffer };
};

export const setSelectedItineraries = (groups, params) => {
  const { selectedOriginItinerary, selectedReturnItinerary, groupIndex } = params;

  return groups.map((group, index) => {
    if (index !== groupIndex) {
      return group;
    }

    const selectedOrigin = group?.originItineraries?.length
        ? group.originItineraries.find(({ id }) => id === selectedOriginItinerary) 
        : null;
    const selectedReturn = group?.returnItineraries?.length
        ? group.returnItineraries.find(({ id }) => id === selectedReturnItinerary)
        : null;

    return {
      ...group,
      ...defineSelectedOffer(group, selectedOrigin, selectedReturn),
    };
  });
};

export const defineCheckedAirlines = (prevAirlines, newAirlines) => {
  const isAllChecked = prevAirlines.every(airline => airline.checked);
  const isAllUnchecked = prevAirlines.every(airline => !airline.checked);

  if (isAllChecked || isAllUnchecked) {
    return newAirlines.map(airline => ({ ...airline, checked: true }));
  }

  const checkedAirlines = prevAirlines.filter(airline => airline.checked)
  const isSomeCheckedExist = checkedAirlines.some(airline => {
    return newAirlines.some(newAirline => airline.code === newAirline.code);
  });

  if (isSomeCheckedExist) {
    return newAirlines.map(newAirline => ({
      ...newAirline,
      checked: checkedAirlines.some(checkedAirline => newAirline.code === checkedAirline.code),
    }));
  }

  return newAirlines.map(airline => ({ ...airline, checked: true })); 
};

export const definePayerEmail = (payment?: Payment, passenger?: Passenger, shouldResetPayerData?: boolean) => {
  if (payment?.payerEmail && !shouldResetPayerData) {
    return payment?.payerEmail;
  }

  return payment.payerType === PAYER_TYPE.INDIVIDUAL && passenger?.email
    ? passenger?.email
    : ''
};

export const definePayerName = (payment?: Payment, passenger?: Passenger, shouldResetPayerData?: boolean) => {
  if (payment?.payerName && !shouldResetPayerData) {
    return payment?.payerName;
  }

  return payment.payerType === PAYER_TYPE.INDIVIDUAL && passenger?.firstName && passenger?.lastName
    ? `${passenger.firstName} ${passenger.lastName}`
    : ''
};

export const defineCoin = (payment?: Payment) => {
  return payment.coin || COIN.BTC;
};

export const definePayerCountry = ({
  mainContact,
  payment,
}: {
  mainContact: Passenger,
  payment?: Payment,
}) => {
  if (payment?.payerCountry) {
    const payerCountry = payment?.payerCountry as any;
    return typeof payerCountry === 'object'
      ? (payerCountry?.id || undefined)
      : (payerCountry || undefined);
  }
  
  if (mainContact?.document?.countryId) {
    return mainContact?.document?.countryId;
  } 

  return undefined;
};

export const definePayerBirthCountry = ({
  mainContact,
  payment,
}: {
  mainContact: Passenger,
  payment?: Payment,
}) => {
  if (payment?.payerBirthCountry) {
    const payerBirthCountry = payment?.payerBirthCountry as any;
    return typeof payerBirthCountry === 'object'
      ? (payerBirthCountry?.id || undefined)
      : (payerBirthCountry || undefined);
  }
  
  if (mainContact?.document?.countryId) {
    return mainContact?.document?.countryId;
  } 

  return undefined;
};

export const definePayerDocumentType = ({
  travelDocumentsTypes,
  mainContact,
  payment,
}: {
  travelDocumentsTypes: PassengerDocumentType[];
  mainContact: Passenger;
  payment?: Payment;
}) => {
  if (payment?.payerDocumentType) {
    return payment?.payerDocumentType;
  }

  const travelDocumentType = travelDocumentsTypes.find(type => (
    +type.value === +mainContact?.document?.typeId
  ));

  if (travelDocumentType?.keyword === PASSENGER_DOCUMENT_TYPES.IDENTITY_CARD) {
    return PAYER_DOCUMENT_TYPES.ID_CARD;
  }
  
  if (travelDocumentType?.keyword === PASSENGER_DOCUMENT_TYPES.PASSPORT) {
    return PAYER_DOCUMENT_TYPES.PASSPORT;
  } 

  return undefined;
};

export const initPayment = ({
  shouldResetPayerData = false,
  travelDocumentsTypes = [],
  passengers,
  payment,
}: {
  shouldResetPayerData?: boolean;
  travelDocumentsTypes?: PassengerDocumentType[];
  passengers: Passenger[];
  payment?: Payment;
}): Payment => {
  const mainContact = passengers.find(passenger => passenger.mainContact);
  const paymentDetails: Payment = {
    paymentType: payment.paymentType,
    coin: defineCoin(payment),
    payerType: payment.payerType,
    payerName: definePayerName(payment, mainContact, shouldResetPayerData),
    payerEmail: definePayerEmail(payment, mainContact, shouldResetPayerData),
    payerRegistrationNumber: payment?.payerRegistrationNumber || '',
    payerVatNumber: payment?.payerVatNumber || '',
    payerPostcode: payment?.payerPostcode || '',
    payerAddress: payment?.payerAddress || '',
    payerStateOrCounty: payment?.payerStateOrCounty || '',
    payerCountry: definePayerCountry({ mainContact, payment }),
    payerCity: payment?.payerCity || '',
    payerBirthCountry: definePayerBirthCountry({ mainContact, payment }),
    payerDocumentNumber: payment?.payerDocumentNumber || mainContact?.document?.number || '',
    payerDocumentType: definePayerDocumentType({ travelDocumentsTypes, mainContact, payment }),
    payerBirthDate: payment?.payerBirthDate || mainContact?.birthday || '',
  };
  const requiredFields = [
    'paymentType',
    'payerType',
    'payerName',
    'payerEmail',
    'payerCountry',
    'payerCity',
    'payerPostcode',
    'payerAddress',
    'payerStateOrCounty',
  ];

  if (payment.paymentType === PAYMENT_TYPE.CRYPTO) {
    requiredFields.push('coin', 'payerBirthCountry', 'payerDocumentNumber', 'payerDocumentType', 'payerBirthDate');
  }

  if (payment.payerType === PAYER_TYPE.BUSINESS) {
    requiredFields.push('payerRegistrationNumber', 'payerVatNumber');
  }

  return pick(paymentDetails, requiredFields) as Payment;
};

export const getInitialPassengerDocument = (passengerData: Passenger) => {
  return {
    typeId: passengerData?.document?.typeId || undefined,
    number: passengerData?.document?.number || '',
    validTill: passengerData?.document?.validTill || '',
    countryId: passengerData?.document?.countryId || undefined,
  };
};

export const initPassengers = (numberOfTravelers, passengers?: Passenger[]): Passenger[] => {
  return Array.from({ length: numberOfTravelers }).map((traveler, i) => {
    const passengerData = passengers?.length && passengers[i];
    return {
      id: i + 1,
      mainContact: passengerData?.mainContact || i === 0,
      firstName: passengerData?.firstName || '',
      lastName: passengerData?.lastName || '',
      email: passengerData?.email || '',
      birthday: passengerData?.birthday || '',
      phone: passengerData?.phone || '',
      gender: passengerData?.gender || undefined,
      document: getInitialPassengerDocument(passengerData),
      loyaltyNumber: {
        airlineId: passengerData?.loyaltyNumber?.airlineId || undefined,
        number: passengerData?.loyaltyNumber?.number || '',
      },
    };
  });
};

export const definePriceRange = (prices = [], maxPrice = null) => {
  if (prices.length && prices[0] && prices[1]) {
    return prices;
  }

  return maxPrice ? [maxPrice, maxPrice] : [0, 0];
};

export const extractDepartureDateFromEvent = (event) => {
  return moment.utc(event.startAt).subtract(1, 'days').format('YYYY-MM-DD');
};

export const extractReturnDateFromEvent = (event) => {
  return moment.utc(event.endAt).add(1, 'days').format('YYYY-MM-DD');
};
