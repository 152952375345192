import extendedLogoLightPath from '../../static/extended-logo-light.svg';
import extendedLogoPath from '../../static/extended-logo.svg';
import logoPath from '../../static/logo.svg';

const YoloTravelLogo = ({ style = {}, light = false, extended = false }) => {
  const params = { className: 'block', style, alt: 'Yolo Travel' };
  return extended ? (
    light ? (
      <img {...params} src={extendedLogoLightPath} />
    ) : (
      <img {...params} src={extendedLogoPath} />
    )
  ) : (
    <img {...params} src={logoPath} />
  )
};

export default YoloTravelLogo;
