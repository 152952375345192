import {
  ControlsChevronRightSmall,
  TravelPassport,
  TravelSuitcase,
  GenericSearch,
  ShopWallet,
} from '@heathmont/moon-icons-tw';
import { useLocation, useNavigate } from 'react-router-dom';
import { IFlightBookingStepsWrapper } from './types';
import {
  stepperProgressMobileCN,
  stepperWrapperMobileCN,
  activeProgressLineCN,
  stepperWrapperCN,
  stepWrapperCN,
} from './styles';
import FlightBookingConfirmPriceError from '../FlightBookingConfirmPriceError';
import { ReactComponent as InfoSvg } from '../.././static/icons/info.svg';
import { FLIGHT_BOOKING_STEP, FLIGHT_BOOKING_STEP_STATUS } from '../../enums/FlightBookingStep';
import { useMemo } from 'react';
import { pageContentCN, pageContentWrapperCN } from '../../styles/wrappers';

export const flightBookingStepsOptions = [
  {
    value: FLIGHT_BOOKING_STEP.FLIGHT_LIST,
    label: 'Search',
    icon: <GenericSearch className='text-moon-24' />
  },
  {
    value: FLIGHT_BOOKING_STEP.PRICE_CONFIRMATION,
    label: 'Overview',
    icon: <TravelSuitcase className='text-moon-24' />
  },
  {
    value: FLIGHT_BOOKING_STEP.PASSENGERS,
    label: 'Passenger',
    icon: <TravelPassport className='text-moon-24' />
  },
  {
    value: FLIGHT_BOOKING_STEP.PAYMENT,
    label: 'Payment',
    icon: <ShopWallet className='text-moon-24' />
  },
];

const FlightBookingStepsWrapper: React.FC<IFlightBookingStepsWrapper> = ({
  hasAdditionalServicesUnpriceableWarning,
  isPriceConfirmationError,
  isSignificantPriceChange,
  isBookingError,
  bookingLoading,
  currentStep,
  isLoading,
  children,
  renderContinueAndBackButton,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const currentStepIndex: number = useMemo(() => {
    return flightBookingStepsOptions.findIndex(step => step.value === currentStep);
  }, [currentStep]);

  const getStatusOfStep = (stepIndex: number): FLIGHT_BOOKING_STEP_STATUS => {
    if (stepIndex === currentStepIndex) {
      return FLIGHT_BOOKING_STEP_STATUS.IN_PROGRESS;
    }

    if (stepIndex > currentStepIndex) {
      return FLIGHT_BOOKING_STEP_STATUS.PENDING;
    }

    if (stepIndex < currentStepIndex) {
      return FLIGHT_BOOKING_STEP_STATUS.COMPLETED;
    }
  };

  const handleBackToStep = (step: FLIGHT_BOOKING_STEP, status: FLIGHT_BOOKING_STEP_STATUS) => {
    if (status !== FLIGHT_BOOKING_STEP_STATUS.COMPLETED) {
      return 
    }

    return navigate(`/${step}${search}`);
  };

  return (
    <div className={pageContentWrapperCN}>
      <div className={pageContentCN}>
        {isPriceConfirmationError ? (
          <FlightBookingConfirmPriceError 
            hasAdditionalServicesUnpriceableWarning={hasAdditionalServicesUnpriceableWarning}
          />
        ) : (
          <>
            {!isBookingError && (
              <>
                <div className={stepperWrapperMobileCN}>
                  <span className='text-popo'>
                    {flightBookingStepsOptions[currentStepIndex].label}
                  </span>
                  <div className={stepperProgressMobileCN}>
                    {`${currentStepIndex + 1}/${flightBookingStepsOptions.length}`}
                  </div>
                </div>
                <div className={stepperWrapperCN}>
                  {flightBookingStepsOptions.map((step, index) => {
                    const status = getStatusOfStep(index);
                    return (
                      <>
                        <div
                          className={stepWrapperCN({
                            isLoading: bookingLoading || isLoading, 
                            completed: status === FLIGHT_BOOKING_STEP_STATUS.COMPLETED,
                            progress: status === FLIGHT_BOOKING_STEP_STATUS.IN_PROGRESS,
                            pending: status === FLIGHT_BOOKING_STEP_STATUS.PENDING,
                          })}
                          key={index}
                          onClick={() => handleBackToStep(step.value, status)}
                        >
                          {step.icon}
                          <span className='h-[24px] text-moon-16 ml-[5px]'>
                            {step.label}
                          </span>
                        </div>
                        <div
                          className={activeProgressLineCN(status === FLIGHT_BOOKING_STEP_STATUS.PENDING)}
                          style={{ left: `calc(${25 * index}%)` }}
                        />
                        {index !== flightBookingStepsOptions.length - 1 && (
                          <ControlsChevronRightSmall />
                        )}
                        
                      </>
                    );
                  })}
                  <div className='w-full h-[2px] bg-beerus absolute left-[0] bottom-[0]' />
                </div>
              </>
            )}
            {children}
            {isSignificantPriceChange && !bookingLoading && !isLoading && !isPriceConfirmationError && !isBookingError && (
              <div className='w-full m-0 p-0 flex flex-col gap-[5px] mt-[18px]'>
                {isSignificantPriceChange && (
                  <div className='flex items-center gap-[10px] text-moon-14 font-bold'>
                    <InfoSvg className='h-[24px] w-[24px]' /> The price has been changed and is actual on current moment
                  </div>
                )}
              </div>
            )}
            {renderContinueAndBackButton()}
          </>
        )}
      </div>
    </div>
  );
};

export default FlightBookingStepsWrapper;
