import { ISelectOptions } from './types';

const SelectOptions: React.FC<ISelectOptions> = ({
  options,
  label,
}) => {
  return (
    <>
      <option disabled value={''}>Select {label}</option>
      {options.map(option => (
        <option key={option.label + option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </>
  );
};

export default SelectOptions;
