import { useNavigate } from 'react-router-dom';
import { BOOK_FLIGHT_LOADING_TEXTS } from '../../constants';
import { getArrivalCity, getDepartureCity, getDepartureDate, getReturnDate } from '../../utils/offers';
import FlightBookingInfoView from '../FlightBookingInfoView';
import FlightBookingInitialLoader from '../FlightBookingInitialLoader';
import FlightBookingSummary from '../FlightBookingSummary';
import { IFlightBookingLoader } from './types';
import { pageContentCN, pageContentWrapperCN } from '../../styles/wrappers';

const FlightBookingLoader: React.FC<IFlightBookingLoader> = ({
  bookingError,
  offers,
}) => {
  const navigate = useNavigate();

  const backToFlightsSearch = () => {
    navigate('/');
  };

  return (
    <div className={pageContentWrapperCN}>
      <div className={pageContentCN}>
        {
          bookingError ? (
            <FlightBookingInfoView
              submitButtonLabel={'Back'}
              subTitle={bookingError}
              title={'The booking was unsuccessful'}
              onClick={backToFlightsSearch}
            />
          ) : (
            <>
              <FlightBookingSummary
                departureDate={getDepartureDate(offers)}
                destination={getArrivalCity(offers)}
                returnDate={getReturnDate(offers)}
                origin={getDepartureCity(offers)}
              />
              <FlightBookingInitialLoader texts={BOOK_FLIGHT_LOADING_TEXTS} small />
            </>
          )
        }
      </div>
    </div>
  );
};

export default FlightBookingLoader;
