import { useMemo } from 'react';
import { Dropdown as MoonDropdown, MenuItem } from '@heathmont/moon-core-tw';
import { IDropdown } from './types';

const Dropdown: React.FC<IDropdown> = ({
  placeholder,
  disabled = false,
  options,
  error,
  value,
  label,
  renderOption,
  onChange,
  onBlur = () => {},
}) => {
  const selectedOption = useMemo(() => {
    const selectedOption = options.find(option => option.value === value);
    return selectedOption;
  }, [value, options]);

  return (
    <MoonDropdown
      disabled={disabled}
      isError={error}
      value={value}
      size='xl'
      onChange={onChange}
      onBlur={onBlur}
    >
      {({ open }) => (
        <>
          <MoonDropdown.InsetSelect
            open={open}
            label={label}
            placeholder={placeholder}
          >
            {renderOption && selectedOption ? renderOption(selectedOption) : (selectedOption?.label || value)}
          </MoonDropdown.InsetSelect>
          <MoonDropdown.Options>
            {options.map((option, index) => (
              <MoonDropdown.Option value={option.value} key={index}>
                {({ selected, active }) => (
                  <MenuItem isActive={active} isSelected={selected}>
                    {renderOption ? renderOption(option) : option.label}
                  </MenuItem>
                )}
              </MoonDropdown.Option>
            ))}
          </MoonDropdown.Options>
        </>
      )}
    </MoonDropdown>
  );
};

export default Dropdown;
