import { useMemo } from 'react';
import { IFlightBookingRange } from './types';
import { outputCN, rangeCN } from './styles';

const FlightBookingRange: React.FC<IFlightBookingRange> = ({
  value,
  label,
  name,
  min,
  max,
  onChange,
}) => {
  const currentValue = useMemo(() => {
    if (!value) {
      return max;
    }

    if (value < min) {
      return min;
    }

    if (value > max) {
      return max;
    }
  
    return value;
  }, [value, min, max]);

  const position = useMemo(() => {
    if (min === max) {
      return 100;
    }
    
    const positionValue = ((currentValue - min) / (max - min)) * 100;

    return Math.floor(positionValue || 1);
  }, [currentValue, min, max]);

  return (
    <div className='relative w-full'>
      {label && (
        <span className='w-full flex mb-[10px] items-center justify-between'>
          {label && <span className='font-medium text-moon-16 text-trunks block'>{label}</span>}
        </span>
      )}

      <input
        value={currentValue}
        className={rangeCN}
        // @ts-ignore
        style={{ '--rangeSelectorPosition': `${position}%` }}
        type="range"
        name={name}
        min={`${min}`}
        max={`${max}`}
        id={name}
        onChange={({ target: { value } }) => {
          onChange(+value)
        }}
      />

      {value && (
        <div
          className={outputCN({
            start: position <= 10,
            middle: position > 10 && position < 90,
            end: position >= 90,
          })}
          style={{ left: `${position}%` }}
          id={name}
          >
          €{value.toLocaleString()}
        </div>
      )}

      <div className='w-full flex justify-between items-center text-moon-16 font-medium text-popo max-tablet:text-moon-12 mt-[4px]'>
        <span>€{min.toLocaleString()}</span>
        <span>€{max.toLocaleString()}</span>
      </div>
    </div>
  );
};

export default FlightBookingRange;
