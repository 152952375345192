import { connect } from 'react-redux';
import FlightBookingPayment from './FlightBookingPayment.ui';
import {
  handleSelectPaymentType,
  handleSelectPaymentCoin,
  setPaymentPayerDetails,
  toggleTransferRequired,
  setPaymentPayerType,
} from '../../store/flightBookings/actions';
import {
  setIsTermsAndConditionsOpen
} from '../../store/flightBookingsDialogs/actions';

const mapStateToProps = ({
  flightBookingsDialogsReducer,
  flightBookingsReducer,
  eventLinkReducer,
}) => {
  return {
    isTermsAndConditionsOpen: flightBookingsDialogsReducer.isTermsAndConditionsOpen,
    transferRequired: flightBookingsReducer.transferRequired,
    selectedOffers: flightBookingsReducer.selectedOffers,
    additionalBags: flightBookingsReducer.additionalBags,
    searchParams: flightBookingsReducer.search,
    amaClientRef: flightBookingsReducer.amaClientRef,
    passengers: flightBookingsReducer.passengers,
    hasAccess: !!(eventLinkReducer.confirmed && eventLinkReducer.eventLink),
    payment: flightBookingsReducer.payment,
    booking: flightBookingsReducer.booking,
  };
};

const mapDispatchToProps = {
  setIsTermsAndConditionsOpen,
  handleSelectPaymentType,
  handleSelectPaymentCoin,
  toggleTransferRequired,
  setPaymentPayerDetails,
  setPaymentPayerType,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingPayment);
