import { useTranslation } from 'react-i18next';
import { Checkbox } from '@heathmont/moon-core-tw';
import { ICheckboxGroup, IOptionsItem } from './types';
import { checkboxLabelCN } from '../../styles/texts';

const CheckboxGroup: React.FC<ICheckboxGroup> = ({
  selectedValues,
  data = [],
  renderCheckboxLabel,
  onSelect,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className='flex flex-col gap-2'>
      {data.map(({ value, title }: IOptionsItem) => (
        <Checkbox
          key={value}
          checked={(selectedValues ?? []).some((selectedValue) => selectedValue === value)}
          label={renderCheckboxLabel ? (
            renderCheckboxLabel(title)
          ) : (
            <span className={checkboxLabelCN}>
              {i18n.exists(title) ? t(title) : title}
            </span>
          )}
          onChange={() => onSelect(value)}
        />
      ))}
    </div>
  );
};

export default CheckboxGroup;
