import countryCodes from 'country-codes-list';
import orderBy from 'lodash/orderBy';
import { Passenger } from '../../enums/Passenger';
import { ISelectOption } from '../SelectOptions/types';

export const formatPhone = (passenger: Passenger): string => {
  let phone = '';
  
  if (passenger?.phoneCountryCode) {
    phone += passenger?.phoneCountryCode;
  }

  if (passenger?.phoneNumber) {
    phone += passenger?.phoneNumber;
  }

  return phone;
};

export const countyCodes = orderBy(
  countryCodes.customArray({
    label: '{countryNameEn} (+{countryCallingCode})',
    value: '+{countryCallingCode}',
  }),
  (countryCode) => countryCode.label
    .replace('Å', 'A')
    .replace('ô', 'o')
    .replace('ç', 'c')
    .replace('é', 'e'), 
  ['asc'],
) as unknown as ISelectOption[];
