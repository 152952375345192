import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { IPaymentCheckout } from './types';
import { paymentStatusLabelCN } from './styles';
import FlightBookingInfoView from '../FlightBookingInfoView';
import Accordion from '../Accordion';
import { paymentCheckoutContentCN, paymentCheckoutWrapperCN } from '../../styles/wrappers';

const PaymentCheckout: React.FC<IPaymentCheckout> = ({
  isTryAgainLoading,
  paymentDetails,
  poweredByIcon,
  paymentError,
  statusLabel,
  isLoading,
  isPending,
  children,
  canRetry,
  onPaymentMethodChange,
  handleTryAgain,
}) => {
  const navigate = useNavigate();

  const handleBackToInitialSearch = () => {
    navigate('/');
  };

  return (
    <div className={paymentCheckoutWrapperCN}>
      <div className={paymentCheckoutContentCN}>
        {paymentError ? (
          <FlightBookingInfoView
            secondaryButtonLabel={canRetry && 'Other methods'}
            submitButtonLoading={isTryAgainLoading}
            submitButtonLabel={canRetry ? 'Retry payment' : 'Flight search'}
            subTitle={paymentDetails}
            title={'The payment was unsuccessful'}
            handleSecondaryButtonClick={canRetry && onPaymentMethodChange}
            onClick={canRetry ? handleTryAgain : handleBackToInitialSearch}
          />
        ) : (
          <Accordion
            openByDefault
            withoutArrow
            disabled
            title={(
              <>
                {isLoading ? (
                  <Skeleton style={{ height: '100%', width: '150px' }} inline />
                ): (
                  <div className='flex items-center gap-[10px]'>
                    <div className={paymentStatusLabelCN(isPending)}>
                      {statusLabel}
                    </div>
                    <span className='font-medium text-moon-20 text-popo'>
                      Payment status
                    </span>
                  </div>
                )}
              </>
            )}
          >
            <div className='w-full flex flex-col gap-[20px]'>
              <span className='text-moon-16 font-normal text-bulma'>
                <>
                  {isLoading ? (
                    <Skeleton style={{ height: '100%', width: '250px' }} inline />
                  ): (
                    <>{paymentDetails}</>
                  )}
                </>
              </span>

              <div>
                <div className='w-full bg-goku flex flex-col gap-[20px]'>
                  {children}
                </div>
              </div>
              
              <div className='flex items-center gap-[10px]'>
                {isLoading ? (
                  <Skeleton style={{ height: '100%', width: '150px' }} inline />
                ): (
                  <>
                    <span className='text-moon-16 font-normal text-trunks'>
                      Powered by
                    </span>
                    {poweredByIcon}
                  </>
                )}
              </div>
            </div>
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default PaymentCheckout;
