import { IFlightBookingPopularDestinations } from './types';
import {
  countryTextCN,
  destinationCN,
  cityTextCN,
  wrapperCN,
} from './styles';
import { POPULAR_DESTINATIONS } from '../../constants';

const FlightBookingPopularDestinations: React.FC<IFlightBookingPopularDestinations> = ({
  onSelect,
}) => {
  const extractImageUrl = (destination) => {
    const name = destination.event ? destination.event.replace(/ /g, '_') : destination.code;
    return require(`../../static/destinations/${name}.png`);
  };

  return (
    <div className={wrapperCN}>
      {POPULAR_DESTINATIONS.map((destination, index) => (
        <div
          className={destinationCN(POPULAR_DESTINATIONS.length === index + 1)}
          style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${extractImageUrl(destination)})` }}
          key={destination.code}
          onClick={() => onSelect(destination.code)}
        >
          <h2 className={cityTextCN}>
            {destination.event ? destination.event : destination.city}
          </h2>
          <div className={countryTextCN}>
            {destination.event ? `${destination.city}, ${destination.country}` : destination.country}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FlightBookingPopularDestinations;
