import moment from 'moment';

export const formatDate = (date) => {
  const isCurrentYear = moment(date).isSame(new Date(), 'year');
  return isCurrentYear 
    ? moment(date).format('DD MMMM')
    : moment(date).format('DD MMMM, YYYY');
};

export const translations = {
  placeholder: 'Select Dates',
  apply: 'Apply',
  reset: 'Reset',
  cancel: 'Cancel',
  labelStartDate: 'Start date & time',
  labelEndDate: 'End date & time',
  lastMonth: 'Last month',
  lastWeek: 'Last week',
  last24hours: 'Last 24 hours',
  yesterday: 'Yesterday',
  today: 'Today',
  tomorrow: 'Tomorrow',
  thisWeek: 'This week',
  nextWeek: 'Next week',
  thisMonth: 'This month',
  nextMonth: 'Next month',
  custom: 'Custom',
};

