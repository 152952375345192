import { useEffect, useMemo, useState, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import isEmpty from 'lodash/isEmpty';
import { Hint, mergeClassnames } from '@heathmont/moon-core-tw';
import get from 'lodash/get';
import { useFormik, FormikProvider, setNestedObjectValues } from 'formik';
import { IFlightBookingPassengers } from './types';
import { getOptionsOnInit, getPassengerFullName, validateBaggages } from './utils';
import FlightBookingPageWrapper from '../../components/FlightBookingPageWrapper';
import FlightBookingBackAndNext from '../../components/FlightBookingBackAndNext';
import { useFlightOffersPriceConfirmation } from '../../hooks/useFlightOffersPriceConfirmation';
import FlightBookingStepsWrapper from '../../components/FlightBookingStepsWrapper';
import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';
import Accordion from '../../components/Accordion';
import FlightBookingPassengerForm from '../../components/FlightBookingPassengerForm';
import { passengersSchema } from './schema';
import { IncludedBagOption, IncludedBagOptionValue } from '../../enums/IncludedBag';
import { calculatePriceWithBags, extractBagsOptions } from '../../utils/baggage';
import useIsMobile from '../../hooks/useIsMobile';
import FlightBookingSummary from '../../components/FlightBookingSummary';
import useDestinations from '../../hooks/useDestinations';
import { getInitialPassengerDocument } from '../../store/flightBookings/utils';
import { textEllipsisCN } from '../../styles/texts';

const FlightBookingPassengers: React.FC<IFlightBookingPassengers> = ({
  additionalBags,
  selectedOffers = [],
  searchParams,
  amaClientRef,
  passengers = [],
  hasAccess,
  updateAdditionalBags,
  updatePassengers,
}) => {
  const isMobile = useIsMobile();
  const componentWillUnmount = useRef(false)
  const { origin, destination, isLoading: destinationLoading } = useDestinations({ searchParams, hasAccess });
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: passengersSchema(selectedOffers),
    validateOnBlur: true,
    initialValues: {
      passengers,
    },
    onSubmit: () => {},
  });
  const { values, errors, touched, setFieldValue, setValues, setFieldTouched, setTouched, validateForm } = formik;
  const {
    isPriceConfirmationError,
    isSignificantPriceChange,
    documentRequired,
    confirmedOffers,
    includedBags,
    isLoading,
    price,
  } = useFlightOffersPriceConfirmation({
    amaClientRef,
    hasAccess,
    offers: selectedOffers,
  });
  const [options, setOptions] = useState({
    travelDocumentsTypes: [],
    countries: [],
    airlines: [],
    loading: true,
  });
  const [isPassengerFormWasAlreadySubmitted, setIsPassengerFormWasAlreadySubmitted] = useState<boolean>(false);
  const [additionalBagPerPassenger, setAdditionalBagPerPassenger] = useState<IncludedBagOptionValue[]>(additionalBags);
  const [bagsOptions, setBagsOptions] = useState<IncludedBagOption[]>([]);
  const totalPrice = useMemo(() => calculatePriceWithBags(price, additionalBagPerPassenger), [price, additionalBagPerPassenger]);
  const additionalBagErrors = useMemo(() => validateBaggages(additionalBagPerPassenger, passengers), [additionalBagPerPassenger, passengers]);
  const isPageLoading = useMemo(() => {
    return isLoading || options.loading || destinationLoading;
  }, [isLoading, options.loading, destinationLoading]);

  const validateOnInit = async () => {
    const validationErrors = await validateForm();

    if (isEmpty(validationErrors)) {
      setIsPassengerFormWasAlreadySubmitted(true);
      setTouched(setNestedObjectValues(values, true));
    }
  }

  useEffect(() => {
    getOptionsOnInit(options, setOptions);
    validateOnInit();

    return () => {
        componentWillUnmount.current = true;
    }
  }, []);

  useEffect(() => {
    return () => {
      if (componentWillUnmount.current) {
        updatePassengers(values.passengers);
      }
    };
  }, [values.passengers]);

  useEffect(() => {
    const updatedPassengers = values.passengers.map(passenger => ({
      ...passenger,
      documentRequired,
      document: documentRequired
        ? getInitialPassengerDocument(passenger)
        : null,
    }));
    setValues({ passengers: updatedPassengers });
    validateForm({ passengers: updatedPassengers });
  }, [documentRequired]);

  useEffect(() => {
    if (confirmedOffers && includedBags && origin && destination) {
      setBagsOptions(extractBagsOptions({
        destination: destination?.city,
        origin: origin?.city,
        offers: confirmedOffers,
        bags: includedBags,
      }));
    }
  }, [confirmedOffers, includedBags, origin?.city, destination?.city]);

  const handleSelectBaggage = (bagValue, passengerId) => {
    const bagOption = bagsOptions.find(bag => bag.value === bagValue);

    if (!bagOption) {
      return;
    }

    const updatedAdditionalBags = additionalBagPerPassenger.some(bag => bag.value === bagValue && bag.passengerId === passengerId)
      ? additionalBagPerPassenger.filter(bag => !(bag.value === bagValue && bag.passengerId === passengerId))
      : [...additionalBagPerPassenger, { ...bagOption, passengerId }];

    setAdditionalBagPerPassenger(updatedAdditionalBags);
    updateAdditionalBags(updatedAdditionalBags);
  };

  const renderPassengerTitle = (index) => {
    const passengerTouched = get(touched, ['passengers', index], {});
    const passengerErrors = get(errors, ['passengers', index], {});
    const passengerAdditionalBaggageError = get(additionalBagErrors, [index], null)
    const passenger = get(values, ['passengers', index], {});
    const isTouched = !isEmpty(passengerTouched);
    const isError = !isEmpty(passengerErrors) || passengerAdditionalBaggageError;

    if (isTouched) {
      const fullName = getPassengerFullName(passenger);
      return (
        <div className='text-moon-14'>
          <div className='flex items-center gap-[10px]'>
            <div className={`w-[3px] h-[3px] rounded-[50%] ${isError ? 'bg-dodoria' : 'bg-roshi'}`} /> 
            <span className='text-bulma tracking-[1.5px]'>
              {isError ? 'NOT  COMPLETED' : 'COMPLETED'}
            </span>
          </div>
          <span className={mergeClassnames('text-moon-12 font-normal text-trunks mt-[5px]', textEllipsisCN)}>
            Passenger {index + 1}{fullName ? `: ${fullName}` : ''}
          </span>
        </div>
      );
    }

    return (<>Passenger {index + 1}</>);
  };

  const handleSelectMainContact = (passengerId) => {
    setFieldValue('passengers', values.passengers.map((passenger) => ({
      ...passenger,
      mainContact: +passenger.id === +passengerId
        ? !passenger.mainContact
        : false,
    })))
  };

  return (
    <FlightBookingPageWrapper isMobile={isMobile} page={'flight-booking-passengers'}>
      <FlightBookingStepsWrapper
        isPriceConfirmationError={isPriceConfirmationError}
        isSignificantPriceChange={isSignificantPriceChange}
        currentStep={FLIGHT_BOOKING_STEP.PASSENGERS}
        isLoading={isPageLoading}
        renderContinueAndBackButton={() => (
          <FlightBookingBackAndNext
            isLoading={isPageLoading}
            disabled={isPriceConfirmationError || !isEmpty(errors) || !isEmpty(additionalBagErrors)}
            price={totalPrice} 
            step={FLIGHT_BOOKING_STEP.PASSENGERS}
          />
        )}
      >
        <>
          <FlightBookingSummary
            departureDate={searchParams.departureDate}
            destination={destination?.city}
            returnDate={searchParams.returnDate}
            isLoading={isPageLoading}
            origin={origin?.city}
          />
          <div className='w-full flex flex-col gap-[10px]'>
            <FormikProvider value={formik}>
              {values.passengers.map((passenger, index) => (
                <Accordion
                  openByDefault={!isPageLoading && !isPassengerFormWasAlreadySubmitted}
                  disabled={isPageLoading}
                  title={(
                    <>
                      {isPageLoading ? (
                        <Skeleton style={{ height: '100%', width: '150px' }} inline />
                      ): (
                        renderPassengerTitle(index)
                      )}
                    </>
                  )}
                  key={`${passenger.id}_${isLoading}_${options.loading}_${destinationLoading}_${isPassengerFormWasAlreadySubmitted}`}
                >
                  <FlightBookingPassengerForm
                    travelDocumentsTypes={options.travelDocumentsTypes}
                    additionalBagError={get(additionalBagErrors, [index], null)}
                    isMultiPassengers={values.passengers.length > 1}
                    additionalBags={
                      additionalBagPerPassenger
                        .filter(additionalBag => additionalBag?.passengerId === passenger.id)
                        .map(additionalBag => additionalBag.value)
                    }
                    passenger={passenger}
                    countries={options.countries}
                    airlines={options.airlines}
                    touched={get(touched, ['passengers', index], {})}
                    errors={get(errors, ['passengers', index], {})}
                    bags={bagsOptions}
                    handleSelectMainContact={() => handleSelectMainContact(passenger.id)}
                    handleSelectBaggage={(selectedBag) => handleSelectBaggage(selectedBag, passenger.id)}
                    setFieldTouched={(field) => setFieldTouched(`passengers.${index}.${field}`, true, false)}
                    setFieldValue={(field, value) => setFieldValue(`passengers.${index}.${field}`, value)}
                  />
                </Accordion>
              ))}
            </FormikProvider>
          </div>

          {errors?.passengers && typeof errors?.passengers === 'string' && (
             <Hint className='mt-[16px]' error>{errors?.passengers}</Hint>
          )}
        </>
      </FlightBookingStepsWrapper>
    </FlightBookingPageWrapper>
  );
};

export default FlightBookingPassengers;
