import { mergeClassnames } from '@heathmont/moon-core-tw';

export const inputCN = (open: boolean) => mergeClassnames([
  'rounded-moon-i-md',
  open && '[&_input]:shadow-input-focus',
  open && 'hover:[&_input]:shadow-input-focus',
  '[&_input]:h-[72px]',
  '[&_input]:rounded-moon-i-md',
  '[&_input]:cursor-pointer',
]); 

export const menuCN = mergeClassnames([
  'w-[454px]',
  'max-tablet:w-full',
  'p-[32px]',
  'max-mobile:p-[16px]',
  'flex',
  'flex-col',
  'gap-[24px]',
  'shadow-moon-none',
]);

export const groupCN = mergeClassnames([
  'flex',
  'w-full',
  'justify-between',
  'items-center',
  'px-[16px]',
  'py-[8px]',
  'border-[1px]',
  'border-[solid]',
  'border-beerus',
  'rounded-[16px]',
]);

export const groupLabelCN = mergeClassnames([
  'text-moon-16',
  'font-medium',
  'text-trunks',
  'normal-case',
  'mb-[12px]',
]);

export const optionCN = ({ firstOption, lastOption }: { firstOption: boolean, lastOption: boolean }) => mergeClassnames([
  'flex',
  'px-[16px]',
  'py-[12px]',
  'w-full',
  'justify-between',
  'items-center',
  'm-0',
  'cursor-pointer',
  'rounded-none',
  '-mt-[1px]',
  'mr-[0]',
  'mb-[0]',
  '-ml-[1px]',
  'border-[1px]',
  'border-[solid]',
  'border-beerus',
  firstOption && 'rounded-tl-[16px] rounded-tr-[16px]',
  lastOption && 'rounded-bl-[16px] rounded-br-[16px]',
  'hover:bg-[#F6F5F4]'
]);

export const optionTitleCN = mergeClassnames([
  'text-moon-14',
  'text-bulma',
  'normal-case',
]);

export const optionSubTitleCN = mergeClassnames([
  'text-moon-14',
  'text-trunks',
  'normal-case',
]);

