import { mergeClassnames } from '@heathmont/moon-core-tw';

export const borderCN = mergeClassnames([
  'about-us-section',
  'border-solid',
  'border-b',
  'border-y-[#ffffff33]',
  'laptop:border-r',
  'laptop:border-x-[#ffffff33]',
]);

export const sectionCN = mergeClassnames([
  'p-[42px]',
  'w-1/2',
  'font-normal',
  'text-moon-24',
  'text-goku',
  'max-laptop:w-full',
  'max-tablet:text-moon-16',
  'max-tablet:p-[24px]',
]);

export const bannerCN = mergeClassnames([
  'w-full',
  'rounded-[12px]',
  'px-[100px]',
  'py-[0]',
  'max-tablet:px-[10px]',
  'max-laptop:px-[72px]',
  'mb-[8px]',
]);

export const titleCN = mergeClassnames([
  'font-normal',
  'text-[64px]',
  'leading-[36px]',
  'text-goku',
  'tracking-[-0.04em]',
  'max-laptop:text-[48px]',
  'max-laptop:leading-[36px]',
]);

export const descriptionCN = mergeClassnames([
  'text-moon-32',
  'max-tablet:text-moon-24',
  'text-goku',
  'font-normal',
  'mt-[48px]',
  'max-tablet:mt-[32px]',
]);

export const sectionsCN = mergeClassnames([
  'rounded-[12px]',
  'border-[1px]',
  'border-[solid]',
  'border-[rgba(255,255,255,0.2)]',
  'bg-[rgba(217,_217,_217,_0.2)]',
  'mt-[72px]',
  'flex',
  'flex-wrap',
  'w-full',
  'max-laptop:mt-[56px]',
]);

export const contactButtonCN = mergeClassnames([
  'w-[292px]',
  'mt-[56px]',
  'max-tablet:w-[100%]',
  'max-tablet:mt-[32px]',
]);

export const solutionsCN = mergeClassnames([
  'w-full',
  'flex',
  'justify-between',
  'flex-wrap',
  'gap-[8px]',
  'mt-[56px]',
  'max-tablet:mt-[46px]',
  'max-tablet:-mb-[30px]',
  'max-tablet:flex-col',
]);

export const solutionBlockCN = mergeClassnames([
  'w-[calc(100%/3-5.5px)]',
  'bg-[linear-gradient(180deg,_#FFFFFF_0%,_#F0F3FB_100%)]',
  'rounded-[12px]',
  'flex',
  'flex-col',
  'p-[42px]',
  'gap-[12px]',
  'max-tablet:p-[72px]',
  'max-tablet:w-[calc(100%)]',
  'max-mobile:gap-[8px]',
  'max-mobile:p-[20px]',
  'max-mobile:min-h-[315px]',
  'max-mobile:justify-center',
]);

export const solutionIconWrapperCN = mergeClassnames([
  'h-[100px]',
  'flex',
  'justify-start',
  'items-center',
  'max-mobile:h-[75px]',
  'max-mobile:mb-[16px]',
]);

export const solutionIconCN = mergeClassnames([
  'max-mobile:max-h-[75px]',
  'max-mobile:w-auto',
]);

export const solutionTitleCN = mergeClassnames([
  'font-medium',
  'text-[32px]',
  'leading-[40px]',
  'text-popo',
  'tracking-[-0.04em]',
  'max-laptop:text-[24px]',
  'max-mobile:leading-[30.62px]',
]);

export const solutionDescriptionCN = mergeClassnames([
  'font-normal',
  'text-moon-24',
  'text-bulma',
  'opacity-50',
  'max-tablet:text-moon-16',
]);
