import MoonAccordion from '@heathmont/moon-core-tw/lib/es/accordion/Accordion';
import { ControlsChevronDownSmall } from '@heathmont/moon-icons-tw';
import { IAccordion } from './types';
import { contentCN, extraPaddingCN, iconCN, itemCN, titleCN } from './styles';
import { mergeClassnames } from '@heathmont/moon-core-tw';

const Accordion: React.FC<IAccordion> = ({
  openByDefault = true,
  withoutArrow = false,
  withShadow = false,
  disabled = false,
  children,
  title,
}) => {
  return (
    <MoonAccordion itemSize="xl" defaultValue={openByDefault ? 'open' : 'close'}>
      <MoonAccordion.Item disabled={disabled} value={'open'} className={itemCN(withShadow)}>
        <MoonAccordion.Header disabled={disabled} className="moon-open:[&_svg]:rotate-180">
          <MoonAccordion.Button className={mergeClassnames('data-[disabled]:cursor-auto data-[disabled]:opacity-100', extraPaddingCN)}>
            <h3 className={titleCN}>{title}</h3>
            {!withoutArrow && (
              <ControlsChevronDownSmall
                className={iconCN}
              />
            )}
          </MoonAccordion.Button>
        </MoonAccordion.Header>
        <MoonAccordion.Content className={contentCN}>
          {children}
        </MoonAccordion.Content>
      </MoonAccordion.Item>
    </MoonAccordion>
  );
};

export default Accordion;
