import Yup from '../../utils/validation';

const EMAIL_MIN_VALUE = 2;
const EMAIL_MAX_VALUE = 255;
const NAME_MIN_VALUE = 2;
const NAME_MAX_VALUE = 255;
const DESCRIPTION_MIN = 2;
const DESCRIPTION_MAX = 1000;

const validateMessages = {
  name: {
    required: 'Name cannot be empty',
    min: `Name cannot be less than ${NAME_MIN_VALUE} characters`,
    max: `Name cannot be longer than ${NAME_MAX_VALUE} characters`,
  },
  email: {
    required: 'Email cannot be empty',
    min: `Email cannot be less than ${EMAIL_MIN_VALUE} characters`,
    max: `Email cannot be longer than ${EMAIL_MAX_VALUE} characters`,
    valid: 'Email should be in format "user@example.com"',
    disallowedSymbol: 'Email cannot contain "+"',
  },
  description: {
    required: 'Description cannot be empty',
    min: `Description cannot be less than ${DESCRIPTION_MIN} characters`,
    max: `Description cannot be longer than ${DESCRIPTION_MAX} characters`,
  },
};

export const contactFormSchema = Yup.object().shape({
  name: Yup
    .string()
    .min(NAME_MIN_VALUE, validateMessages.name.min)
    .max(NAME_MAX_VALUE, validateMessages.name.max)
    .isInLatinCharacters()
    .required(validateMessages.name.required),
  email: Yup
    .string()
    .email(validateMessages.email.valid)
    .min(EMAIL_MIN_VALUE, validateMessages.email.min)
    .max(EMAIL_MAX_VALUE, validateMessages.email.max)
    .withoutSpaces()
    .isInLatinCharacters()
    .disallowedSymbols(['+'], validateMessages.email.disallowedSymbol)
    .required(validateMessages.email.required),
  description: Yup
    .string()
    .min(DESCRIPTION_MIN, validateMessages.description.min)
    .max(DESCRIPTION_MAX, validateMessages.description.max)
    .isInLatinCharacters()
    .required(validateMessages.description.required),
});

export const initialValues = {
  description: '',
  email: '',
  name: '',
};

export const getFieldBaseProps = (field) => {
  return {
    inputSize: 'xlarge',
    fieldName: field, 
    fieldId: field,
  };
};
