import { mergeClassnames } from '@heathmont/moon-core-tw';

export const paymentTypeCN = (active: boolean) => mergeClassnames([
  'flex',
  'items-center',
  'justify-center',
  'flex-col',
  'w-[calc(100%-4px)]',
  'gap-[7.5px]',
  'h-full',
  'rounded-[12px]',
  'cursor-pointer',
  'max-mobile:gap-[5px]',
  'shadow-input',
  'hover:shadow-input-hov',
  active && '!shadow-input-focus',
]);

export const iconCN = mergeClassnames([
  'max-w-[60px]',
  'max-h-[60px]',
  'max-mobile:max-w-[30px]',
  'max-mobile:max-h-[30px]',
]);
