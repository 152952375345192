export enum DURATION {
  ALL = 'clear',
  LESS_THAN_3_HOURS = 180,
  LESS_THAN_5_HOURS = 300,
  LESS_THAN_10_HOURS = 600,
};

export enum DURATION_LABEL {
  ALL = 'All',
  LESS_THAN_3_HOURS = 'Less than 3h',
  LESS_THAN_5_HOURS = 'Less than 5h',
  LESS_THAN_10_HOURS = 'Less than 10h',
}

export const durationOptions = [
  {
    value: DURATION.ALL,
    label: DURATION_LABEL.ALL,
  },
  {
    value: DURATION.LESS_THAN_3_HOURS,
    label: DURATION_LABEL.LESS_THAN_3_HOURS,
  },
  {
    value: DURATION.LESS_THAN_5_HOURS,
    label: DURATION_LABEL.LESS_THAN_5_HOURS,
  },
  {
    value: DURATION.LESS_THAN_10_HOURS,
    label: DURATION_LABEL.LESS_THAN_10_HOURS,
  },
];
