import { mergeClassnames } from '@heathmont/moon-core-tw';

export const wrapperCN = ({
  backgroundColor,
  hasAccess,
}: {
  backgroundColor: boolean;
  hasAccess: boolean;
}) => mergeClassnames([
  'w-full',
  'h-auto',
  'min-h-[100dvh]',
  'overflow-x-hidden',
  hasAccess ? 'overflow-y-auto' : 'overflow-y-hidden',
  backgroundColor ? 'bg-[#67A9E5]' : 'bg-gohan', 
]);

export const backgroundImageCN = mergeClassnames([
  'w-[calc(100%+100px)]',
  '-ml-[50px]',
  'min-h-[100dvh]',
  'max-laptop:px-[50px]',
  'max-laptop:py-[0]',
]); 

export const logoWrapperCN = mergeClassnames([
  'flex',
  'justify-start',
  'items-center',
  'w-full',
  'p-[30px]',
  'gap-[10px]',
  'h-[113px]',
  'max-tablet:h-[93.73px]',
  'max-tablet:justify-center',
]);

export const bannerCN = mergeClassnames([
  'box-content',
  'w-[calc(100%-16px)]',
  'pt-[194px]',
  'px-[0]',
  'pb-[166px]',
  'ml-[8px]',
  'mr-[8px]',
  'mb-[8px]',
  '-mt-[105px]',
  'rounded-[12px]',
  '[box-shadow:inset_0_0_0_1000px_rgba(0,0,0,.4)]',
  'max-tablet:rounded-none',
  'max-tablet:ml-[0]',
  'max-tablet:mr-[0]',
  'max-tablet:-my-[93.73px]',
  'max-tablet:pt-[100px]',
  'max-tablet:px-[0]',
  'max-tablet:pb-[78px]',
  'max-tablet:w-full',
]);
