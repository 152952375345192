import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IFlightBookingSearch } from './types';
import FlightBookingPageWrapper from '../../components/FlightBookingPageWrapper';
import Footer from '../../components/Footer';
import FlightBookingSearchForm from '../../components/FlightBookingSearchForm';
import FlightBookingHelloText from '../../components/FlightBookingHelloText';
import { DestinationOption } from '../../enums/Destination';
import { handleFetchOptions } from '../FlightBookingList/utils';
import useIsMobile from '../../hooks/useIsMobile';
import { formatValueToSearchParams } from './utils';

const FlightBookingSearch: React.FC<IFlightBookingSearch> = ({
  isContactFormOpen,
  searchParams,
  hasAccess,
  eventLink,
  setEventDataToSearchParams,
  setIsContactFormOpen,
  setSearchParams,
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [origin, setOrigin] = useState<object | null>(null);
  const [originOptions, setOriginOptions] = useState<DestinationOption[]>([]);
  const [destination, setDestination] = useState<object | null>(null);
  const [destinationOptions, setDestinationOptions] = useState<DestinationOption[]>([]);

  useEffect(() => {
    if (searchParams.origin && hasAccess) {
      handleFetchOptions(searchParams.origin as string, setOrigin, setOriginOptions);
    }
  }, [searchParams.origin, hasAccess]);

  useEffect(() => {
    if (searchParams.destination && hasAccess) {
      handleFetchOptions(searchParams.destination as string, setDestination, setDestinationOptions);
    }
  }, [searchParams.destination, hasAccess]);

  useEffect(() => {
    if (hasAccess && eventLink && !searchParams.destination && !searchParams.departureDate && !searchParams.returnDate) {
      setEventDataToSearchParams(eventLink);
    }
  }, [hasAccess, eventLink, searchParams.destination, searchParams.departureDate, searchParams.returnDate]);

  const handleSearch = (values) => {
    const params = formatValueToSearchParams(values);
    setSearchParams(params, {
      shouldResetAirlines: true,
      shouldResetFilters: true,
      shouldResetSortBy: true,
    });
    navigate(`/flight-booking-list`);
  };

  return (
    <FlightBookingPageWrapper isMobile={isMobile} page={'flight-booking-search'}>
      <FlightBookingHelloText />
      <FlightBookingSearchForm
        withPopularDestinations
        destinationOptions={destinationOptions}
        originOptions={originOptions}
        searchParams={{ ...searchParams, destination, origin }}
        disabled={false}
        onSubmit={handleSearch}
      />
      <Footer
        isContactFormOpen={isContactFormOpen}
        setIsContactFormOpen={setIsContactFormOpen}
      />
    </FlightBookingPageWrapper>
  );
};

export default FlightBookingSearch;
