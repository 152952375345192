const PREFIX = 'ht_';

export const getRaw = (key, defaultValue = null) => {
  const value = sessionStorage.getItem(`${PREFIX}${key}`);
  if (!value) return defaultValue;
  return value;
};

export const get = (key, defaultValue = null) => {
  const value = getRaw(key, defaultValue);
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export const remove = (key) => {
  sessionStorage.removeItem(`${PREFIX}${key}`);
};

export const set = (key, value) => {
  if (value === null) {
    remove(key);
  } else {
    sessionStorage.setItem(`${PREFIX}${key}`, value);
  }
};
