import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, mergeClassnames } from '@heathmont/moon-core-tw';
import { IFlightBookingConfirmation } from './types';
import FlightBookingPageWrapper from '../../components/FlightBookingPageWrapper';
import FlightBookingOffer from '../../components/FlightBookingOffer';
import {
  handleFetchBookingDetails,
  defineTitleAndSubTitle,
  handleDownloadInvoice,
  handleDownloadTicket,
  isInvoicePayment,
  isSuccessPayment,
} from './utils';
import useIsMobile from '../../hooks/useIsMobile';
import { Passenger } from '../../enums/Passenger';
import { Payment } from '../../enums/Payment';
import FlightBookingFeedbackDialog from '../../components/FlightBookingFeedbackDialog';
import { btnDownloadCN, btnStatic } from '../../styles/buttons';

const FlightBookingConfirmation: React.FC<IFlightBookingConfirmation> = ({
  isMoreFareRulesModalOpen,
  isFeedbackModalOpen,
  fareRulesOfferId,
  hasAccess,
  setIsMoreFareRulesModalOpen,
  setIsFeedbackFormOpen,
  resetToInitial,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [booking, setBooking] = useState<{
    associatedRecords: any[];
    travelers: Passenger[];
    payment: Payment;
    offers: any[];
    trips: any[];
    price: number;
  }>({
    associatedRecords: [],
    travelers: [],
    payment: null,
    offers: [],
    trips: [],
    price: 0,  
  });
  const [isBookingLoading, setIsBookingLoading] = useState<boolean>(true);
  const [isTicketsDownloading, setIsTicketsDownloading] = useState<boolean>(false);
  const [isInvoiceDownloading, setIsInvoiceDownloading] = useState<boolean>(false);
  const [primaryContactUserId, setPrimaryContactUserId] = useState<number | null>(null);
  const [primaryContactTripId, setPrimaryContactTripId] = useState<number | null>(null);
  const { amaClientRef } = useParams();
  const { title, subTitle } = useMemo(() => defineTitleAndSubTitle({
    paymentType: booking?.payment?.paymentType,
    status: booking?.payment?.status,
    price: booking?.price,
  }), [booking?.payment?.paymentType, booking?.payment?.status, booking?.price]);

  const backToFlightsSearch = () => {
    navigate('/');
  };

  useEffect(() => {
    if (location?.state?.resetToInitial) {
      resetToInitial();
    }
  }, [location?.state?.resetToInitial]);

  useEffect(() => {
    if (hasAccess) {
      handleFetchBookingDetails({
        amaClientRef,
        setPrimaryContactUserId,
        setPrimaryContactTripId,
        setIsFeedbackFormOpen,
        setIsBookingLoading,
        handleError: backToFlightsSearch,
        setBooking,
      });
    }

    return () => {
      resetToInitial();
    };
  }, [hasAccess]);

  return (
    <FlightBookingPageWrapper
      withBackgroundImage
      backgroundColor
      isMobile={isMobile}
      page={'flight-booking-confirmation'}
    >
      <div className='px-[0] py-[60px] w-[892px] max-w-full flex flex-col justify-center items-center mx-[auto] my-[0] -mt-[113px] max-tablet:w-full max-tablet:-mt-[94px] max-tablet:pt-[100px] max-tablet:px-[0] max-tablet:pb-[120px]'>
        <div className='w-full flex flex-col justify-center items-center pt-[0] px-[80px] pb-[20px] max-tablet:px-[0] max-tablet:pb-[0]'>
          <span className='text-moon-40 max-laptop:text-moon-32 max-tablet:text-moon-24 text-bulma font-medium text-center mb-[12px]'>
            {title}
          </span>
          <span className='text-moon-16 text-bulma text-center mt-[10px]'>
            {subTitle}
          </span>
        </div>
        <div className='w-full flex flex-col gap-[20px] my-[40px]'>
          {booking.offers.map((offer) => {
            const offerAssociatedRecords = booking.associatedRecords.filter(associatedRecord => (
              associatedRecord.flightOfferId === offer.id
            ));
            return (
              <FlightBookingOffer
                isMoreFareRulesModalOpen={isMoreFareRulesModalOpen}
                associatedRecords={offerAssociatedRecords}
                fareRulesOfferId={fareRulesOfferId}
                travelers={booking.travelers}
                isLoading={isBookingLoading}
                isTicket
                offer={offer}
                key={offer.id}
                setIsMoreFareRulesModalOpen={setIsMoreFareRulesModalOpen}
              />
            );
          })}
        </div>
        <div className='w-full flex items-center justify-center gap-[10px] fixed bottom-[20px] ml-[var(--scrollbar-width)] max-tablet:flex-col'>
          {
            (isInvoicePayment(booking?.payment?.paymentType) || isSuccessPayment(booking?.payment?.status)) && (
              <Button
                className={mergeClassnames(btnDownloadCN, btnStatic)}
                animation={isInvoiceDownloading ? 'progress' : undefined}
                disabled={isTicketsDownloading || isInvoiceDownloading || isBookingLoading}
                size='xl'
                onClick={() => handleDownloadInvoice({
                  amaClientRef,
                  paymentId: booking?.payment?.id,
                  setIsInvoiceDownloading,
                })}
              >
                Download invoice
              </Button>
            )
          }
          <Button
            className={mergeClassnames(btnDownloadCN, btnStatic)}
            animation={isTicketsDownloading ? 'progress' : undefined}
            disabled={isTicketsDownloading || isInvoiceDownloading || isBookingLoading}
            size='xl'
            onClick={() => handleDownloadTicket({
              amaClientRef,
              trips: booking.trips,
              setIsTicketsDownloading,
            })}
          >
            Download ticket
          </Button>
        </div>
      </div>

      {isFeedbackModalOpen && !!primaryContactTripId && !!primaryContactUserId && (
        <FlightBookingFeedbackDialog
          tripId={primaryContactTripId}
          userId={primaryContactUserId}
          onClose={() => setIsFeedbackFormOpen(false, amaClientRef)}
        />
      )}
    </FlightBookingPageWrapper>
  );
};

export default FlightBookingConfirmation;
