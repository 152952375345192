import axios from 'axios';
import request from '../../utils/request';

export const formatDocumentTypes = (documentTypes = []): { keyword: string, value: number, label: string }[] => {
  return documentTypes.map(documentType => ({
    keyword: documentType.keyword,
    value: documentType.id,
    label: documentType.name,
  }));
};

export const formatCountries = (countries = []): { value: number, label: string, flag: string }[] => {
  return countries.map(country => ({
    value: country.id,
    label: country.name,
    flag: country.flag,
  }));
};

export const formatOptions = (options = []): { value: number, label: string }[] => {
  return options.map(option => ({
    value: option.value,
    label: option.title,
  }));
};

export const getOptionsOnInit = async (options, setOptions) => {
  try {
    setOptions({ ...options, loading: true });
  
    const [travelDocumentsTypesResponse, countriesResponse, paymentOptionsResponse] = await Promise.all([
      request.get('white-label/travel-documents-types'),
      request.get('white-label/countries'),
      request.get('white-label/payments/options'),
    ]);

    setOptions({
      ...options,
      travelDocumentsTypes: formatDocumentTypes(travelDocumentsTypesResponse?.data?.travelDocumentsTypes),
      paymentTypes: formatOptions(paymentOptionsResponse?.data?.paymentTypes),
      payerTypes: formatOptions(paymentOptionsResponse?.data?.payerTypes),
      countries: formatCountries(countriesResponse?.data?.countries),
      loading: false,
    });
  } catch (err) {
    setOptions({ ...options, loading: false });
  }
};

export const handleFetchCoins = async ({
  setIsCryptoUnavailable,
  setCoinsLoading,
  setCoins,
  source,
}) => {
  try {
    setCoinsLoading(true);
    setIsCryptoUnavailable(false);
    const response = await request.get('white-label/payments/crypto-coins', {
      cancelToken: source.token,
    });
    setCoins(response?.data?.coins);
    setCoinsLoading(false);
  } catch (err) {
    setCoinsLoading(false);

    if (axios.isCancel(err)) {
      console.error('Request canceled');
    } else {
      setIsCryptoUnavailable(true);
    }
  }
};
