import get from 'lodash/get';
import { FARE_RULE_CATEGORY_LABEL, fareRuleCategoryOptions } from '../enums/FareRuleCategory';

const PENALTIES_FARE_RULES_DESCRIPTION_TYPE_NAME = 'PENALTIES';

export const defineDescriptionForFareRule = (rule) => {
  if (rule?.maxPenaltyAmount && +rule?.maxPenaltyAmount > 0) {
    return `Max penalty amount €${rule.maxPenaltyAmount}`
  }

  return null;
};

export const defineLabelForFareRule = (rule, option) => {
  if (!rule) {
    return null
  }

  if (rule.notApplicable && !option.required) {
    return null;
  }

  if (rule.notApplicable && option.required) {
    return `Non-${FARE_RULE_CATEGORY_LABEL[rule.category]}`
  }

  let label = `${FARE_RULE_CATEGORY_LABEL[rule.category]}`;

  if ((rule.maxPenaltyAmount && +rule.maxPenaltyAmount > 0) || rule.isChargeable) {
    label += ` for fee`;
  }

  return label;
};

export const extractFlightOfferFareRules = (offer) => {
  const rules = get(offer, ['fareRules', 'rules'], []);
   
  return fareRuleCategoryOptions
    .map((option) => {
      const fareRule = rules.find(rule => rule.category === option.value);

      if (!fareRule) {
        return null;
      }
    
      return {
        ...fareRule,
        description: defineDescriptionForFareRule(fareRule),
        label: defineLabelForFareRule(fareRule, option),
      };
    })
    .filter(rule => rule?.label);
};

export const extractFlightOfferDetailedFareRules = (offer, includedDetailedFareRules) => {
  if(!includedDetailedFareRules){
    return [];
  }

  const includedDetailedFareRulesArr = Object.values(includedDetailedFareRules);
  const segmentFareRules = [];
  offer.itineraries.forEach((itinerary) => {
    itinerary?.segments?.forEach((segment) => {
      const segmentFareRule: any = includedDetailedFareRulesArr.find((fareRule: any) => +fareRule?.segmentId === +segment?.id);
      const penaltiesFareRules: any = segmentFareRule ? 
        segmentFareRule?.fareNotes?.descriptions?.find((description) => description.descriptionType === PENALTIES_FARE_RULES_DESCRIPTION_TYPE_NAME) :
        null;
      if(segmentFareRule && penaltiesFareRules){
        segmentFareRules.push({ ...penaltiesFareRules, segment });
      }
    })
  });

  return segmentFareRules;
};

export const extractFareDetails = ({
  travelerId,
  segmentId,
  offer,
}: {
  travelerId: string,
  segmentId: string,
  offer: any,
}) => {
  const travelerPricing = offer?.travelerPricings.find(pricing => +pricing.travelerId === +travelerId);

  if (!travelerPricing) {
    return {};
  }

  const fareDetails = travelerPricing.fareDetailsBySegment.find(fareDetails => +fareDetails.segmentId === +segmentId)

  return fareDetails || {};
};
